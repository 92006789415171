import { Fragment, useEffect } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import FormError from './FormError';
import FormLabel from './FormLabel';

function AutoComplete({
  field,
  form,
  valueKey,
  labelKey,
  label,
  required,
  tooltip,
  query,
  setQuery,
  locations,
  fullObj = () => {},
  ...rest
}) {
  const meta = {
    touched: form?.touched[field.name] || false,
    error: form?.errors[field.name]
  };
  const value = field.value;
  // displayed when a user selects field
  const valueDisplay = locations?.find((el) => el[valueKey] === value || el.value === value);
  // console.log(locations);
  useEffect(() => {
    fullObj(valueDisplay);
  }, [valueDisplay?.city, valueDisplay?.state, valueDisplay?.country]);

  return (
    <div className="w-full">
      <Combobox
        value={value}
        onChange={(opt) => {
          form?.setFieldValue(field.name, opt[valueKey] || opt?.value || opt);
        }}>
        {label && (
          <FormLabel required={required} tooltip={tooltip}>
            {label}
          </FormLabel>
        )}
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left sm:text-sm">
            <Combobox.Input
              {...rest}
              className=" border-gray-300 rounded-lg h-12 pl-3 pr-10 leading-5 text-gray-900  block w-full sm:text-sm font-regular text-sm focus:outline-none focus-visible:border-theme-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300"
              displayValue={() =>
                !!valueDisplay ? valueDisplay[labelKey] || valueDisplay.label || value : null
              }
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            // afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="z-10 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5  sm:text-sm">
              {locations.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                locations.map((location) => (
                  <Combobox.Option
                    key={Math.random() * locations.length}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={location}>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {`${location[labelKey]}, ${location['state']}`}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>

      <FormError meta={meta} name={field.name} />
    </div>
  );
}

export default AutoComplete;
