/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-unused-vars */
import React from 'react';
import BenefitOption from 'ui/stories/BenefitOption';
import HospitalLogo from 'ui/stories/HospitalLogo';
import JobDetails from 'ui/stories/JobDetails';
import parse from 'html-react-parser';
import { Fragment } from 'react';
import Nopreview from './Nopreview.svg';
import { useLocations } from '../../lib/services/dropDownServices';
import _ from 'lodash';

export const DynamicData = ({
  formValues,
  categories,
  specialization,
  currency,
  qualifications,
  benefits
}) => {
  function checkFormFilled(formValues) {
    return (
      formValues?.hospital_name ||
      formValues?.job_title ||
      formValues?.job_description ||
      formValues?.category ||
      formValues?.salary?.currency ||
      formValues?.salary?.amount ||
      formValues?.location ||
      formValues?.vacancies ||
      formValues?.specialization ||
      false
    );
  }

  return (
    <div className="flex flex-col gap-6">
      {!checkFormFilled(formValues) ? (
        <div className="flex flex-col items-center w-full gap-3 mt-16">
          <img src={Nopreview} alt="No Job to Preview" className="w-3/6" />
          <div className="text-2xl leading-7 text-center text-theme-gray-800">
            Preview not available
          </div>
          <div className="text-sm leading-5 text-center font-regular text-theme-gray-600">
            Once you begin updating the form on the left, we will display the job preview
          </div>
        </div>
      ) : (
        <>
          <HeaderSection formValues={formValues} categories={categories} />
          <JobDetailSection
            formValues={formValues}
            specialization={specialization}
            currency={currency}
          />
          <JobDescriptionSection formValues={formValues} />
          <EmailSection formValues={formValues} />
          <EligibilitySection
            formValues={formValues}
            qualifications={qualifications}
            categories={categories}
          />
          <BenefitSection formValues={formValues} benefits={benefits} />
        </>
      )}
    </div>
  );
};

const HeaderSection = ({ formValues, categories }) => {
  if (
    formValues.hospital_name ||
    formValues.hospital_url ||
    formValues.job_title ||
    formValues.category
  ) {
    return (
      <div className="flex space-x-6">
        {formValues.hospital_url ? (
          <div className="w-[120px] h-[86px] bg-[#f6f7fb] flex justify-center items-center rounded-lg overflow-hidden">
            <img
              className="object-cover w-12 h-12"
              src={formValues.hospital_url}
              alt="hospital_image"
            />
          </div>
        ) : (
          <HospitalLogo />
        )}

        <div className="flex-col font-sans text-left">
          <h4 className="text-2xl text-theme-gray-800 mb-0.5">{formValues.job_title}</h4>
          <p className="mb-2 text-sm font-regular text-theme-gray-500">
            {formValues.hospital_name}
          </p>
          <p className="text-base font-medium text-[#30c6ac]">
            {categories.find((category) => category.id === formValues.category)?.value || ''}
          </p>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const JobDetailSection = ({ formValues, specialization, currency }) => {
  const { locations, loading } = useLocations(formValues.location.length, formValues.location);
  const findCurrencySign = {
    INR: '₹',
    USD: '$',
    Euro: '€',
    Pound: '£'
  };
  if (
    formValues.specialization ||
    formValues.salary?.currency ||
    formValues.salary?.amount ||
    formValues.location ||
    formValues.vacancies
  ) {
    return (
      <>
        <JobDetails
          details={[
            {
              title: 'Specialization',
              value:
                specialization.find(
                  (specializeValue) => specializeValue.id === formValues.specialization
                )?.value || ''
            },
            {
              title: 'Salary',
              value:
                `${
                  findCurrencySign[
                    currency.find(
                      (currencyValue) => currencyValue.id === formValues.salary?.currency
                    )?.value
                  ] || ''
                }${formValues.salary?.amount}` || ''
            },
            { title: 'Vacancies', value: formValues.vacancies || '' },
            {
              title: 'Location',
              value: !loading
                ? `${_.head(locations).city}, ${_.head(locations).state}, ${
                    _.head(locations).country
                  }`
                : '' || ''
            }
          ]}
        />
      </>
    );
  } else {
    return null;
  }
};

const JobDescriptionSection = ({ formValues }) => {
  if (formValues.job_description) {
    return (
      <>
        <div className="my-3 text-xl leading-5 text-theme-gray-800">Job Description</div>
        <div className="w-full prose">
          {parse(formValues.job_description.replaceAll('<p><br></p>', '') || '')}
        </div>
      </>
    );
  } else {
    return null;
  }
};

const EmailSection = ({ formValues }) => {
  if (formValues.email) {
    return (
      <div className="flex flex-col gap-3">
        <div className="text-base leading-5 text-theme-gray-800">Recruiter email id</div>
        <div className="text-sm leading-5 font-regular text-theme-gray-600">
          {formValues.email || ''}
        </div>
      </div>
    );
  } else {
    null;
  }
};

const EligibilitySection = ({ formValues, qualifications, categories }) => {
  if (
    formValues.qualification ||
    formValues.additionalQualifications?.length > 0 ||
    formValues.minYears ||
    formValues.maxYears
  ) {
    return (
      <div id="second_sec" className="flex flex-col gap-4">
        <div className="text-xl leading-5 text-theme-gray-800">Eligibility</div>
        <div className="flex flex-col gap-3 2xl:flex-row">
          <div className="flex flex-col flex-1 gap-3">
            <div className="flex gap-2">
              <div className="text-sm leading-5 font-regular text-theme-gray-500">
                {formValues.qualification ? 'Qualification' : null}
              </div>
              <div className="text-xs font-bold leading-5 font-regular text-theme-gray-500">
                {formValues.qualification
                  ? qualifications.find((q) => q.id === formValues.qualification).value
                  : ''}
              </div>
            </div>
            <div className="flex gap-2">
              <div className="text-sm leading-5 font-regular text-theme-gray-500">
                {formValues.additionalQualifications ? 'Additional Qualifications' : null}
              </div>
              <div className="text-xs font-bold leading-5 font-regular text-theme-gray-500">
                {formValues.additionalQualifications?.join(', ') || ''}
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 gap-3">
            <div className="flex self-start gap-2">
              <div className="text-sm leading-5 font-regular text-theme-gray-500">
                {formValues.category ? 'Category of the employer' : null}
              </div>
              <div className="text-xs font-bold leading-5 font-regular text-theme-gray-500">
                {formValues.category
                  ? categories.find((c) => c.id === formValues.category).value
                  : ''}
              </div>
            </div>
            <div className="flex gap-2">
              <div className="text-sm leading-5 font-regular text-theme-gray-500">
                {formValues.minYears ||
                formValues.maxYears ||
                formValues.minYears === 0 ||
                formValues.maxYears === 0
                  ? 'Years of experience'
                  : null}
              </div>
              <div className="text-xs font-bold leading-5 font-regular text-theme-gray-500">
                {(formValues.minYears && formValues.maxYears) ||
                formValues.minYears === 0 ||
                formValues.maxYears === 0
                  ? `${formValues.minYears === 0 ? 0 : formValues.minYears} years - ${
                      formValues.maxYears === 0 ? 0 : formValues.maxYears
                    } years`
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const BenefitSection = ({ formValues, benefits }) => {
  const dynamicBenefits = formValues.benefits
    ? formValues.benefits.map((mb) => benefits.find((fb) => fb.id === mb).value)
    : '';
  if (formValues.benefits?.length >= 0) {
    return (
      <div className="flex flex-col items-start gap-3">
        <div className="text-base font-medium leading-5 text-theme-gray-800">Benefits</div>
        {dynamicBenefits
          ? dynamicBenefits.map((db, index) => {
              return (
                <Fragment key={index}>
                  <BenefitOption>{db}</BenefitOption>
                </Fragment>
              );
            })
          : ''}
        {formValues.other_benefits?.map((ob, index) => {
          if (ob) {
            return (
              <Fragment key={index}>
                <BenefitOption>{ob}</BenefitOption>
              </Fragment>
            );
          }
        })}
      </div>
    );
  } else {
    return null;
  }
};
