import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

export default function SuccessLayout({
  title,
  description,
  imgURL,
  className,
  children,
  employer = false,
  ...rest
}) {
  return (
    <div
      className={classNames('flex flex-col items-center space-y-5 text-center', className)}
      {...rest}>
      <img src={imgURL} alt="success image" className="md:w-[388px] md:h-[388px] object-cover" />
      <h3 className={classNames('md:w-2/3', employer ? 'leading-[3rem]' : '')}>{title}</h3>
      <p
        className={classNames(
          'text-theme-gray-600 font-regular text-xs w-3/4 md:w-2/5',
          employer ? 'text-sm' : ''
        )}>
        {description}
      </p>
      <div className="space-y-4">{children}</div>
    </div>
  );
}

SuccessLayout.propTypes = {
  /* 
        title of the message
    */
  title: PropTypes.string.isRequired,
  /* 
        description of the message
    */
  description: PropTypes.string,
  /*
        src of the image to show
    */
  imgURL: PropTypes.string.isRequired,
  /*
        additional classnames to add
    */
  className: PropTypes.string
};

SuccessLayout.defaultProps = {
  description: null
};
