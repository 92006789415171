/* eslint-disable no-unused-vars */

import { API } from '../../lib/network/API';
import { crossDomainToken } from '../../lib/network/Auth';
import { toastedNote } from '../../utils/Helper';

export const applicantDropdownProps = {
  label: 'Status',
  options: ['All', 'CV shortlisted', 'Interviewed', 'Hired', 'Rejected', 'On hold'],
  className: 'sm:w-52'
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export const viewProfile = {
  name: 'View profile',
  fun: async (rowData, setOnTooltipClick, navigate, mutate, slug) => {
    setOnTooltipClick(false);
    navigate(`/jobs/${slug}/${rowData.id}`);
  }
};
export const download = {
  name: 'Download resume',
  fun: async (rowData, setOnTooltipClick) => {
    console.log(rowData);
    setOnTooltipClick(false);
    const res = await fetch(rowData?.resume?.signed_url, {});
    const pdff = await res.blob();
    saveAs(pdff, `${rowData?.name}_resume.pdf`);
  }
};

export const shortlist = {
  name: 'Shortlist',
  fun: async (rowData, setOnTooltipClick, navigate, mutate, slug, details) => {
    setOnTooltipClick(false);
    const { data, error } = await API.post(
      `/job_postings/${details.id}/update_application_status/${rowData.id}`,
      {
        status: 'cv_shortlist'
      }
    );
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error.message[0]);
    }
  }
};
export const hire = {
  name: 'Hire',
  fun: async (rowData, setOnTooltipClick, navigate, mutate, slug, details) => {
    setOnTooltipClick(false);
    const { data, error } = await API.post(
      `/job_postings/${details.id}/update_application_status/${rowData.id}`,
      {
        status: 'hire'
      }
    );
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error.message[0]);
    }
  }
};

export const interview = {
  name: 'Interview',
  fun: async (rowData, setOnTooltipClick, navigate, mutate, slug, details) => {
    setOnTooltipClick(false);
    const { data, error } = await API.post(
      `/job_postings/${details.id}/update_application_status/${rowData.id}`,
      {
        status: 'interview'
      }
    );
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error.message[0]);
    }
  }
};

export const putOnHold = {
  name: 'Put on hold',
  fun: async (rowData, setOnTooltipClick, navigate, mutate, slug, details) => {
    setOnTooltipClick(false);
    const { data, error } = await API.post(
      `/job_postings/${details.id}/update_application_status/${rowData.id}`,
      {
        status: 'put_on_hold'
      }
    );
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error.message[0]);
      console.log(error);
    }
  }
};
export const reject = {
  name: 'Reject',
  fun: async (rowData, setOnTooltipClick, navigate, mutate, slug, details) => {
    setOnTooltipClick(false);
    const { data, error } = await API.post(
      `/job_postings/${details.id}/update_application_status/${rowData.id}`,
      {
        status: 'reject'
      }
    );
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error.message[0]);
    }
  }
};
