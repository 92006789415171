/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import Modal from 'ui/stories/Modal';
import { Formik, Form, Field } from 'formik';
import TextInput from 'ui/stories/TextInput';
import Dropdown from 'ui/stories/Dropdown';
import { Button } from 'ui/stories/Button';
import { checkoutValidations, customPlanValidation } from '../../lib/formikValidations/validation';
import {
  useCountriesList,
  useOrganization,
  usePlanDetails
} from '../../lib/services/checkoutServices';
import { useCountryCodeList, useFindAllCurrency } from '../../lib/services/dropDownServices';
import _ from 'lodash';
import { CloseIcon } from 'ui/stories/assets/Logos';
import { useState } from 'react';
import { CurrentUser } from '../../lib/network/Auth';
import { API } from '../../lib/network/API';
import { toastedNote } from '../../utils/Helper';
import { useContext } from 'react';
import { UserData } from '../../lib/context/userContext';
import { useEffect } from 'react';
import { useRef } from 'react';
import TextArea from 'ui/stories/TextArea';
import Contact from 'ui/stories/Contact';
import { useOrganizationType } from '../../lib/services/companyProfileServices';
import Loader from 'ui/stories/Loader';
import { isValidPhoneNumber } from 'libphonenumber-js';
import classNames from 'classnames';
import InputSelect from 'ui/stories/InputSelect';

export default function Checkout({ isOpen, setIsOpen, planId, planType, callMutate }) {
  const user = CurrentUser.getDetails();
  const [showLoader, setLoading] = useState(false);
  const [country, setCountry] = useState('');
  const [stateValue, setStateValue] = useState('');
  const [state, setState] = useState(false);
  const [city, setCity] = useState(false);
  const [clearPolling, setClearPolling] = useState(false);
  const [pollingStatus, setPollingStatus] = useState(null);

  const [stateList, setStateList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  const [alert, setAlert] = useState(false);

  // user details
  const userId = user?.id;
  const { organization } = useOrganization(true, userId);
  const { countries } = useCountriesList();
  // const { states } = useStatesList(state, country);
  // const { cities } = useCitiesList(city, country, stateValue);

  useEffect(() => {
    async function getState() {
      const { data, error } = await API.post(
        'https://countriesnow.space/api/v0.1/countries/states',
        {
          country: country
        }
      );
      setStateList(data.data.states);
    }

    if (country.length) {
      getState();
    }
  }, [state, country]);

  useEffect(() => {
    async function getCities() {
      const { data, error } = await API.post(
        'https://countriesnow.space/api/v0.1/countries/state/cities',
        {
          country: country,
          state: stateValue
        }
      );
      setCitiesList(data?.data);
    }
    if (stateValue.length) {
      getCities();
    }
  }, [city, stateValue]);

  const { plan, isValidating, loading } = usePlanDetails(planId !== 0, planId);
  const { userData } = useContext(UserData);

  const paymentObj = useRef(null);

  const amountHelper = (val, showCurrency = true) => {
    return showCurrency
      ? loading || isValidating
        ? 0
        : `₹${val}`
      : loading || isValidating
      ? 0
      : val;
  };

  const initialValues = {
    companyName: organization?.organization_name,
    gst: '',
    address: '',
    country: '',
    state: '',
    city: '',
    pincode: ''
  };

  const razerPayinit = (val, id, resp) => {
    var options = {
      key: process.env.REACT_APP_RAZER_PAY,
      subscription_id: id,
      name: val.companyName,
      description: 'Monthly Test Plan',
      handler: function () {
        // localStorage.setItem('sub_det', JSON.stringify(resp));
        callPolling();
        callMutate();
      },
      prefill: {
        name: userData.name,
        email: userData.email,
        contact: userData.phone
      },
      theme: {
        color: '#F37254'
      }
    };
    var rzp1 = new Razorpay(options);
    rzp1.open();
  };

  const razerPay = async (val) => {
    console.log('razerPay');
    setLoading(true);
    const user = CurrentUser.getDetails();
    const customData = {
      subscription: {
        organization_id: user.id,
        pricing_id: planId,
        company_name: val.companyName,
        gst_number: val.gst ? val.gst : '',
        gateway: 'razorpay',
        address_attributes: {
          line_1: val.address,
          country: val.country,
          state: val.state,
          city: val.city,
          pin: val.pincode
        }
      },
      operation: 'create'
    };
    const { data, error } = await API.post('/payments/subscription', customData);
    if (!error && data.response.gateway_entity_id) {
      paymentObj.current = data.response;
      razerPayinit(val, data.response.gateway_entity_id, data.response);
    }
  };

  const getPaymentStatus = async (id) => {
    try {
      const response = await API.get(`/payments/status?subscription_id=${id}`);
      const { data, error } = response;

      if (data && data.response && data.response.status !== null) {
        setPollingStatus(data.response.status);
        if (data.response.status === 'pending') {
          setPollingStatus(data.response.status);
          // Continue polling
          return;
        } else {
          // Status is either 'active' or 'cancelled', stop polling
          localStorage.removeItem('sub_det');
          setLoading(false);
          setClearPolling(true);
          clearInterval(paymentObj.pollingInterval);
          callMutate();
          return;
        }
      } else {
        toastedNote.error(error || 'An unknown error occurred.');
        setPollingStatus(null);
        setClearPolling(true);
        setLoading(false);
      }
    } catch (err) {
      console.error('Failed to fetch payment status:', err);
      toastedNote.error(err.message || 'Failed to fetch payment status.');
      setPollingStatus(null);
      setClearPolling(true);
      setLoading(false);
    }
  };

  const callPolling = () => {
    // if (localStorage.getItem('sub_det')) {
    //   paymentObj.current = JSON.parse(localStorage.getItem('sub_det'));
    // }
    paymentObj.pollingInterval = setInterval(() => {
      getPaymentStatus(paymentObj.current.subscription_id);
    }, 5000);

    setTimeout(() => {
      clearInterval(paymentObj.pollingInterval);
      setClearPolling(true);
      setLoading(false);
    }, 180000);
  };

  useEffect(() => {
    if (localStorage.getItem('sub_det')) {
      localStorage.removeItem('sub_det');
      // setLoading(true);
      // callPolling();
    }
    return () => {
      clearInterval(paymentObj.pollingInterval);
      setPollingStatus(null);
      setClearPolling(true);
    }; // Clear interval on component unmount
  }, []);

  console.log({
    clearPolling,
    showLoader,
    pollingStatus
  });

  return (
    <div>
      <Modal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        // clearState={() => setLoading(false)}
        alignMid={showLoader}
        isOnClose={!showLoader}
        dialogClassName={classNames(showLoader ? '!pt-0' : null)}>
        <>
          {showLoader ? (
            <Transaction />
          ) : clearPolling && pollingStatus !== null ? (
            <SuccessMessage
              status={pollingStatus}
              modalState={setIsOpen}
              clearPollingFnc={() => setClearPolling(false)}
              setLoadingFnc={() => {
                setLoading(false);
              }}
            />
          ) : (
            <CheckOutDetails
              setIsOpen={setIsOpen}
              plan={plan}
              planType={planType}
              amountHelper={amountHelper}
              initialValues={initialValues}
              countries={countries}
              states={stateList}
              cities={citiesList}
              showLoader={showLoader}
              razerPay={razerPay}
              setCountry={setCountry}
              setStateValue={setStateValue}
              setState={setState}
              setCity={setCity}
              callMutate={callMutate}
              alert={alert}
              setAlert={setAlert}
            />
          )}
        </>
      </Modal>
    </div>
  );
}

const Transaction = () => {
  return (
    <div className="h-44 md:w-96 flex flex-col justify-center items-center">
      <div className="mb-14">
        <lottie-player
          src="/transaction.json"
          background="transparent"
          speed="1"
          loop
          autoplay></lottie-player>
      </div>
      <p className="text-sm leading-5 text-center -mt-14 sm:-mt-10 font-regular sm:leading-normal sm:text-base">
        Your payment is being processed, Please do not close, refresh or click on back button. This
        might take some time.
      </p>
    </div>
  );
};

const SuccessMessage = ({ status, modalState, clearPollingFnc, setLoadingFnc }) => {
  if (status === 'active') {
    return (
      <div>
        <div className="relative flex flex-col items-center justify-center max-w-xl px-3 py-6 text-center duration-300 ease-in backdrop-blur-sm gap-y-4 rounded-xl md:p-10 md:px-20">
          <img src="/Successful.svg" alt="vector" />
          <h3 className="text-xl text-theme-gray-800">Payment has been done successfully</h3>
          <p className="font-regular text-sm text-[#323736]">
            Thanks for buying the Junior domestic plan.
          </p>
          <div className="flex flex-wrap items-center justify-center self-center mt-4 space-x-4">
            <Button href="/jobs">Start Unlocking CVs</Button>
            <Button href="/job-post">Start Posting Job</Button>
          </div>
        </div>
      </div>
    );
  } else if (status === 'pending') {
    return (
      <div>
        <div className="relative flex flex-col items-center justify-center max-w-xl text-center duration-300 ease-in backdrop-blur-sm gap-y-4 rounded-xl py-4">
          <h3 className="text-lg text-theme-gray-800">
            This is taking longer than expected. Please check back later for payment confirmation.
          </h3>
          <Button
            size="small"
            className="px-7 mt-2 text-lg"
            href="/billing/plans"
            onClick={() => {
              modalState(false);
              // clearPollingFnc();
              // setLoadingFnc();
            }}>
            Continue
          </Button>
        </div>
      </div>
    );
  } else if (status === 'cancelled') {
    return (
      <div>
        <div className="relative flex flex-col items-center justify-center max-w-xl px-3 py-6 text-center duration-300 ease-in backdrop-blur-sm gap-y-4 rounded-xl md:p-10 md:px-20">
          <h3 className="text-xl text-theme-gray-800">Payment was not successful</h3>
          <p className="font-regular text-sm text-[#323736]">
            Your payment was cancelled. Please try again or contact support.
          </p>
          <div className="flex flex-wrap items-center justify-center space-x-2 mt-4">
            <Button href="/billing/plans">Retry Payment</Button>
            <Button href="/billing/plans">Contact Support</Button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const CheckOutDetails = ({
  amountHelper,
  setIsOpen,
  razerPay,
  plan,
  initialValues,
  countries,
  states,
  showLoader,
  cities,
  setCountry,
  setStateValue,
  setState,
  setCity,
  planType,
  alert,
  setAlert
}) => {
  useEffect(() => {
    return () => {
      setAlert(false);
    };
  }, []);
  return (
    <>
      {planType == 'contact_us' ? (
        <CustomPlan setIsOpen={setIsOpen} planAmount={plan?.amount} planType={planType} />
      ) : (
        <div className="w-72 sm:min-w-[650px] lg:min-w-[900px]">
          <div className="flex items-center justify-between pb-4 text-2xl border-b">
            Checkout
            <Button onClick={setIsOpen} icon={<CloseIcon />} type="plain" size="small" />
          </div>

          <div className="pt-4 lg:flex lg:flex-row-reverse lg:gap-10">
            {/*form*/}
            <div className="flex-1 pt-6 lg:pt-0">
              <Formik
                initialValues={initialValues}
                validationSchema={checkoutValidations}
                onSubmit={razerPay}>
                {({ values }) => {
                  return (
                    <Form className="flex flex-col">
                      {!alert ? (
                        <div className="space-y-4 lg:flex lg:flex-row-reverse lg:gap-10 lg:space-y-0">
                          {/*gst card */}
                          <div
                            style={{
                              background:
                                ' linear-gradient(112.86deg, #008B73 -4.49%, #373895 117.83%)'
                            }}
                            className={classNames(
                              alert
                                ? ''
                                : 'flex-1 text-white px-5 rounded-xl py-5 h-fit max-w-[364px]'
                            )}>
                            <p className="flex justify-between pb-2 border-b lg:pb-4">
                              Plan Summary <span>{amountHelper(plan?.amount)}</span>
                            </p>
                            <div className="py-2 border-b lg:py-4">
                              <div>
                                <p className="flex justify-between">
                                  {plan?.name} <span>{amountHelper(plan?.amount)}</span>
                                </p>
                                <p className="flex justify-between">
                                  GST ({amountHelper(plan?.gst_percentage, false)}%){' '}
                                  <span>{amountHelper(plan?.gst)}</span>
                                </p>
                              </div>
                            </div>
                            <p className="flex items-center justify-between pt-2 lg:pt-4">
                              <span className="text-base">Total in INR</span>{' '}
                              <span className="text-2xl">{amountHelper(plan?.to_be_paid)}</span>
                            </p>
                          </div>
                          <div className="flex flex-col flex-1 space-y-5">
                            <p>Company information</p>
                            <div className="space-y-5">
                              <Field
                                component={TextInput}
                                label="Company name"
                                required
                                placeholder="Company name"
                                name="companyName"
                              />
                              <Field
                                component={TextInput}
                                label="GST (Optional)"
                                placeholder="GST (Optional)"
                                name="gst"
                              />
                              <Field
                                component={TextInput}
                                label="Address"
                                required
                                placeholder="Address"
                                name="address"
                              />
                            </div>
                            <div className="lg:flex lg:gap-3 ">
                              <div className="w-full">
                                <Field
                                  component={Dropdown}
                                  name="country"
                                  className="font-sans"
                                  label="Country"
                                  required
                                  placeholder="Select"
                                  labelKey="country"
                                  valueKey="country"
                                  options={countries}
                                  getValue={(data) => {
                                    setCountry(data);
                                    setState(true);
                                  }}
                                />
                              </div>
                              <div className="w-full">
                                <Field
                                  component={Dropdown}
                                  name="state"
                                  className="font-sans"
                                  label="State"
                                  required
                                  labelKey="name"
                                  valueKey="name"
                                  placeholder="Select"
                                  options={states}
                                  getValue={(data) => {
                                    setStateValue(data);
                                    setCity(true);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="lg:flex lg:gap-3 ">
                              <div className="w-full">
                                <Field
                                  component={Dropdown}
                                  name="city"
                                  className="font-sans"
                                  label="City"
                                  required
                                  // labelKey="city"
                                  // valueKey="city"
                                  placeholder="Select"
                                  options={cities}
                                />
                              </div>
                              <div className="w-full">
                                <Field
                                  component={TextInput}
                                  label="Pincode"
                                  required
                                  placeholder="123456"
                                  name="pincode"
                                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="my-2">
                          <NetBankingAlert />
                        </div>
                      )}
                      <div className="flex w-full py-4 space-x-8 md:justify-end">
                        <Button type="plain" onClick={setIsOpen}>
                          Cancel
                        </Button>
                        {alert ? (
                          <Button submit>Complete payment</Button>
                        ) : (
                          <button
                            disabled={
                              values.companyName?.length === 0 ||
                              values.address?.length === 0 ||
                              values.country?.length === 0 ||
                              values.state?.length === 0 ||
                              values.city?.length === 0 ||
                              values.pincode?.length === 0
                            }
                            className="bg-theme-green-500 text-white rounded-md p-2 disabled:bg-theme-green-200"
                            onClick={() => {
                              setAlert(true);
                            }}>
                            Proceed with payment
                          </button>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const NetBankingAlert = () => {
  return (
    <p className="text-center">
      NetBanking usually takes upto 48 hours to activate subscriptions.
      <br /> We recommend you to use either cards / UPI for faster checkouts.
    </p>
  );
};

const CustomPlan = ({ setIsOpen, planAmount, planType }) => {
  // contact number with country code and phone number
  const [countryCodeQuery, setCountryCodeQuery] = useState('');

  const { countryCodes } = useCountryCodeList(
    true,
    countryCodeQuery[0] === '+' ? countryCodeQuery.replace('+', '%2B') : `%2B${countryCodeQuery}`
  );
  const validate = (values) => {
    const errors = {};

    if (!values.countrycode) {
      errors.countrycode = 'Country Code Required';
    }

    const isValid = isValidPhoneNumber(values?.contact, values?.alphaCode);
    if (!isValid) {
      errors.contact = 'Enter valid phone number';
    }

    return errors;
  };
  const user = CurrentUser.getDetails();
  const { organization, mutate } = useOrganization(true, user.id);
  const { organizationType, loading } = useOrganizationType();
  const initialValues = {
    name: organization?.name || '',
    countrycode: organization?.country_code || '',
    contact: organization?.phone || '',
    email: organization?.email || '',
    amount: planAmount ? planAmount : '',
    planType: planAmount ? 'fixed' : planType,
    website: organization?.website || '',
    companyType: organization.org_type || '',
    message: '',
    alphaCode: ''
  };

  const submitForm = async (values) => {
    // const { countryCallingCode, phone } = parseNumberFormat(values.phoneNumber);
    const customData = {
      plan_request: {
        name: values.name,
        email: values.email,
        amount: values.amount,
        plan_type: values.planType,
        country_code: values.countrycode,
        contact: values.contact,
        website: values.website,
        organization_type: values.companyType || '',
        message: values.message
      }
    };
    const { data, error } = await API.post(`/plan_requests`, customData);
    if (!error) {
      toastedNote.success(data.response.message);
      mutate();
      setIsOpen();
    } else {
      toastedNote.error(error.message.message);
      console.log(error);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-5">
        <Loader size="large" />
      </div>
    );
  }

  return (
    <div>
      <h3 className="text-theme-gray-800 text-base md:text-[28px]">
        Tell us about your organization and how our sales team can help you?
      </h3>
      <div>
        <Formik
          validate={validate}
          initialValues={initialValues}
          validationSchema={customPlanValidation}
          onSubmit={submitForm}>
          <Form className="py-6 space-y-3">
            <Field
              required
              component={TextInput}
              name="name"
              label="Your name"
              placeholder="Your name"
            />
            <Field
              component={Contact}
              label="Contact"
              name="contact"
              countryCodeQuery={countryCodeQuery}
              setCountryCodeQuery={setCountryCodeQuery}
              countryCodes={countryCodes}
              placeholder="+91"
            />
            <Field
              name="amount"
              component={TextInput}
              label="Amount"
              placeholder="Amount for the plan"
              readOnly={planAmount ? true : false}
            />
            <Field
              name="planType"
              component={TextInput}
              label="Plan Type"
              placeholder="Plan Type"
              readOnly
            />
            <Field
              required
              name="email"
              component={TextInput}
              label="Email"
              placeholder="Email address"
            />
            {/* <Field
              name="amount"
              component={TextInput}
              label="Amount"
              placeholder="Amount for the plan"
              readOnly={planAmount ? true : false}
            /> */}
            {/* <Field
              name="planType"
              component={TextInput}
              label="Plan Type"
              placeholder="Plan Type"
              readOnly
            /> */}
            <Field
              name="website"
              component={TextInput}
              label="Website"
              placeholder="https://example.com"
            />
            <div className="w-full">
              <Field
                component={Dropdown}
                name="companyType"
                className="font-sans"
                label="Type of company"
                required
                labelKey="value"
                valueKey="id"
                placeholder="Select"
                options={organizationType}
              />
            </div>

            <Field required name="message" component={TextArea} label="Message" />
            <div className="flex justify-end w-full pt-2 space-x-6">
              <Button onClick={setIsOpen} type="plain" className="">
                Cancel
              </Button>
              <Button className="px-8" submit>
                Submit
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
