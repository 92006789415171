import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormLabel from './FormLabel';
import _ from 'lodash';
import FormError from './FormError';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useEffect } from 'react';
import { parsePhoneNumberWithError, parseNumber } from 'libphonenumber-js';

export const parsePhoneNumber = (phone) => {
  if (_.isEmpty(phone)) return { valid: false };
  return parseNumber(phone, { defaultCountry: 'IN', extended: true });
};

export const isValidMobileNumber = (phone) => {
  if (_.isEmpty(phone)) return { valid: false };
  const parsedNumber = parseNumber(phone, { defaultCountry: 'IN', extended: true });
  return parsedNumber.valid ? parsePhoneNumberWithError(phone, 'IN').getType() === 'MOBILE' : false;
};

export default function PhoneInput({
  field,
  form,
  className,
  label,
  tooltip,
  firstPlaceholder,
  ...props
}) {
  const meta = {
    touched: form?.touched[field.name] || false,
    error: form?.errors[field.name]
  };

  const initialPhoneNumber = form?.initialValues[field.name] || '';
  const parsedNumber = parsePhoneNumber(initialPhoneNumber).valid
    ? parsePhoneNumberWithError(initialPhoneNumber, 'IN') || ''
    : {};

  const [countryCode, setCountryCode] = useState(parsedNumber?.countryCallingCode || '');
  const [phoneNumber, setPhoneNumber] = useState(parsedNumber?.nationalNumber || '');
  // console.log(countryCode, phoneNumber, '');
  useEffect(() => {
    form?.setFieldValue(field.name, [countryCode, phoneNumber].join(''));
  }, [countryCode, phoneNumber]);

  return (
    <div
      className={classNames('flex flex-col border-none p-0 bg-transparent', className)}
      {...props}>
      {/* <FormLabel tooltip={tooltip}>{label}</FormLabel> */}
      <div className="relative flex w-full">
        <input
          {...field}
          {...props}
          placeholder={firstPlaceholder}
          value={countryCode}
          onBlur={field?.onBlur}
          onChange={(e) => {
            setCountryCode(e.target.value);
          }}
          type="text"
          maxLength={3}
          // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          className={classNames(
            'border-r-0 shadow-sm block sm:text-sm font-regular text-sm h-12 w-16 rounded-tl-md rounded-bl-md bg-[#f9f9f9]',
            meta.touched && meta.error
              ? 'border-red-300 border-r-gray-300 focus:border-r-gray-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:outline-none focus:ring-0'
              : 'border-gray-300 focus:border-gray-300 focus:ring-transparent text-neutral-700'
          )}
        />
        <input
          {...field}
          {...props}
          onBlur={field?.onBlur}
          value={phoneNumber}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          type="text"
          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          className={classNames(
            'appearance-none w-full shadow-sm sm:text-sm font-regular text-sm h-12 rounded-tr-md rounded-br-md',
            meta.touched && meta.error
              ? 'border-red-300 border-l-gray-300 focus:border-l-gray-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300  focus:outline-none focus:ring-0'
              : 'border-gray-300  focus:border-gray-300 focus:ring-transparent text-neutral-700 block'
          )}
        />
        <div className="absolute inset-y-0 right-0 flex items-center">
          {meta.touched && meta.error && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500 pointer-events-none"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      <FormError meta={meta} />
    </div>
  );
}

PhoneInput.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * placeholder if needed
   */
  placeholder: PropTypes.string,
  /**
   *if meessage need to be displayed
   */
  tooltip: PropTypes.string,
  /**
   * callback on click
   */
  label: PropTypes.string
};

PhoneInput.defaultProps = {
  className: '',
  placeholder: '',
  tooltip: '',
  label: ''
};
