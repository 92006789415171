import { CheckIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function Stat({ className, children, title, icon }) {
  return (
    <div className={classNames('flex flex-row md:space-x-4 space-x-3', className)}>
      <div className="w-9 h-9 md:w-16 md:h-16 rounded-full bg-theme-green-100 flex items-center justify-center m-1">
        {icon}
      </div>

      <div className="flex flex-col justify-between">
        <div className="font-bold text-2xl md:text-4xl">{children}</div>
        <div className="text-xxs text-theme-gray-600 md:text-sm font-regular">{title}</div>
      </div>
    </div>
  );
}

Stat.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * size of loader
   */
  title: PropTypes.string.isRequired,
  /**
   * what to display
   */
  children: PropTypes.number.isRequired,
  /**
   * callback on click
   */
  icon: PropTypes.node.isRequired
};

Stat.defaultProps = {
  className: ''
};
