/* eslint-disable no-unused-vars */
import { queryStringParseFuntion } from '../../utils/Helper';
import { useAPI } from '../network/SWR';

export function useOrganization(canCall = true, id) {
  const key = `/organizations/${id}`;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    organization: data?.organization || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useCountriesList(canCall = true) {
  const key = `https://countriesnow.space/api/v0.1/countries`;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    countries: data?.data || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

// export function useStatesList(canCall = true, country) {
//   const queryObject = {
//     country: country
//   };
//   const queryValues = queryStringParseFuntion(queryObject);
//   const key = `https://countriesnow.space/api/v0.1/countries/states`;
//   const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {
//     country: country
//   });
//   return {
//     states: data?.data?.states || [],
//     error,
//     loading,
//     mutate,
//     isValidating
//   };
// }

// export function useCitiesList(canCall = true, country, state) {
//   const queryObject = {
//     region_type: 'city',
//     country: country,
//     state: state
//   };
//   const queryValues = queryStringParseFuntion(queryObject);
//   const key = `https://countriesnow.space/api/v0.1/countries/state/cities`;
//   const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {
//     country: country,
//     state: state
//   });
//   return {
//     cities: data?.regions || [],
//     error,
//     loading,
//     mutate,
//     isValidating
//   };
// }

export function usePlanDetails(canCall = true, id) {
  const key = `/pricings/${id}`;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    plan: data || {},
    error,
    loading,
    mutate,
    isValidating
  };
}
