import { queryStringParseFuntion } from '../../utils/Helper';
import { useAPI } from '../network/SWR';

export function useBillingHistory(canCall = true, { page = 1 }) {
  const queryQbject = {
    page,
    per_page: 10
  };
  const queryValues = queryStringParseFuntion(queryQbject);
  const key = `/payments${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {
    suspense: true
  });
  return {
    billing_history: data?.payments || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.pagination || null
  };
}
