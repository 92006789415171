import { UsersIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './unlockButton.css';

export default function UnlockCount({ className, children, shadow, subScriptionPresent, count }) {
  return (
    <a
      data-tooltip={Number(children) === 0 ? 'Contact admin' : null}
      href="/jobs"
      className={classNames(
        'flex flex-row space-x-2 text-theme-green-500 bg-theme-green-100 h-8 rounded-full items-center justify-center px-5 py-1',
        shadow ? 'shadow' : 'shadow-none',
        className
      )}>
      <UsersIcon className="w-5 h-5" />
      <span className="text-base">
        {children} {subScriptionPresent ? 'unlocks available.' : 'free unlocks.'}
      </span>
    </a>
  );
}

UnlockCount.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,

  /**
   * to show shadow or not
   */
  shadow: PropTypes.bool,
  /**
   * what to display
   */
  children: PropTypes.string.isRequired
};

UnlockCount.defaultProps = {
  className: '',
  shadow: false
};
