/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useBillingHistory } from '../../lib/services/billingHistoryServices';
import EmptyPage from 'ui/stories/EmptyPage';
import Loader from 'ui/stories/Loader';
import Table from 'ui/stories/Table';
import ApplicantStatus from 'ui/stories/ApplicantStatus';
import TableLayout from '../../components/tableLayout/TableLayout';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { API } from '../../lib/network/API';
import { saveAs } from 'file-saver';
import { crossDomainToken } from '../../lib/network/Auth';
import { useState } from 'react';
import useDocumentTitle from '../../utils/useDocumentTitle';

export default function BillingHistory() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [dataId, setDataId] = useState(null);
  const pageNumberQuery = new URLSearchParams(location.search).get('pageNo') || 1;
  const { billing_history, error, mutate, isValidating, loading, paginationInfo } =
    useBillingHistory(true, { page: pageNumberQuery });

  useDocumentTitle(`Billing: History`);

  const downloadPDF = async (id, invoice_number, plan_name) => {
    setDataId(id);
    setIsLoading(true);
    const res = await fetch(`https://api.nurseindia.org/v1/payments/${id}/download_invoice`, {
      headers: {
        Authorization: crossDomainToken()
      }
    });
    const pdff = await res.blob();
    saveAs(pdff, `${invoice_number}.pdf`);
    setIsLoading(false);
  };

  const columnData = [
    {
      field: 'Plan',
      render: (rowData) => (
        <div className="text-sm leading-6 text-gray-600 font-regular">{rowData.plan_name}</div>
      )
    },
    {
      field: 'Invoice number',
      render: (rowData) => (
        <div className="text-sm leading-6 text-gray-600 font-regular">{rowData.invoice_number}</div>
      )
    },
    {
      field: 'Payment Period',
      render: (rowData) => (
        <div className="text-sm leading-6 text-gray-600 font-regular">{rowData.payment_period}</div>
      )
    },
    {
      field: 'Amount',
      render: (rowData) => (
        <div className="text-sm leading-6 text-gray-600 font-regular">
          {' '}
          &#8377; {rowData.amount}
        </div>
      )
    },
    {
      field: 'Status',
      render: (rowData) => (
        <div className="text-sm text-gray-600 ">
          <ApplicantStatus type={rowData.status}>{rowData.status}</ApplicantStatus>
        </div>
      )
    },
    {
      field: '',
      render: (rowData) => (
        <div className="px-5 text-xs text-gray-600 lg:px-0">
          <button
            onClick={() => {
              downloadPDF(rowData.id, rowData.invoice_number, rowData.plan_name);
            }}
            className="flex items-center gap-1 font-regular">
            {isLoading && dataId === rowData.id ? (
              <Loader key={rowData.id} size="small" />
            ) : (
              <img src="/download.png" alt="download resume" />
            )}
            Download
          </button>
        </div>
      )
    }
  ];
  useEffect(() => {}, []);

  const locaderContentHelper = (billing) => {
    if (loading || isValidating) {
      return (
        <div className="flex items-center justify-center h-96">
          <Loader size="large" />
        </div>
      );
    } else if (billing_history && _.isEmpty(billing_history)) {
      return (
        <div className="py-16">
          <EmptyPage
            title="No data available"
            showButton={false}
            imgURL="/cardboardEmpty.svg"
            className="object-contain border-none !bg-transparent"
          />
        </div>
      );
    } else {
      return (
        <div>
          <Table columnData={columnData} data={billing} />
        </div>
      );
    }
  };
  return (
    <div className="max-w-full p-5 2xl:max-w-7xl ">
      <h3 className="pt-8 pb-2 text-3xl text-theme-gray-500">
        Billing <span className="text-black">/ history</span>
        <div className="py-5">
          <TableLayout filter loading={loading} paginationInfo={paginationInfo}>
            {locaderContentHelper(billing_history)}
          </TableLayout>
        </div>
      </h3>
    </div>
  );
}
