export const FacebookIcon = (props) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={48} height={48} rx={24} fill="#03725F" />
    <path
      d="M32.125 24a8.149 8.149 0 0 1-7.148 8.07.32.32 0 0 1-.352-.312v-5.882H26.5a.627.627 0 0 0 .625-.665.641.641 0 0 0-.648-.585h-1.852V22.75a1.25 1.25 0 0 1 1.25-1.25h1.25a.627.627 0 0 0 .625-.665.641.641 0 0 0-.648-.585h-1.227a2.5 2.5 0 0 0-2.5 2.5v1.875H21.5a.625.625 0 0 0-.625.664.64.64 0 0 0 .648.586h1.852v5.882a.313.313 0 0 1-.344.313c-4.125-.5-7.312-4.086-7.148-8.375A8.125 8.125 0 0 1 32.125 24z"
      fill="#fff"
    />
  </svg>
);

export const LinkedinIcon = (props) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={48} height={48} rx={24} fill="#03725F" />
    <path
      d="M30.563 16.188H17.438a1.25 1.25 0 0 0-1.25 1.25v13.125a1.25 1.25 0 0 0 1.25 1.25h13.125a1.25 1.25 0 0 0 1.25-1.25V17.438a1.25 1.25 0 0 0-1.25-1.25zM21.5 27.75a.624.624 0 1 1-1.25 0v-5a.624.624 0 1 1 1.25 0v5zm-.625-6.563a.937.937 0 1 1 0-1.874.937.937 0 0 1 0 1.875zm7.5 6.563a.624.624 0 1 1-1.25 0v-2.813a1.563 1.563 0 0 0-3.125 0v2.813a.624.624 0 1 1-1.25 0v-5a.626.626 0 0 1 1.234-.14 2.812 2.812 0 0 1 4.391 2.328v2.812z"
      fill="#fff"
    />
  </svg>
);

export const InstagramIcon = (props) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={48} height={48} rx={24} fill="#03725F" />
    <path d="M24 26.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" fill="#fff" />
    <path
      d="M27.438 16.188h-6.875a4.375 4.375 0 0 0-4.375 4.375v6.875a4.375 4.375 0 0 0 4.375 4.375h6.875a4.375 4.375 0 0 0 4.375-4.375v-6.875a4.375 4.375 0 0 0-4.375-4.375ZM24 27.75a3.75 3.75 0 1 1 0-7.5 3.75 3.75 0 0 1 0 7.5Zm4.063-6.875a.937.937 0 1 1 0-1.875.937.937 0 0 1 0 1.875Z"
      fill="#fff"
    />
  </svg>
);

export const MailIcon = (props) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={48} height={48} rx={24} fill="#03725F" />
    <path
      d="M31.5 17.75h-15a.624.624 0 0 0-.625.625V29a1.25 1.25 0 0 0 1.25 1.25h13.75a1.25 1.25 0 0 0 1.25-1.25V18.375a.624.624 0 0 0-.625-.625ZM30.875 29h-13.75v-9.203l6.453 5.914a.625.625 0 0 0 .844 0l6.453-5.914V29Z"
      fill="#fff"
    />
  </svg>
);

export const MedalIcon = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8 9.5C9.933 9.5 11.5 7.933 11.5 6C11.5 4.067 9.933 2.5 8 2.5C6.067 2.5 4.5 4.067 4.5 6C4.5 7.933 6.067 9.5 8 9.5Z"
      fill="#64748B"
    />
    <path
      d="M8.00104 0.500011C6.86794 0.497703 5.76186 0.845813 4.83432 1.49664C3.90679 2.14748 3.20318 3.06919 2.81991 4.13549C2.43665 5.2018 2.39246 6.36053 2.69342 7.45293C2.99438 8.54532 3.62575 9.51794 4.50104 10.2375V15C4.50053 15.0853 4.52214 15.1693 4.56376 15.2438C4.60539 15.3183 4.6656 15.3807 4.73854 15.425C4.81089 15.4705 4.89368 15.4967 4.97903 15.501C5.06437 15.5054 5.14941 15.4879 5.22604 15.45L8.00104 14.0563L10.776 15.45C10.847 15.4814 10.9235 15.4984 11.001 15.5C11.1336 15.5 11.2608 15.4473 11.3546 15.3536C11.4484 15.2598 11.501 15.1326 11.501 15V10.2375C12.3763 9.51794 13.0077 8.54532 13.3087 7.45293C13.6096 6.36053 13.5654 5.2018 13.1822 4.13549C12.7989 3.06919 12.0953 2.14748 11.1678 1.49664C10.2402 0.845813 9.13413 0.497703 8.00104 0.500011ZM8.00104 10.5C7.11102 10.5 6.24099 10.2361 5.50097 9.74162C4.76095 9.24716 4.18417 8.54435 3.84358 7.72209C3.50298 6.89982 3.41387 5.99502 3.5875 5.1221C3.76114 4.24919 4.18972 3.44737 4.81906 2.81803C5.44839 2.18869 6.25022 1.76011 7.12313 1.58648C7.99604 1.41284 8.90084 1.50196 9.72311 1.84255C10.5454 2.18315 11.2482 2.75992 11.7427 3.49995C12.2371 4.23997 12.501 5.11 12.501 6.00001C12.4994 7.19298 12.0247 8.33661 11.1812 9.18016C10.3376 10.0237 9.194 10.4984 8.00104 10.5Z"
      fill="#64748B"
    />
  </svg>
);

export const CurrencyIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect width="24" height="24" rx="12" fill="white" />
    <path
      d="M15.5 9.6V9.25C15.5 7.68125 13.1375 6.5 10 6.5C6.8625 6.5 4.5 7.68125 4.5 9.25V11.75C4.5 13.0563 6.1375 14.0938 8.5 14.4062V14.75C8.5 16.3188 10.8625 17.5 14 17.5C17.1375 17.5 19.5 16.3188 19.5 14.75V12.25C19.5 10.9563 17.9125 9.91875 15.5 9.6ZM8.5 13.3875V11.9062C8.99742 11.9702 9.49849 12.0015 10 12C10.5015 12.0015 11.0026 11.9702 11.5 11.9062V13.3875C11.0038 13.4648 10.5022 13.5024 10 13.5C9.4978 13.5024 8.99621 13.4648 8.5 13.3875ZM14.5 10.8688V11.75C14.5 12.275 13.725 12.8375 12.5 13.1812V11.7188C13.3062 11.525 13.9875 11.2313 14.5 10.8688ZM5.5 11.75V10.8688C6.0125 11.2313 6.69375 11.525 7.5 11.7188V13.1812C6.275 12.8375 5.5 12.275 5.5 11.75ZM9.5 14.75V14.4875L10 14.5C10.2458 14.5 10.4833 14.4937 10.7125 14.4812C10.9625 14.5687 11.225 14.6437 11.5 14.7125V16.1812C10.275 15.8375 9.5 15.275 9.5 14.75ZM12.5 16.3875V14.9C12.9974 14.9652 13.4984 14.9987 14 15C14.5015 15.0015 15.0026 14.9702 15.5 14.9062V16.3875C14.5056 16.5375 13.4944 16.5375 12.5 16.3875ZM16.5 16.1812V14.7188C17.3062 14.525 17.9875 14.2312 18.5 13.8687V14.75C18.5 15.275 17.725 15.8375 16.5 16.1812Z"
      fill="#64748B"
    />
  </svg>
);

export const CloseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
    {...props}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
  </svg>
);

export const FilterIcon = (props) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x="0.25" width="28" height="28" rx="12" fill="white" />
    <path
      d="M15.8125 17.4375H7.375"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.125 17.4375H18.9375"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M17.375 19C18.2379 19 18.9375 18.3004 18.9375 17.4375C18.9375 16.5746 18.2379 15.875 17.375 15.875C16.5121 15.875 15.8125 16.5746 15.8125 17.4375C15.8125 18.3004 16.5121 19 17.375 19Z"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.8125 10.5625H7.375"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.125 10.5625H13.9375"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.375 12.125C13.2379 12.125 13.9375 11.4254 13.9375 10.5625C13.9375 9.69956 13.2379 9 12.375 9C11.5121 9 10.8125 9.69956 10.8125 10.5625C10.8125 11.4254 11.5121 12.125 12.375 12.125Z"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const SortIcon = (props) => (
  <svg
    width="29"
    height="28"
    viewBox="0 0 29 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x="0.25" width="28" height="28" rx="12" fill="white" />
    <path
      d="M13 17.75L10.5 20.25L8 17.75"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.5 7.75V20.25"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.5 10.25L18 7.75L20.5 10.25"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 20.25V7.75"
      stroke="#64748B"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ShareIcon = (props) => (
  <svg width={12} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.5 9.001a2.48 2.48 0 0 0-1.786.756l-2.88-1.849a2.518 2.518 0 0 0 0-1.812l2.88-1.85a2.5 2.5 0 1 0-.712-1.743c0 .31.058.617.168.906L4.29 5.26a2.5 2.5 0 1 0 0 3.486l2.88 1.85A2.5 2.5 0 1 0 9.5 9.002Z"
      fill="#008B73"
    />
  </svg>
);
