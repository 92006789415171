/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from 'react';
import { useState } from 'react';
import ProgressTracker from 'ui/stories/ProgressTracker';
import Stepper from 'ui/stories/Stepper';
import Heading2 from 'ui/stories/Heading2';
import { Form, Formik, Field, FieldArray, useFormikContext } from 'formik';
import {
  jobPosting1Validation,
  jobPosting2Validation
} from '../../lib/formikValidations/validation';
import {
  useAdditionalQualification,
  useBenefits,
  useFindAllCategories,
  useFindAllCurrency,
  useFindAllSpecialization,
  useJobQualifications,
  useLocations
} from '../../lib/services/dropDownServices';
import { EligibilityForm, RoleDetailForm } from '../../components/jobpost/JobPostFields';
import { Button } from 'ui/stories/Button';
import { API } from '../../lib/network/API';
import { useNavigate } from 'react-router-dom';
import { DynamicData } from '../../components/jobpost/DynamicData';
import classNames from 'classnames';
import { TitleContext } from '../../lib/context/JobContext';
import { handleImageChange } from '../../utils/Helper';
import { CurrentUser } from '../../lib/network/Auth';
import _ from 'lodash';
import Modal from 'ui/stories/Modal';
import { CloseIcon } from 'ui/stories/assets/Logos';
import CheckBox from 'ui/stories/CheckBox';
import TextInput from 'ui/stories/TextInput';

const initialValues = {
  hospital_name: '',
  hospital_image: {},
  hospital_url: '',
  job_title: '',
  job_description: '',
  category: '',
  specialization: '',
  salary: {
    amount: '',
    currency: ''
  },
  location: '',
  vacancies: '',
  qualification: '',
  additionalQualifications: [],
  minYears: '',
  maxYears: '',
  benefits: [],
  other_benefits: [''],
  email: '',
  termsAndConditions: false,
  qualifyingQuestions: [
    { question: '', choice_answer: '' },
    { question: '', choice_answer: '' },
    { question: '', choice_answer: '' }
  ]
};

function QuestionComponent({ questionIndex, index }) {
  return (
    <div className="p-4 bg-[#EEF2FC] border-[#D7DEEF] rounded-lg">
      <Field
        component={TextInput}
        label={`Question ${questionIndex}`}
        type="text"
        name={`qualifyingQuestions.${index}.question`}
      />
      <div className="flex flex-row justify-between font-regular font-normal text-sm leading-6 text-[#404759]">
        <div className={'relative flex items-start'}>
          <div className="flex h-5 items-center mt-4">
            <Field
              component={CheckBox}
              name={`qualifyingQuestions.${index}.choice_answer`}
              subtitle="Choice answer (Yes/No)"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const QualifyingQuestions = ({ setModalIsOpen, setSkipQuestions, setFormSubmit }) => {
  const { values, submitForm } = useFormikContext();
  return (
    <div className="font-sans">
      <div className="flex justify-between border-b border-[#E4E8F5] py-4 px-6">
        <h4 className="text-2xl text-gray-800 leading-7">
          Add qualifying questions
          <span className="text-xl text-gray-800 leading-7">(Optional)</span>
        </h4>
        <CloseIcon className="cursor-pointer h-6 font-sans" onClick={() => setModalIsOpen(false)} />
      </div>
      <FieldArray
        name="qualifyingQuestions"
        render={(arrayHelpers) => (
          <div className="flex flex-col gap-y-4 py-6 px-6">
            {values.qualifyingQuestions?.map((item, idx) => (
              <QuestionComponent key={idx} index={idx} questionIndex={idx + 1} values={values} />
            ))}
          </div>
        )}
      />
      <p className="font-regular font-normal text-sm leading-6 opacity-60 text-[#404759] px-6">
        The open text answers will only be in numerical format.
      </p>
      <div className="flex flex-col mt-6 mb-6 px-6">
        <Button
          type="primary"
          label="Submit"
          onClick={() => {
            setFormSubmit(true);
            submitForm();
            setModalIsOpen(false);
          }}
          className="w-full"
        />
        <Button
          type="plain"
          label="Skip questions"
          className="w-full"
          onClick={() => {
            setFormSubmit(true);
            submitForm();
            setSkipQuestions(true);
            setModalIsOpen(false);
          }}
        />
      </div>
    </div>
  );
};

export default function JobPostingLayout({ togglePreview }) {
  const getSection = document.getElementById('second_sec');
  const parentDiv = document.getElementById('parentDiv');
  const scrollToTop = () => {
    parentDiv.scrollIntoView({
      behavior: 'smooth'
    });
  };

  if (getSection !== null) {
    getSection.scrollIntoView({ behavior: 'smooth' });
  }
  const navigate = useNavigate();

  const { setThanksTitle } = useContext(TitleContext);

  const [formSubmit, setFormSubmit] = useState(false);
  const [skipQuestions, setSkipQuestions] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [completeState, setCompleteState] = useState(0);
  const [formToggle, setFormToggle] = useState(0);
  const [filled, setFilled] = useState(0);
  const [formValues, setFormValues] = useState({});
  const [query, setQuery] = useState('');
  const [jobId, setJobId] = useState('');
  const [locationObj, setLocationObj] = useState({});

  const { qualifications } = useJobQualifications();
  const { categories } = useFindAllCategories();
  const { specialization } = useFindAllSpecialization();
  const { currency } = useFindAllCurrency();
  const { benefits } = useBenefits();
  const { locations } = useLocations(true, query);

  const handleToggleForm = async () => {
    setFormToggle((prev) => (prev === 0 ? 1 : 0));
  };

  const submitJobForm = async (values, actions) => {
    const org_id = CurrentUser.getDetails().id;
    const data = await handleImageChange(values?.hospital_image, org_id);

    const roleDetailData = {
      job_posting: {
        name: values.hospital_name,
        title: values.job_title,
        description: values.job_description,
        category_id: values.category,
        currency: values.salary.currency,
        salary: values.salary.amount,
        city: values.location,
        state: locationObj?.state,
        country: locationObj?.country,
        count: values.vacancies,
        work_area: values.specialization,
        logo_attributes: {
          url: data?.signed_url,
          file_type: 'image'
        }
      }
    };
    const eligibilityData = {
      job_posting: {
        required_qualification: values.qualification,
        additional_qualification: values.additionalQualifications,
        min_experience: values.minYears,
        max_experience: values.maxYears,
        benefits: {
          default: values.benefits,
          custom: values.other_benefits[0] === '' ? [] : values.other_benefits
        },
        recruiter_email: values.email,
        termsAndConditions: values.termsAndConditions,
        qualify_questions_attributes: skipQuestions
          ? []
          : values?.qualifyingQuestions?.filter((item) => item?.question != '')
      }
    };
    actions.setSubmitting(false);

    if (formToggle && formSubmit) {
      const { data, error } = await API.put(`/job_postings/${jobId}?submit=true`, eligibilityData);
      setThanksTitle(values.job_title);
      if (!error) {
        navigate('/job-success');
      }
    } else {
      if (jobId) {
        return;
      }
      const { data, error } = await API.post('/job_postings', roleDetailData);
      if (!error) {
        setJobId(data.response.data.id);
      }
    }
  };

  const toggleValidation = {
    0: jobPosting1Validation,
    1: jobPosting2Validation
  };

  const fullObj = (val) => {
    setLocationObj(val);
  };

  const showForm = (formState, values, setFieldValue) => {
    switch (formState) {
      case 0:
        return (
          <RoleDetailForm
            categories={categories}
            specialization={specialization}
            query={query}
            setQuery={setQuery}
            locations={locations}
            currency={currency}
            hospitalImg={values.hospital_url}
            fullObj={fullObj}
          />
        );
      case 1:
        return (
          <EligibilityForm
            values={values}
            setFieldValue={setFieldValue}
            qualifications={qualifications}
            benefits={benefits}
          />
        );
      default:
        return <RoleDetailForm categories={categories} />;
    }
  };

  useEffect(() => {
    setCompleteState(formToggle !== 0 ? 50 : 0);
  }, [formToggle]);

  return (
    <div className="mt-20 md:flex w-full overflow-x-hidden overflow-y-auto h-screen">
      <div
        className={classNames(
          !togglePreview ? 'flex-1' : 'hidden',
          ' h-auto relative overflow-y-auto scrollbar-hide'
        )}>
        <div className="absolute bottom-0">
          <div
            className="hidden md:block"
            style={{
              position: 'absolute',
              width: '533px',
              height: '400px',
              left: '-186px',
              bottom: '-300px',
              background: '#008B73',
              opacity: '0.5',
              filter: 'blur(260px)',
              zIndex: -10
            }}
          />
          <div
            className="md:hidden"
            style={{
              position: 'absolute',
              width: '533px',
              height: '400px',
              left: '-186px',
              bottom: '0px',
              background: '#008B73',
              opacity: '0.5',
              filter: 'blur(260px)',
              zIndex: -10
            }}
          />
          <div
            className="hidden md:block"
            style={{
              position: 'absolute',
              width: '533px',
              height: '400px',
              left: '225px',
              bottom: -'0px',
              background: '#5A5BBD',
              opacity: '0.5',
              filter: 'blur(280px)',
              zIndex: -10
            }}
          />
          <div
            className="md:hidden"
            style={{
              position: 'absolute',
              width: '200px',
              height: '400px',
              left: '105px',
              bottom: '-0px',
              background: '#5A5BBD',
              opacity: '0.7',
              filter: 'blur(280px)',
              zIndex: -10
            }}
          />
        </div>
        <ProgressTracker>{completeState}</ProgressTracker>
        <div className="px-5 pb-5 xl:px-20  mt-11">
          <div className="flex space-x-8">
            <Stepper number={1} active={formToggle === 0} completed={filled >= 1}>
              Role Details
            </Stepper>
            <Stepper number={2} active={formToggle === 1} completed={filled >= 2}>
              Eligibility
            </Stepper>
          </div>
          <Heading2 className="!text-left mt-12 text-theme-purple-500">
            Create your job listing
          </Heading2>
          {/*Formik come here */}
          <div className="mt-10">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={submitJobForm}
              validationSchema={toggleValidation[formToggle]}>
              {({ values, setFieldValue, errors, isValid }) => {
                document
                  .getElementsByClassName(Object.keys(errors)[0] + '-ErrorMessage')[0]
                  ?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end'
                  });
                setFormValues(values);
                return (
                  <Form className="space-y-6">
                    {showForm(formToggle, values, setFieldValue)}
                    <div
                      className={`flex  ${formToggle !== 0 ? 'justify-between' : 'justify-end'}`}>
                      {formToggle !== 0 && (
                        <Button
                          submit
                          onClick={() => {
                            scrollToTop();
                            setFormToggle(formToggle - 1);
                            setFilled((prev) => prev - 1);
                          }}
                          type="secondary"
                          className="w-40 m-2">
                          Back
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          if (formToggle && isValid && values?.termsAndConditions) {
                            setModalIsOpen(true);
                          } else if (!formToggle && isValid && values?.vacancies) {
                            handleToggleForm();
                            setFilled((prev) => prev + 1);
                            setCompleteState(values.termsAndConditions ? 100 : 50);
                          }
                        }}
                        submit
                        type="primary"
                        label={formToggle ? 'Post a job' : 'Next'}
                        className="w-40 m-2"
                      />
                      <Field
                        component={Modal}
                        isOpen={modalIsOpen}
                        setIsOpen={setModalIsOpen}
                        dialogClassName="!p-0 !w-[610px]">
                        <QualifyingQuestions
                          setModalIsOpen={setModalIsOpen}
                          setFormSubmit={setFormSubmit}
                          setSkipQuestions={setSkipQuestions}
                          setFieldValue={setFieldValue}
                        />
                      </Field>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          togglePreview ? 'flex flex-col' : 'hidden',
          'gap-6 p-5 md:p-12 md:flex md:flex-col md:w-3/6 md:shadow-lg md:h-full md:gap-6 overflow-y-auto'
        )}>
        {/*Dynamic data loads here */}
        <DynamicData
          locationObj={locationObj}
          togglePreview={togglePreview}
          formValues={formValues}
          categories={categories}
          specialization={specialization}
          currency={currency}
          qualifications={qualifications}
          benefits={benefits}
        />
      </div>
    </div>
  );
}
