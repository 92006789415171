import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Logo } from './Logo';

export default function Footer({ socialLinks, sections, className, ...rest }) {
  const mappedIcons = (items) =>
    items.map((item, key) => (
      <a href={item.link} key={key} className="w-12 h-12">
        {item.icon}
      </a>
    ));

  const mappedSections = (items) =>
    items.map((item, key) => (
      <span key={key} className="mt-4 text-sm text-white font-regular">
        <a href={item.link}>{item.name}</a>
      </span>
    ));

  return (
    <div className="w-full bg-footer-bg">
      <footer
        className={classNames(
          'py-10 px-5 lg:px-28 flex flex-col md:flex-row max-w-[1248px] lg:gap-32 sm:gap-16',
          className
        )}
        {...rest}>
        <div className="md:mt-4">
          <Logo whiteWashed={true} className="w-24" />
          <p className="max-w-md mt-4 text-sm text-theme-green-100 md:mt-2 font-regular">
            NurseIndia, a unit of Purple Haze Ventures LLP is a Web based application to provide a
            platform for nurses to serve them better jobs, social experience and learning skills for
            their profession.
          </p>
          <div className="hidden my-4 space-x-2 md:flex">{mappedIcons(socialLinks)}</div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.f22labs.com"
            className="hidden mt-4 text-sm text-white w-fit md:flex font-regular">
            {'Powered By F22 Labs'}
          </a>
        </div>
        <div className="flex flex-col md:flex-row sm:gap-16 lg:gap-32">
          {sections.map((sec, key) => (
            <div key={key} className="flex flex-col">
              {mappedSections(sec)}
            </div>
          ))}
        </div>
        <div className="flex my-4 space-x-2 md:hidden">{mappedIcons(socialLinks)}</div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.f22labs.com"
          className="text-sm text-white w-fit md:hidden font-regular">
          {'Powered By F22 Labs'}
        </a>
      </footer>
    </div>
  );
}

Footer.propTypes = {
  /* 
        list of icons to show
    */
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      link: PropTypes.string.isRequired
    })
  ),
  /* 
        list of links to show inside about section
    */
  sections: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string
      })
    )
  ),
  /* 
        additional classNames to add on
    */
  className: PropTypes.string
};

Footer.defaultProps = {
  sections: [
    [
      { name: 'About us', link: '/' },
      { name: 'Privacy Policy', link: '/' },
      { name: 'Our corporate clients', link: '/' }
    ],
    [
      { name: 'Contact Us', link: '/' },
      { name: 'Blog', link: '/' },
      { name: 'FAQ', link: '/' }
    ]
  ]
};
