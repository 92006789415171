// create SWR services here
import { useAPI } from '../network/SWR';

export function useFindAllCategories(canCall = true) {
  const key = 'configs/options?config_key=job_category';
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    categories: data?.response?.configurations || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useFindAllSpecialization(canCall = true) {
  const key = 'configs/options?config_key=job_specialization';
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    specialization: data?.response?.configurations || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useFindAllCurrency(canCall = true) {
  const key = 'configs/options?config_key=currency';
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    currency: data?.response?.configurations || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useJobQualifications(canCall = true) {
  const key = '/configs/options?config_key=job_qualification';

  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});

  return {
    qualifications: data?.response?.configurations || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useAdditionalQualification(canCall = true) {
  const key = '/configs/options?config_key=job_additional_qualification';

  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});

  return {
    additionalQualifications: data?.response?.configurations || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useBenefits(canCall = true) {
  const key = '/configs/options?config_key=job_benefit';

  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});

  return {
    benefits: data?.response?.configurations || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useLocations(canCall = true, query) {
  const key = '/cities';
  const key2 = `/cities?city=${query}`;
  const regionKey = query.length ? key2 : key;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? regionKey : null, {});

  return {
    locations: data?.cities || [],
    error,
    loading,
    mutate,

    isValidating
  };
}

export function useCountryCodeList(canCall = true, query) {
  const key = '/country_code';
  const key2 = `/country_code?code=${query}`;
  const codeKey = query.length ? key2 : key;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? codeKey : null, {});

  return {
    countryCodes: data?.country_codes || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

// export function useCountryCodeList(canCall = true, query) {
//   const key = '/country_code';
//   const key2 = `/country_code?code=${query}`;
//   const codeKey = query.length ? key2 : key;
//   const { data, error, loading, mutate, isValidating } = useAPI(canCall ? codeKey : null, {});

//   return {
//     countryCodes: data?.country_codes || [],
//     error,
//     loading,
//     mutate,
//     isValidating
//   };
// }
