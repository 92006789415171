import { parseNumber } from 'libphonenumber-js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from '../lib/network/API';
import _ from 'lodash';
import axios from 'axios';
import { saveAs } from 'file-saver';

/* Toasted notes */
export const toastedNote = {
  error: (title, options) => toast.error(title, options),
  info: (title, options) => toast.info(title, options),
  success: (title, options) => toast.success(title, options),
  warn: (title, options) => toast.warn(title, options)
};

export const parseNumberFormat = (phone) => {
  if (_.isEmpty(phone)) return false;
  return parseNumber(phone, { defaultCountry: 'IN', extended: true });
};

export const parseSortBy = (sort) => {
  console.log(sort);
  switch (sort) {
    case 'Newest Post':
      return {
        criteria: 'order',
        orderBy: 'desc'
      };
    case 'Oldest Post':
      return {
        criteria: 'order',
        orderBy: 'asc'
      };
    case 'Most Applied':
      return {
        criteria: 'applications_count',
        orderBy: 'desc'
      };
    case 'Least Applied':
      return {
        criteria: 'applications_count',
        orderBy: 'asc'
      };
  }
};

const formatDate = (value) => {
  let date = new Date(value);
  const day = date.toLocaleString('default', { day: '2-digit' });
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.toLocaleString('default', { year: 'numeric' });
  return day + ' ' + month + ' ' + year;
};

export function getLastMonthDate() {
  var lastMonth = new Date();
  lastMonth.setMonth(lastMonth.getMonth() - 1);
  return formatDate(lastMonth);
}

export function getLastWeeksDate() {
  const now = new Date();
  return formatDate(new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000));
}
export function getLastDayDate() {
  const now = new Date();
  return formatDate(new Date(now.getTime() - 1 * 24 * 60 * 60 * 1000));
}

export const parseDatePosted = (date) => {
  const today = formatDate(Date.now());
  console.log(date);
  switch (date) {
    case 'any_time':
      return '';
    case 'past_24_hrs':
      return {
        startDate: getLastDayDate(),
        endDate: today
      };
    case 'past_week':
      return {
        startDate: getLastWeeksDate(),
        endDate: today
      };
    case 'past_month':
      return {
        startDate: getLastMonthDate(),
        endDate: today
      };
  }
};
export const findCurrencySign = (currency) => {
  switch (currency) {
    case 'INR':
      return '₹';
    case 'USD':
      return '$';
    case 'Euro':
      return '€';
    case 'Pound':
      return '£';
    default:
      return '₹';
  }
};
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};
export const intervalScroll = () => {
  setTimeout(() => {
    scrollToTop();
  }, 500);
};

export const handlePdfChange = async (file, id) => {
  // console.log(file, 'from helper');
  if (file.name) {
    const fileName = file.name
      .substring(0, file.name.lastIndexOf('.'))
      .replace(' ', '_')
      .replace(/[^a-zA-Z0-9.?,]/g, '');
    const extension = _.last(file.name.split('.'));
    const { data, error } = await API.get(
      `https://api.nurseindia.org/v1/signed_url?path=organizations/${id}/${fileName}.${extension}`
    );
    if (error) {
      toastedNote.error("Can't able to upload image");
    }
    // console.log(data.response.url);
    try {
      const uploader = axios.create({});
      const file_type = file.type.includes('image')
        ? 'image'
        : file.type.includes('application')
        ? 'document'
        : 'other';
      uploader.put(data.response.url, file, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return { signed_url: data.response.url, file_type: file_type, success: true };
    } catch (e) {
      console.log('error uploading file', e, e.response);
      return { success: false };
    }
  }
};

export const parseHTML = (htmldata) => {
  const desc = _.chain(htmldata)
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace('Job Description', '')
    .replace('Job description', '')
    .value();
  return desc.substring(0, 200);
};

export const downloadPDF = async (link, fileName) => {
  const res = await fetch(link, {});
  const pdff = await res.blob();
  saveAs(pdff, `${fileName}`);
};

export const handleImageChange = async (file, id) => {
  // console.log(file, 'from helper');
  if (file.name) {
    const fileName = Date.now();
    const extension = _.last(file.name.split('.'));
    const { data, error } = await API.get(
      `https://api.nurseindia.org/v1/signed_url?path=organizations/${id}/${fileName}.${extension}`
    );
    if (error) {
      toastedNote.error('Cannot upload image');
    }
    // console.log(data.response.url);
    try {
      const uploader = axios.create({});
      const file_type = file.type.includes('image')
        ? 'image'
        : file.type.includes('application')
        ? 'document'
        : 'other';
      uploader.put(data.response.url, file, {
        headers: {
          'Content-Type': 'image/*'
        }
      });

      return { signed_url: data.response.url, file_type: file_type, success: true };
    } catch (e) {
      console.log('error uploading file', e, e.response);
      return { success: false };
    }
  }
};

export const hyphenateString = (str) => {
  const hypenatedString = str
    .replace(/[^a-zA-Z0-9 ]/g, '')
    .replace(/[ ]+/g, '-')
    .toLowerCase();
  return hypenatedString;
};
