import { XMarkIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Icon from './Icon';

export default function CheckTag({ className, selected, closed, children, onClick, ...rest }) {
  return (
    <button
      {...rest}
      onClick={onClick}
      className={classNames(
        'px-3 py-1.5 text-center inline-block rounded-md text-sm font-regular',
        selected ? 'bg-theme-green-500 text-white ' : 'bg-theme-green-300 text-theme-gray-700 ',
        className
      )}>
      <div className=" flex flex-row space-x-2.5 items-center h-5">
        {selected && <Icon icon="tick" className="w-5 h-5 text-white" />}
        <span>{children}</span>
        {closed && <XMarkIcon className="w-4 h-4" />}
      </div>
    </button>
  );
}

CheckTag.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * size of loader
   */
  selected: PropTypes.bool,
  /**
   * closed icon
   */
  closed: PropTypes.bool,
  /**
   * what to display
   */
  children: PropTypes.string.isRequired,
  /**
   * callback on click
   */
  onClick: PropTypes.func
};

CheckTag.defaultProps = {
  className: '',
  selected: false,
  onClick: () => {}
};
