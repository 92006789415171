/* eslint-disable @next/next/no-img-element */
import React, { useState, useEffect } from 'react';
import LockedContent from 'ui/stories/LockedContent';
import classNames from 'classnames';
import { ArrowLeftIcon } from '@heroicons/react/24/solid';
import { Button } from 'ui/stories/Button';
import { useApplicantProfile } from '../../lib/services/applicantProfileServices';
import { useNavigate } from 'react-router-dom';
import Loader from 'ui/stories/Loader';
import { API } from '../../lib/network/API';
import { toastedNote, downloadPDF } from '../../utils/Helper';

function calculateDate(dateString) {
  const [, ...second] = dateString?.split(' ');
  return second.join(' ');
}

function ApplicantProfile({ id, slug, handleRender }) {
  const navigate = useNavigate();
  const [blur, setBlur] = useState(false);
  const { profile, loading, mutate } = useApplicantProfile(true, id, slug);
  const handleUnlock = async () => {
    const { data, error } = await API.post(`/organizations/job/${slug}/unlock/${profile.id}`);
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
      handleRender();
    } else {
      toastedNote.error(error?.message[0]);
    }
  };

  const handleReject = async () => {
    const { data, error } = await API.post(
      `/job_postings/${slug}/update_application_status/${profile.id}`,
      {
        status: 'reject'
      }
    );
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error?.message[0]);
    }
  };

  const handleShortlist = async () => {
    const { data, error } = await API.post(
      `/job_postings/${slug}/update_application_status/${profile.id}`,
      {
        status: 'cv_shortlist'
      }
    );
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error?.message[0]);
    }
  };

  const handleOnHold = async () => {
    const { data, error } = await API.post(
      `/job_postings/${slug}/update_application_status/${profile.id}`,
      {
        status: 'put_on_hold'
      }
    );
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error?.message[0]);
    }
  };

  const handleDownload = (profile) => {
    const signed_url = profile?.resume?.signed_url;
    if (blur) {
      toastedNote.error('Unlock profile to download resume');
    } else if (!blur) {
      signed_url
        ? downloadPDF(signed_url, profile?.resume?.file_name)
        : toastedNote.error('Resume Not Found');
    }
  };

  useEffect(() => {
    setBlur(!profile?.show);
  }, [profile]);

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-96">
        <Loader size="large" />
      </div>
    );
  }

  let profileExperienceData = '';
  profile?.qualifications_str?.map(
    (qualification) => (profileExperienceData += qualification + ' ')
  );
  profileExperienceData +=
    profile?.experience?.years > 0 ? `${profile?.experience?.years} years of experience` : '';

  const QuestionAnswers = ({ question, answer }) => (
    <div className="flex flex-col gap-3">
      <h5 className="font-sans font-medium text-xl leading-[18px] text-gray-800">{question}</h5>
      <p className="font-regular font-normal text-sm leading-5 text-[#323736]">{answer}</p>
    </div>
  );

  return (
    <>
      <div className="flex-col px-6 pt-6 space-y-6 pb-14">
        {/* go back arrow */}
        <div className="flex items-center justify-start space-x-3">
          <ArrowLeftIcon
            className="w-5 h-5 font-sans font-medium cursor-pointer text-theme-gray-800"
            onClick={() => navigate(-1)}
          />
          <p className="font-sans text-xl font-medium text-theme-gray-800">Candidate profile</p>
        </div>

        {/* candidate data */}
        <div className="w-full p-5 space-y-3 bg-white lg:flex md:justify-between rounded-xl">
          <div className="flex space-x-6">
            {/* candidate-image */}
            <div className="min-w-[40px] w-10 h-10 md:h-20 md:w-20 rounded-3xl">
              <img
                src={profile?.profile_picture?.signed_url}
                alt={profile?.profile_picture?.signed_url ?? 'img'}
              />
            </div>

            <div className="space-y-2 font-regular">
              {/* name-qualification */}
              <h4 className="font-sans text-xl font-medium md:text-4xl text-theme-gray-800">
                {profile?.name}
              </h4>

              <div className="text-sm font-semibold text-theme-gray-700">
                {/* degree with experience  */}
                <p className="">{profileExperienceData}</p>
              </div>

              <div
                className={classNames(
                  'text-sm text-theme-gray-500',
                  blur ? 'blur-sm' : 'blur-none'
                )}>
                {profile?.location && (
                  <p>{`${profile?.location?.city}, ${profile?.location?.state}, ${profile?.location?.country}`}</p>
                )}
              </div>

              <div
                className={classNames(
                  'flex flex-col space-y-1 lg:flex lg:space-x-2 text-sm font-semibold',
                  blur ? 'blur-sm' : 'blur-none'
                )}>
                <p className="text-theme-green-500">{profile?.email}</p>
                <div></div>
                <p className="text-theme-gray-500">
                  {`${profile?.country_code} ${profile?.mobile}`}
                </p>
              </div>
            </div>
          </div>

          {blur && (
            <div className="flex items-center justify-center w-full h-8 space-x-2 bg-theme-green-100 md:hidden rounded-xl">
              <img src="/LockKeyOpen.svg" alt="lock_image" className="h-5" />
              <p
                className="text-base font-medium text-theme-green-500"
                onClick={() => handleUnlock}>
                Unlock profile
              </p>
            </div>
          )}

          {/* download resume */}
          <div
            onClick={() => handleDownload(profile)}
            data-tooltip={blur ? 'Unlock to download' : null}
            className="w-full max-w-[400px] lg:w-40 h-8 bg-theme-green-100 flex justify-center mx-auto lg:mx-0 items-center rounded-xl">
            <button className="text-base font-medium text-theme-green-500">Download resume</button>
          </div>
        </div>

        {/* qualifying-questions */}
        {profile?.assessment && (
          <div>
            <div className="h-16 bg-[#E5EAF5] px-6 py-4 rounded-t-xl hidden md:flex">
              <h4 className="text-xl font-sans font-medium text-[#475569]">Qualifying questions</h4>
            </div>
            <div className="flex flex-col gap-6 p-6 bg-white rounded-b-xl">
              {profile?.assessment?.map((item, idx) => (
                <QuestionAnswers key={idx} question={item.question} answer={item.answer} />
              ))}
            </div>
          </div>
        )}

        {/* work-experience */}
        <div>
          <div className="h-16 bg-[#E5EAF5] px-6 py-4 rounded-t-xl hidden md:flex">
            <h4 className="text-xl font-sans font-medium text-[#475569]">Experience</h4>
          </div>
          <div className="flex-col p-6 space-y-6 bg-white rounded-xl md:rounded-b-xl md:rounded-t-none">
            {profile?.applicant_experience?.map((experience, id) => {
              const year = experience?.experience?.years;
              const month = experience?.experience?.months;
              let experienceString = '';

              if (year && month) experienceString = `${year} yr ${month} mos`;
              else if (year) experienceString = `${year} yr`;
              else if (month) experienceString = `${month} mos`;

              return (
                <LockedContent
                  key={id}
                  title={experience.title}
                  hospitalImageURL="/Exp.png"
                  subtitle={[experience?.organization_name]}
                  time={{
                    start_date: calculateDate(experience?.start),
                    end_date: experience?.currently_working
                      ? 'Present'
                      : calculateDate(experience?.end),
                    experience: experienceString
                  }}
                  location={
                    experience?.location
                      ? `${experience?.location?.city}, ${experience?.location?.state}, ${experience?.location?.country}`
                      : null
                  }
                  locked={blur}
                  education={false}
                />
              );
            })}
          </div>
        </div>
        {/* education */}
        <div>
          <div className="h-16 bg-[#E5EAF5] px-6 py-4 rounded-t-xl hidden md:flex">
            <h4 className="text-xl font-sans font-medium text-[#475569]">Education</h4>
          </div>
          <div className="flex-col p-6 space-y-6 bg-white rounded-xl md:rounded-b-xl md:rounded-t-none">
            {profile?.applicant_qualification?.map((qualification, id) => (
              <LockedContent
                key={id}
                title={qualification?.college_name}
                hospitalImageURL="/Edu.png"
                subtitle={[qualification?.degree]}
                time={{
                  start_date: qualification?.start_year,
                  end_date: qualification?.end_year
                }}
                location={
                  qualification?.location
                    ? `${qualification?.location?.city}, ${qualification?.location?.state}, ${qualification?.location?.country}`
                    : null
                }
                locked={blur}
                education={true}
              />
            ))}
          </div>
        </div>

        {/* buttons */}
        {blur ? (
          <div className="flex flex-col space-y-4 md:flex-row md:justify-start md:space-x-6 md:space-y-0">
            <Button
              type="primary"
              onClick={() => handleUnlock()}
              className="flex items-center justify-center w-full h-12 text-white md:w-60">
              <img
                src="/lockOpenWhite.svg"
                alt="lock_image"
                className="inline h-5 mr-2"
                onClick={() => handleUnlock()}
              />
              Unlock profile
            </Button>
            <Button
              label={profile?.status === 'rejected' ? 'Rejected' : 'Reject'}
              disabled={profile?.status === 'hired' || profile?.status === 'rejected'}
              onClick={() => handleReject()}
              className="hidden md:flex md:justify-center md:items-center bg-[#FFE1DF] w-full text-[#F56861] md:w-20 md:h-12 hover:bg-[#fee9e9] active:bg-[#fee9e9]"
            />
          </div>
        ) : (
          <div className="flex flex-col space-y-4 md:flex-row md:justify-start md:space-x-6 md:space-y-0">
            <Button
              type="primary"
              label={
                profile?.status === 'cv_shortlisted' ? 'Shortlisted' : 'Shortlist the candidate'
              }
              onClick={() => handleShortlist()}
              disabled={
                profile?.status === 'cv_shortlisted' ||
                profile?.status === 'interviewed' ||
                profile?.status === 'hired' ||
                profile?.status === 'rejected'
              }
              className="flex items-center justify-center w-full md:w-60 md:h-12"
            />
            <Button
              label={profile?.status === 'on_hold' ? 'On Hold' : 'Put on hold'}
              onClick={() => handleOnHold()}
              disabled={
                profile?.status === 'cv_shortlisted' ||
                profile?.status === 'on_hold' ||
                profile?.status === 'hired' ||
                profile?.status === 'rejected'
              }
              className="bg-[#DCDCF1] w-full text-[#373895] md:w-32 md:h-12 hover:bg-[#e3e3f4] active:bg-[#e3e3f4] transition-colors flex justify-center items-center"
            />
            <Button
              label={profile?.status === 'rejected' ? 'Rejected' : 'Reject'}
              onClick={() => handleReject()}
              disabled={profile?.status === 'hired' || profile?.status === 'rejected'}
              className="bg-[#FFE1DF] w-full text-[#F56861] md:w-20 md:h-12 hover:bg-[#fee9e9] active:bg-[#fee9e9] flex justify-center items-center"
            />
          </div>
        )}
      </div>
    </>
  );
}

export default ApplicantProfile;
