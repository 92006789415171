import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from 'ui/stories/Button';

export default function BuyPlanBanner({ className, title, subtitle, buttonTitle, href }) {
  return (
    <div
      className={classNames(
        'w-full h-28 sm:h-12 p-3 flex flex-col space-y-3 items-center sm:flex-row sm:justify-between sm:text-left sm:space-y-0 space-x-4 bg-[#e1b20833] rounded-lg',
        className
      )}>
      <div className="flex flex-col space-y-2 sm:flex-row sm:space-x-2 sm:space-y-0 items-center">
        <h3 className="text-[#927204] text-sm sm:text-base  font-medium">{title}</h3>
        <p className="text-[#927204] text-xs font-regular">{subtitle}</p>
      </div>

      <Button
        className="!px-3.5 !py-1 !p-0.5 !text-base"
        type="primary"
        href={href}
        label={buttonTitle}
      />
    </div>
  );
}

BuyPlanBanner.propTypes = {
  // for additional classes to be added
  className: PropTypes.string,

  // for the title of the banner
  title: PropTypes.string.isRequired,

  // for the subtitle of the banner
  subtitle: PropTypes.string.isRequired,

  // for the label of the Button
  buttonTitle: PropTypes.string.isRequired,

  // for the link for the button
  href: PropTypes.string.isRequired
};

BuyPlanBanner.defaultProps = {
  className: ''
};
