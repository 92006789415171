import { CheckIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function BenefitOption({ className, children, icon }) {
  return (
    <div
      className={classNames(
        'flex justify-center space-x-3 items-center font-sm leading-5 font-regular text-sm text-theme-gray-600',
        className
      )}>
      {icon}
      <p>{children}</p>
    </div>
  );
}

BenefitOption.propTypes = {
  // addtional classnames to be added
  className: PropTypes.string,

  // the icon component to be rendered
  icon: PropTypes.element.isRequired
};

BenefitOption.defaultProps = {
  // if no classes to be added then empty string
  className: '',

  // this icon will be rendered by defaul
  icon: (
    <>
      <CheckIcon className="w-4 h-4 text-theme-green-500 rotate-12" />
    </>
  )
};
