import { Formik, Form, Field } from 'formik';
import React from 'react';
import TextArea from 'ui/stories/TextArea';
import { Button } from 'ui/stories/Button';
import Modal from 'ui/stories/Modal';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { API } from '../lib/network/API';
import { toastedNote } from '../utils/Helper';

function JobClosePopUp({ isOpen, setIsOpen, rowId, mutate }) {
  const submitForm = async (values) => {
    const customData = {
      job_posting: {
        status: 'closed',
        reason: values.picked_popup + ' ,' + values.text_area
      }
    };
    const { data, error } = await API.put(`/job_postings/${rowId}`, customData);
    if (!error) {
      console.log(data);
      toastedNote.success(data?.response.message);
      setIsOpen();
      mutate();
    } else {
      toastedNote.error(error?.message.message);
      console.log(error);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="flex justify-between h-16 py-4 px-6 border-b-2 border-[#e4e8f5]">
          <h3 className="font-sans text-2xl">Reason for close</h3>
          <XMarkIcon
            className="w-6 h-6 text-[#5c657b] cursor-pointer"
            onClick={() => setIsOpen()}
          />
        </div>
        <div className="px-6">
          <Formik onSubmit={submitForm} initialValues={{ picked_popup: '', text_area: '' }}>
            {({}) => {
              return (
                <Form className="mt-4 flex-col space-y-6">
                  <div className="">
                    <Field
                      type="radio"
                      name="picked_popup"
                      value="Hired someone"
                      title="Hired someone"
                      className="h-4 w-4 border-gray-300 text-theme-green-600 focus:ring-theme-green-500 cursor-pointer "
                    />
                    <label className="ml-3 text-sm font-regular">Hired someone</label>
                  </div>

                  <div className="">
                    <Field
                      type="radio"
                      name="picked_popup"
                      value="Adequate Applicants"
                      title="Hired someone"
                      className="h-4 w-4 border-gray-300 text-theme-green-600 focus:ring-theme-green-500 cursor-pointer "
                    />
                    <label className="ml-3 text-sm font-regular">Adequate Applicants</label>
                  </div>

                  <div className="">
                    <Field
                      type="radio"
                      name="picked_popup"
                      value="Inadequate/Poor Quality"
                      title="Hired someone"
                      className="h-4 w-4 border-gray-300 text-theme-green-600 focus:ring-theme-green-500 cursor-pointer "
                    />
                    <label className="ml-3 text-sm font-regular">Inadequate/Poor Quality</label>
                  </div>

                  <div className="">
                    <Field
                      type="radio"
                      name="picked_popup"
                      value="Position on Hold"
                      title="Hired someone"
                      className="h-4 w-4 border-gray-300 text-theme-green-600 focus:ring-theme-green-500 cursor-pointer "
                    />
                    <label className="ml-3 text-sm font-regular">Position on Hold</label>
                  </div>

                  <div className="">
                    <Field
                      type="radio"
                      name="picked_popup"
                      value="Other"
                      title="Hired someone"
                      className="h-4 w-4 border-gray-300 text-theme-green-600 focus:ring-theme-green-500 cursor-pointer "
                    />
                    <label className="ml-3 text-sm font-regular">Other</label>
                  </div>

                  <div className="flex justify-center">
                    <Field
                      placeholder="Why do you want to close this job?"
                      type="text"
                      name="text_area"
                      rows={5}
                      cols={59}
                      component={TextArea}
                    />
                  </div>
                  <div className="mt-6 sm:flex sm:justify-end">
                    <Button submit type="primary" label="Submit" className="w-full md:w-36 h-12" />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
}

export default JobClosePopUp;
