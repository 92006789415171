import { useAPI } from '../network/SWR';

export function useApplicantProfile(canCall = true, id, slug) {
  const key = `applicants/${id}?job_id=${slug}`;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    profile: data?.applicant || [],
    error,
    loading,
    mutate,
    isValidating
  };
}
