import classNames from 'classnames';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { Button } from './Button';
import UnlockCount from './UnlockButton';

export default function ActivePlan({ subscriptions, onCancel, className, ...rest }) {
  return (
    <div className={classNames('flex flex-col items-start', className)} {...rest}>
      <span className="bg-theme-green-500 text-white px-3.5 py-2.5 rounded-t-lg">Active Plan</span>
      <div className="border-theme-green-500 border-2 shadow rounded-b rounded-tr w-full p-4 bg-theme-green-100 space-y-4">
        {subscriptions?.map((plan, idx) => (
          <div
            className="bg-white border border-theme-gray-300 rounded p-5 flex flex-col md:flex-row space-y-5 space-x-0 md:space-y-0 md:space-x-5"
            key={idx}>
            <div className="flex flex-col items-start md:flex-1">
              <span className="text-3xl text-theme-purple-500">{plan.pricing.name}</span>
              <span className="text-base text-theme-green-500 mt-1">
                {plan?.vacancy_period} average time to close a vacancy
              </span>
              <span className="text-xs font-regular mt-3 text-theme-gray-500  leading-5">
                {plan?.pricing?.description}
              </span>
              {plan?.unlocks_left > 0 ? (
                <UnlockCount className="mt-3 inline-block">{plan?.unlocks_left}</UnlockCount>
              ) : (
                <div className="mt-5 text-base text-theme-green-500 bg-theme-green-100 h-8 rounded-full items-center justify-center px-5 py-1">
                  No unlocks left, Contact admin
                </div>
              )}
            </div>
            <ul className="list-disc list-inside border-b border-theme-gray-200  pt-2 pb-2.5 md:border-0 md:mb-0 space-y-1.5  md:flex-1">
              {plan?.pricing?.plan_benefits?.map((f, idx) => (
                <li className="text-xs font-regular text-theme-gray-500 relative" key={idx}>
                  {f}
                </li>
              ))}
            </ul>
            <div className="flex flex-col font-regular text-xs space-y-2 md:flex-1 text-theme-gray-800 md:!mt-5">
              <div className="flex flex-row justify-between md:pl-5">
                <span className="">Total price</span>
                <span className="font-semibold">₹{plan?.pricing?.amount}</span>
              </div>
              <div className="flex flex-row justify-between md:pl-5">
                <span>Purchase date</span>
                <span className="font-semibold">
                  {DateTime.fromISO(plan?.purchase_date).toFormat('MMMM dd, yyyy')}
                </span>
              </div>
              <div className="flex flex-row justify-between md:pl-5">
                <span>Renewal date</span>
                <span className="font-semibold">
                  {DateTime.fromISO(plan?.renewal_date).toFormat('MMMM dd, yyyy')}
                </span>
              </div>
              <div className="text-center md:text-right mt-5">
                <Button
                  size="small"
                  type="plain"
                  warning
                  className="!p-0 mt-5 md:!mt-8"
                  onClick={() => onCancel(plan)}>
                  Cancel Plan
                </Button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

ActivePlan.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * ActivePlan
   */
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      pricing: PropTypes.shape({
        name: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        amount: PropTypes.number.isRequired,
        plan_benifits: PropTypes.arrayOf(PropTypes.string).isRequired
      }),
      purchase_date: PropTypes.number.isRequired,
      renewal_date: PropTypes.number.isRequired,
      unlocks_left: PropTypes.number.isRequired,
      vacancy_period: PropTypes.string.isRequired
    })
  ).isRequired,
  /**
   * on cancel func
   */
  onCancel: PropTypes.func.isRequired
};

ActivePlan.defaultProps = {
  className: ''
};
