import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Modal({
  children,
  isOpen = false,
  setIsOpen,
  alignMid,
  classname,
  dialogClassName,
  isOnClose = true,
  clearState = () => {}
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[99]"
        onClose={() => {
          if (isOnClose) {
            setIsOpen(!isOpen);
          }
          clearState();
        }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div
          className={classNames(
            alignMid ? 'inset-x-0 inset-y-56' : 'inset-0',
            'fixed z-10 overflow-y-auto'
          )}>
          <div
            className={classNames(
              'flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0',
              classname
            )}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={classNames(
                  'relative transform rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-auto sm:p-6',
                  dialogClassName
                )}>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  /**
   * to open the modal
   */
  val: PropTypes.bool
};

Modal.defaultProps = {
  val: false
};
