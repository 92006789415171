import { useAPI } from '../network/SWR';

export function useCurrentPlan(canCall = true) {
  const key = '/organizations/subscriptions';
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    currentPlan: data?.subscriptions || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useAllPlans(canCall = true) {
  const key = '/pricings';
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    allPlans: data?.pricings || [],
    error,
    loading,
    mutate,
    isValidating
  };
}
