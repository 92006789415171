import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import JobPosting from './pages/jobPosting';
import Home from './pages/Home/Home';
import Layout from './components/Layout';
import Jobs from './pages/Jobs/Jobs';
import { JobContext } from './lib/context/JobContext';
import { JobSuccess } from './pages/jobPosting/JobSuccess';
import JobsSlug from './pages/JobsSlug/JobsSlug';
import { BreadcrumbsContext } from './lib/context/BreadcrumbsContext';
import CandidateProfile from './pages/JobsSlug/CandidateProfile';

import { CompanyProfile } from './pages/CompanyProfile';
import { EditProfile } from './pages/EditProfile';
import BillingPage from './pages/Billing/BillingPage';
import { UserContext } from './lib/context/userContext';
import BillingHistory from './pages/Billing/BillingHistory';
import { useState } from 'react';
import { CurrentUser } from './lib/network/Auth';

function App() {
  const [rerender, setRerender] = useState(false);

  function handleRender() {
    setRerender(!rerender);
  }

  if (!CurrentUser.isLoggedIn()) {
    CurrentUser.logout();
    window.location.href = `${process.env.REACT_APP_EMPLOYER_PORTAL}/employer/login?logout=true`;
  }
  const RouteContainer = () => {
    return (
      <BreadcrumbsContext>
        <JobContext>
          <UserContext>
            <Layout>
              <Routes>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="/dashboard" element={<Home />} />
                <Route path="/jobs" element={<Jobs />} />
                <Route path="/job-post" element={<JobPosting />} />
                <Route path="/job-success" element={<JobSuccess />} />
                <Route path="/jobs/:slug" element={<JobsSlug />} />
                <Route
                  path="/jobs/:slug/:id"
                  element={<CandidateProfile handleRender={handleRender} />}
                />
                <Route path="/profile" element={<CandidateProfile handleRender={handleRender} />} />
                <Route path="/company-profile" element={<CompanyProfile />} />
                <Route path="/edit-profile" element={<EditProfile />} />
                <Route path="/billing">
                  <Route path="plans" element={<BillingPage />} />
                  <Route path="history" element={<BillingHistory />} />
                </Route>
              </Routes>
            </Layout>
          </UserContext>
        </JobContext>
      </BreadcrumbsContext>
    );
  };
  return (
    <>
      <BrowserRouter>
        <RouteContainer />
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
