import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import queryString from 'query-string';
import { API } from '../lib/network/API';
import axios from 'axios';
import { parseNumber } from 'libphonenumber-js';
/* Toasted notes */
export const toastedNote = {
  error: (title, options) => toast.error(title, options),
  info: (title, options) => toast.info(title, options),
  success: (title, options) => toast.success(title, options),
  warn: (title, options) => toast.warn(title, options)
};

export const queryStringParseFuntion = (queryObject) => {
  return queryString.stringifyUrl(
    {
      url: '',
      query: queryObject
    },
    { skipNull: true, skipEmptyString: true }
  );
};

export const navigateSelf = (pageNo, query, parsed, navigate) => {
  navigate(
    queryString.stringifyUrl(
      {
        url: '',
        query: {
          ...parsed,
          pageNo,
          query
        }
      },
      { skipNull: true, skipEmptyString: true }
    )
  );
};

export const handleImageChange = async (file, id) => {
  // console.log(file, 'from helper');
  if (file.name) {
    const fileName = Date.now();
    const extension = _.last(file.name.split('.'));
    const { data, error } = await API.get(
      `https://api.nurseindia.org/v1/signed_url?path=organizations/${id}/${fileName}.${extension}`
    );
    if (error) {
      toastedNote.error("Can't able to upload image");
    }
    // console.log(data.response.url);
    try {
      const uploader = axios.create({});
      const file_type = file.type.includes('image')
        ? 'image'
        : file.type.includes('application')
        ? 'document'
        : 'other';
      uploader.put(data.response.url, file, {
        headers: {
          'Content-Type': 'image/*'
        }
      });

      return { signed_url: data.response.url, file_type: file_type, success: true };
    } catch (e) {
      console.log('error uploading file', e, e.response);
      return { success: false };
    }
  }
};

// const fileUploadManager = async ({ file, key = '', token }) => {
//   try {
//     const fileName = file.name
//       .substring(0, file.name.lastIndexOf('.'))
//       .replace(' ', '_')
//       .replace(/[^a-zA-Z0-9.?,]/g, '');
//     const extension = _.last(file.name.split('.'));
//     const file_type = file.type.includes('image')
//       ? 'image'
//       : file.type.includes('application')
//       ? 'document'
//       : 'other';
//     const result = await API(
//       {
//         method: 'GET',
//         url: config.urls.file.upload,
//         data: {},
//         params: {
//           key: `${key}/${fileName}.${extension}`
//         }
//       },
//       true,
//       token
//     );

//     await axios.put(result.url, file, {
//       headers: {
//         'Content-Type': 'image/*'
//       }
//     });
//     return { signed_url: result.url, file_type: file_type, success: true };
//   } catch (e) {
//     console.log('error uploading file', e, e.response);
//     return { success: false };
//   }
// };

// export default fileUploadManager;

export const parseNumberFormat = (phone) => {
  if (_.isEmpty(phone)) return false;
  return parseNumber(phone, { defaultCountry: 'IN', extended: true });
};

export const downloadPDF = async (link, fileName) => {
  const res = await fetch(link, {});
  const pdff = await res.blob();
  saveAs(pdff, `${fileName}`);
};
