import { HomeIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export default function Breadcrumbs({ pages, showHome, className }) {
  return (
    <nav className={classNames('flex', className)} aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-1">
        {showHome && (
          <li>
            <div>
              <a href="#" className="text-gray-400 hover:text-gray-500">
                <HomeIcon
                  className="h-5 w-5 flex-shrink-0 text-theme-green-500"
                  aria-hidden="true"
                />
                <span className="sr-only">Home</span>
              </a>
            </div>
          </li>
        )}
        {pages.map((page, idx) => {
          return (
            <div key={idx} className="flex items-center">
              {pages.length >= 3 && idx === pages.length - 2 && (
                <li key={page.name} href={page.href} className="inline-block sm:hidden">
                  <div className="flex items-center">
                    <svg
                      className="h-5 w-5 flex-shrink-0 text-stone-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true">
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <Link
                      className="text-theme-green-500 hover:text-theme-green-600"
                      to={page.href}>
                      ...
                    </Link>
                  </div>
                </li>
              )}
              <li
                key={page.name}
                className={classNames(
                  idx === 0 || idx === pages.length - 1 ? 'inline-block' : 'hidden',
                  'sm:inline-block'
                )}>
                <div className="flex items-center">
                  {!(idx === 0 && !showHome) && (
                    <svg
                      className="h-5 w-5 flex-shrink-0 text-stone-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true">
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                  )}
                  <Link
                    to={page.href}
                    className={classNames(
                      'ml-2 sm:text-sm text-xs font-medium font-regular capitalize',
                      page.current
                        ? 'text-stone-500 pointer-events-none'
                        : 'text-theme-green-500 hover:text-theme-green-600'
                    )}
                    aria-current={page.current ? 'page' : undefined}>
                    {page.name}
                  </Link>
                </div>
              </li>
            </div>
          );
        })}
      </ol>
    </nav>
  );
}

Breadcrumbs.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,

  /**
   * additional class anmes
   */
  showHome: PropTypes.bool,

  /**
   * breadcrumbs
   */
  pages: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      current: PropTypes.bool.isRequired
    })
  ).isRequired
};

Breadcrumbs.defaultProps = {
  className: '',
  showHome: false
};
