/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import ActivePlan from 'ui/stories/ActivePlan';
import { Button } from 'ui/stories/Button';
import Modal from 'ui/stories/Modal';
import PricingCard from 'ui/stories/PricingCard';
import Checkout from '../../components/Checkout/Checkout';
import { UserData } from '../../lib/context/userContext';
import { API } from '../../lib/network/API';
import { useAllPlans, useCurrentPlan } from '../../lib/services/billingServices';
import { toastedNote } from '../../utils/Helper';
import useDocumentTitle from '../../utils/useDocumentTitle';
import Loader from 'ui/stories/Loader';

export default function BillingPage() {
  const [modal, showModal] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [cancelLoading, setLoading] = useState(false);
  const [subId, setSubId] = useState(null);
  const [planId, setPlanId] = useState(0);
  const [planType, setPlanType] = useState('fixed');
  const { userData } = useContext(UserData);
  const { currentPlan, mutate: subscriptionMutate } = useCurrentPlan();
  const { allPlans, mutate: planMutate } = useAllPlans();

  useDocumentTitle(`Biilling: Plans`);

  const callMutate = () => {
    showModal(false);
    setLoading(false);
    planMutate();
    subscriptionMutate();
  };

  const cancelSubscription = async () => {
    setLoading(true);
    const { data, error } = await API.post('/payments/subscription', {
      operation: 'delete',
      subscription: {
        ...(!!subId && { entity_id: subId }),
        entity_type: 'Subscription'
      }
    });

    if (!error) {
      toastedNote.success(data.response.message);
      callMutate();
    } else {
      toastedNote.error(error.message[0]);
      callMutate();
    }
  };

  const btnArr = [
    {
      size: 'small',
      type: 'plain',
      children: 'No, dont cancel the plan',
      disabled: cancelLoading,
      onClick: () => showModal(false)
    },
    {
      size: 'small',
      type: cancelLoading ? 'primary' : 'secondary',
      children: (
        <p className="flex items-center gap-2">
          Yes, cancel the plan {cancelLoading && <Loader size="small" />}
        </p>
      ),
      warning: true,
      disabled: cancelLoading,
      onClick: () => {
        cancelSubscription();
      }
    }
  ];

  const ModalProps = {
    children: (
      <div className="relative flex flex-col items-center justify-center max-w-xl p-5 text-center duration-300 ease-in backdrop-blur-md gap-y-4 rounded-xl">
        <img src="/cancel_plan_popup.svg" alt="vector" />
        <h3 className="text-xl text-theme-gray-800">Are you sure you wanna cancel the plan?</h3>
        <div className="flex mt-4 space-x-2">
          {btnArr.map((item, idx) => (
            <Button {...item} key={idx} />
          ))}
        </div>
      </div>
    ),
    isOpen: modal,
    setIsOpen: showModal
  };

  return (
    <>
      <div className="bg-[#f5f7fb] max-w-full 2xl:max-w-7xl">
        <div className="p-5">
          <Checkout
            isOpen={checkout}
            setIsOpen={setCheckout}
            planId={planId}
            planType={planType}
            callMutate={() => callMutate()}
          />
          <Modal {...ModalProps} />
          <h3 className="pt-4 pb-2 text-3xl text-theme-gray-500">
            Billing <span className="text-black">/ Plan</span>
          </h3>
          {!_.isEmpty(currentPlan) ? (
            <ActivePlan
              subscriptions={currentPlan}
              onCancel={(plan) => {
                setSubId(plan.id);
                showModal(true);
              }}
            />
          ) : (
            <p className="text-sm text-theme-gray-600 font-regular mb-1.5">
              {`You have ${userData.total_unlocks_left} unlocks left in your account.`}
            </p>
          )}

          <div>
            {userData.subscription_present && (
              <h3 className="py-6 pt-4 text-3xl text-theme-gray-800">Other plans</h3>
            )}
            <div className="flex space-x-5 overflow-x-scroll scrollbar-hide">
              {allPlans.map((price, key) => (
                <PricingCard
                  key={key}
                  amount={price.amount}
                  description={price.description}
                  name={price.name}
                  planBenifits={price.plan_benefits}
                  price={price}
                  onClick={() => {
                    setPlanId(price.id);
                    setCheckout(true);
                    setPlanType(price.plan_type);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
