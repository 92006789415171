import * as Yup from 'yup';

const nameRegex = new RegExp(/[A-Za-z]/);
const passwordRegex = new RegExp(
  /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/
);
const websiteRegex = new RegExp(
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
);

export const jobPosting1Validation = Yup.object().shape({
  hospital_name: Yup.string()
    .min(3, 'Hospital name must be 3 characters or more.')
    .max(264, 'Hospital name must be 264 characters or less.')
    .matches(nameRegex, 'Hospital name must only contain alphabets.')
    .required('Enter hospital name.'),
  job_title: Yup.string()
    .min(3, 'Job title must be 3 characters or more.')
    .max(500, 'Job title must be 500 characters or less.')
    .matches(nameRegex, 'Job title must only contain alphabets.')
    .required('Enter job title.'),
  category: Yup.string().required('Select a category.'),
  location: Yup.string().required('Select a location.'),
  job_description: Yup.string()
    .required('Enter job description.')
    .min(100, 'Job description must be 100 characters or more.')
    .max(5000, 'Job description must be 5000 characters or less.'),
  specialization: Yup.string().required('Select a specialization.'),
  salary: Yup.object().shape({
    amount: Yup.number()
      .required('Enter salary.')
      .moreThan(0, 'Salary amount cannot be 0')
      .max(99999999, 'Salary amount must be 8 characters or less.'),
    currency: Yup.string().required('Select a currency.')
  }),
  vacancies: Yup.number()
    .required('Enter vacancy count.')
    .moreThan(0, 'Vacancy count cannot be 0.')
    .max(9999, 'Vacancy count must be 4 characters or less.')
});

export const jobPosting2Validation = Yup.object().shape({
  qualification: Yup.string().required('Select a qualification.'),
  minYears: Yup.number().required('Select Min years.'),
  maxYears: Yup.number()
    .required('Select Max years.')
    .test('minCheck', 'Max years cannot be lesser than Min years.', function (f) {
      const ref = Yup.ref('minYears');
      return f >= this.resolve(ref);
    }),
  other_benefits: Yup.array().of(
    Yup.string()
      .matches(/^(?!\s+$)/, 'This field cannot contain only blankspaces')
      .min(2, 'Other benifits must be 2 characters or more.')
      .max(16, 'Other benifits must be 16 characters or less.')
  ),
  email: Yup.string().email('Enter a valid email address.').required('Enter your email.'),
  termsAndConditions: Yup.boolean().oneOf([true], 'Please accept the terms and conditions.')
});

export const jobDetailEditValidation = Yup.object().shape({
  hospital_name: Yup.string()
    .min(3, 'Hospital name must be 3 characters or more.')
    .max(264, 'Hospital name must be 264 characters or less.')
    .matches(nameRegex, 'Hospital name must only contain alphabets.')
    .required('Enter hospital name.'),
  job_title: Yup.string()
    .min(3, 'Job title must be 3 characters or more.')
    .max(500, 'Job title must be 500 characters or less.')
    .matches(nameRegex, 'Job title must only contain alphabets.')
    .required('Enter job title.'),
  category: Yup.string().required('Select a category.'),
  location: Yup.string().required('Select a location.'),
  job_description: Yup.string()
    .required('Enter job description.')
    .min(100, 'Job description must be 100 characters or more.')
    .max(5000, 'Job description must be 5000 characters or less.'),
  specialization: Yup.string().required('Select a specialization.'),
  salary: Yup.object().shape({
    amount: Yup.number()
      .required('Enter salary.')
      .moreThan(0, 'Salary amount cannot be 0')
      .max(99999999, 'Salary amount must be 8 characters or less.'),
    currency: Yup.string().required('Select a currency.')
  }),
  vacancies: Yup.number()
    .required('Enter a vacany count.')
    .moreThan(0, 'Vacany count cannot be 0.')
    .max(9999, 'Vacancy count must be 4 characters or less.'),
  qualification: Yup.string().required('Select a qualification.'),
  minYears: Yup.number().required('Select Min years.'),
  maxYears: Yup.number()
    .required('Select Max years.')
    .test('minCheck', 'Max years cannot be lesser than Min years.', function (f) {
      const ref = Yup.ref('minYears');
      return f >= this.resolve(ref);
    }),
  other_benefits: Yup.array().of(
    Yup.string()
      .matches(/^(?!\s+$)/, 'This field cannot contain only blankspaces')
      .min(2, 'Other benifits must be 2 characters or more.')
      .max(16, 'Other benifits must be 16 characters or less.')
  ),
  email: Yup.string().email('Enter a valid email address.').required('Enter your email.'),
  termsAndConditions: Yup.boolean().oneOf([true], 'Please accept the terms and conditions.')
});

export const companyProfileValidationForHospitals = Yup.object().shape({
  organizationType: Yup.number().required('An organisation type is required'),
  organizationName: Yup.string()
    .min(3, 'Organization name must be 3 characters or more.')
    .max(240, 'Organization name must be 240 characters or less.')
    .matches(nameRegex, 'Organization name must only contain alphabets.')
    .required('Enter an orgnization name.'),
  about: Yup.string()
    .min(3, 'About organization must be 3 characters or more.')
    .max(1000, 'About organization must be 1000 characters or less.')
    .required('Enter about organization.'),
  website: Yup.string().matches(websiteRegex, 'Enter a valid website.'),
  email: Yup.string().email('Enter a valid email address.').required('Enter your email.'),
  employeeCount: Yup.number()
    .typeError('Enter a valid number.')
    .min(1, 'Number of emloyees cannot be 0.'),
  bedCount: Yup.number().typeError('Enter a valid number.').min(1, 'Number of beds cannot be 0.'),
  speciality: Yup.number().required('Select a speciality.')
});

export const companyProfileValidationForOthers = Yup.object().shape({
  organizationType: Yup.number().required('An organisation type is required'),
  organizationName: Yup.string()
    .min(3, 'Organization name must be 3 characters or more.')
    .max(240, 'Organization name must be 240 characters or less.')
    .matches(nameRegex, 'Organization name must only contain alphabets.')
    .required('Enter an orgnization name.'),
  about: Yup.string()
    .min(3, 'About organization must be 3 characters or more.')
    .max(1000, 'About organization must be 1000 characters or less.')
    .required('Enter about organization.'),
  website: Yup.string().matches(websiteRegex, 'Enter a valid website.'),
  email: Yup.string().email('Enter a valid email address.').required('Enter your email.'),
  employeeCount: Yup.number()
    .typeError('Enter a valid number.')
    .min(1, 'Number of emloyees cannot be 0.')
});

export const editProfileValidation = Yup.object().shape({
  name: Yup.string()
    .min(3, 'must be 3 characters or more.')
    .max(240, 'Must be atleast 240 letters')
    .matches(nameRegex, 'Must be alphabetical letters only')
    .required('Organization Name is required'),
  email: Yup.string().email('Enter a valid email address.').required('Enter your email.'),
  oldPassword: Yup.string()
    .min(8, 'Password must be 8 characters or more.')
    .max(15, 'Password must be 15 characters or less.')
    .matches(passwordRegex, 'Password must contain an uppecase, a number and a special character'),
  newPassword: Yup.string()
    .min(8, 'Password must be 8 characters or more.')
    .max(15, 'Password must be 15 characters or less.')
    .matches(passwordRegex, 'Password must contain an uppecase, a number and a special character'),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Confirm password does not match.'
  )
});

export const checkoutValidations = Yup.object().shape({
  companyName: Yup.string().required('Company name is required.'),
  gst: Yup.string().length(15, 'enter a valid GST number.'),
  address: Yup.string()
    .min(3, 'Address must be 3 characters or more.')
    .max(265, 'Address must be 265 characters or less.')
    .required('Enter your address.'),
  country: Yup.string().required('Select a country.'),
  state: Yup.string().required('Select a state.'),
  city: Yup.string().required('Select a city.'),
  pincode: Yup.string()
    .length(6, 'Pincode must be only 6 characters.')
    .required('Enter your pincode.')
    .matches(/^[1-9][0-9]{5}$/, 'Enter a valid pincode')
});

export const customPlanValidation = Yup.object().shape({
  name: Yup.string().required('Company name is required.'),
  email: Yup.string().email('Enter a valid email address.').required('Enter your email.'),
  website: Yup.string().matches(websiteRegex, 'Enter a valid website.'),
  message: Yup.string().required('message required.')
});
