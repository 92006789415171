import React, { createContext, useState } from 'react';

export const BreadcrumbsCon = createContext();
export const BreadcrumbsContext = ({ children }) => {
  const [showState, setShowState] = useState(false);
  const [pathArr, setPathArr] = useState([]);
  const [jobSlug, setJobSlug] = useState({});
  return (
    <BreadcrumbsCon.Provider
      value={{ showState, setShowState, pathArr, setPathArr, jobSlug, setJobSlug }}>
      {children}
    </BreadcrumbsCon.Provider>
  );
};
