import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import CheckTag from 'ui/stories/CheckTag';
import FormLabel from 'ui/stories/FormLabel';
import TextInput from 'ui/stories/TextInput';
import TextArea from 'ui/stories/TextArea';
import {
  useOrganizationType,
  useSpeciality,
  useOrganization
} from '../lib/services/companyProfileServices';
import Dropdown from 'ui/stories/Dropdown';
import HospitalLogo from 'ui/stories/HospitalLogo';
import { Button } from 'ui/stories/Button';
import {
  companyProfileValidationForHospitals,
  companyProfileValidationForOthers
} from '../lib/formikValidations/validation';
import { API } from '../lib/network/API';
import { handleImageChange, toastedNote } from '../utils/Helper';
import { CurrentUser } from '../lib/network/Auth';
import _ from 'lodash';
import Loader from 'ui/stories/Loader';
import useDocumentTitle from '../utils/useDocumentTitle';

export const CompanyProfile = () => {
  const { specialities } = useSpeciality();
  const user = CurrentUser.getDetails();
  const { organization, loading, mutate } = useOrganization(true, user.id);
  const { organizationType } = useOrganizationType();
  const [organizationToggle, setOrganizationToggle] = useState('');
  const [isHospital, setIsHospital] = useState(true);

  useDocumentTitle(`Company Profile`);

  useEffect(() => {
    setOrganizationToggle(organization.organization_type);
    return () => {};
  }, [loading, organization.organization_type]);
  const submitForm = async (values) => {
    const org_id = CurrentUser.getDetails().id;
    const imageData = !_.isEmpty(values.hospital_image.name)
      ? await handleImageChange(values.hospital_image, org_id)
      : {};

    const dataForHospitals = {
      organization: {
        org_name: values.organizationName,
        org_type: values.organizationType,
        website: _.isEmpty(values.website) ? null : values.website,
        organization_profile_attributes: {
          ...(organization?.organization_profile?.id && {
            id: organization.organization_profile.id
          }),
          description: values.about,
          email: values.email,
          employees_count: values.employeeCount,
          beds_count: values.bedCount,
          speciality: values.speciality,
          ...(values.hospital_image.name && {
            logo_attributes: {
              url: imageData?.signed_url,
              file_type: 'image'
            }
          })
        }
      }
    };

    const dataForOthers = {
      organization: {
        org_name: values.organizationName,
        org_type: values.organizationType,
        website: _.isEmpty(values.website) ? null : values.website,
        organization_profile_attributes: {
          ...(organization?.organization_profile?.id && {
            id: organization.organization_profile.id
          }),
          description: values.about,
          email: values.email,
          employees_count: values.employeeCount,
          ...(values.hospital_image.name && {
            logo_attributes: {
              url: imageData?.signed_url,
              file_type: 'image'
            }
          })
        }
      }
    };
    const { data, error } = await API.put(
      `/organizations/${user.id}`,
      isHospital ? dataForHospitals : dataForOthers
    );
    if (!error) {
      toastedNote.success(data.response.message);
      mutate();
    }
    toastedNote.error(error?.message.message);
    console.log(error);
  };

  const initialValues = {
    hospital_image: {},
    hospital_url: organization?.organization_profile?.logo?.signed_url || '',
    organizationType: organization?.org_type || '',
    organizationName: organization?.organization_name || '',
    about: organization?.organization_profile?.about_organization || '',
    website: organization?.website || '',
    email: organization.organization_profile?.official_email || '',
    employeeCount: organization?.organization_profile?.employees_count || '',
    bedCount: organization?.organization_profile?.beds_count || '',
    speciality: organization.organization_profile?.speciality || ''
  };

  if (loading && organization.organization_type) {
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <Loader size="large" />
      </div>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={submitForm}
      validationSchema={
        isHospital ? companyProfileValidationForHospitals : companyProfileValidationForOthers
      }>
      {({ setFieldValue, errors, touched, values }) => {
        document
          .getElementsByClassName(Object.keys(errors)[0] + '-ErrorMessage')[0]
          ?.scrollIntoView({
            behavior: 'smooth',
            block: 'end'
          });
        return (
          <Form className="flex flex-col gap-6 pt-10 pb-14 px-6">
            <div className="text-3xl text-theme-gray-800">Company Profile</div>
            <div className="flex gap-6 items-center">
              <Field
                component={HospitalLogo}
                edit={true}
                name="hospital_image"
                imageURL={organization?.organization_profile?.logo?.signed_url}
              />
              <div className="flex flex-col gap-3">
                <div>
                  {
                    _.first(organizationType?.filter((x) => x.id === values.organizationType))
                      ?.value
                  }{' '}
                  logo
                </div>
                <div className="font-regular text-xs text-[#848988]">Dimensions: 120 x 80 px</div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <FormLabel>We are</FormLabel>
              {organizationToggle && (
                <div className="flex gap-3 flex-wrap">
                  {organizationType?.map((type, index) => {
                    return (
                      <CheckTag
                        key={index}
                        type="button"
                        name="organizationType"
                        onClick={(e) => {
                          setFieldValue('organizationType', type.id);
                          setOrganizationToggle(e.target.innerText);
                        }}
                        selected={organizationToggle === type.value}
                        className="whitespace-nowrap">
                        {type.value}
                      </CheckTag>
                    );
                  })}
                </div>
              )}
              {values.organizationType !== 1 ? setIsHospital(false) : setIsHospital(true)}
              {touched.organizationType && errors.organizationType ? (
                <p className="mt-1 text-[11px] text-[#db4d4d] font-regular">
                  {errors.organizationType}
                </p>
              ) : null}
            </div>
            <Field
              component={TextInput}
              placeholder="Enter Hospital Name"
              name="organizationName"
              showValidation={true}
              label="Company Name"
              onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
              required
            />
            <Field
              component={TextArea}
              placeholder="Enter about your organization"
              name="about"
              showValidation={true}
              label="About organization"
              required
            />
            <div className="flex gap-4 sm:gap-6">
              <div className="flex-1">
                <Field
                  label="Website"
                  component={TextInput}
                  type="url"
                  name="website"
                  placeholder="https://"
                  onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                />
              </div>
              <div className="flex-1">
                <Field
                  label={`Official email id of ${isHospital ? 'Hospital' : 'Company'}`}
                  component={TextInput}
                  onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                  type="email"
                  name="email"
                  readOnly
                  placeholder="Enter official mail id"
                  required
                />
              </div>
            </div>
            <div className="flex gap-6">
              <div className="flex-1">
                <Field
                  label="No. of employees"
                  component={TextInput}
                  type="text"
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  name="employeeCount"
                  placeholder="Enter no. of employees"
                />
              </div>
              {isHospital && (
                <div className="flex-1">
                  <Field
                    label="No. of beds"
                    component={TextInput}
                    type="text"
                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                    name="bedCount"
                    placeholder="Enter no. of beds"
                  />
                </div>
              )}
            </div>
            {isHospital && (
              <Field
                label="Hospital Speciality"
                component={Dropdown}
                valueKey="id"
                labelKey="value"
                options={specialities}
                name="speciality"
                placeholder="Select a Qualification"
                className=""
                required
              />
            )}
            <Button submit={true} type="primary" label="Save" className="w-40" />
          </Form>
        );
      }}
    </Formik>
  );
};
