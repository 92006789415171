/* eslint-disable no-unused-vars */
import React from 'react';
import Footer from 'ui/stories/Footer';
import Sidebar from 'ui/stories/Sidebar';
import { BellIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { FacebookIcon, LinkedinIcon, InstagramIcon, MailIcon } from 'ui/stories/assets/Logos';
import UnlockCount from 'ui/stories/UnlockButton';
import { Button } from 'ui/stories/Button';
import { CurrentUser } from '../lib/network/Auth';
import { Link, useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { UserData } from '../lib/context/userContext';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import classNames from 'classnames';
import { useNotification } from '../lib/services/notificationServices';
import { useState } from 'react';
import { v4 } from 'uuid';

const footerProps = {
  socialLinks: [
    { icon: <FacebookIcon />, link: 'https://www.facebook.com/NurseIndiaOfficial' },
    { icon: <LinkedinIcon />, link: 'https://www.linkedin.com/company/nurseindia-org/' },
    { icon: <InstagramIcon />, link: 'https://www.instagram.com/nurseindia.org_/' },
    { icon: <MailIcon />, link: 'mailto:connect@nurseindia.org' }
  ],
  sections: [
    [
      { name: 'About us', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/about` },
      { name: 'Our Clients', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/corporateclients` },
      { name: 'Privacy Policy', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/privacypolicy` },
      {
        name: 'Terms and Conditions',
        link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/termsconditions`
      },
      { name: 'Disclaimer', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/disclaimer` }
    ],
    [
      { name: 'Contact Us', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/contact` },
      { name: 'Blog', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/blog` },
      { name: 'FAQ', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/faq` }
    ]
  ]
};

const HeaderNavigation = [
  { label: 'Nurses', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}` },
  { label: 'Employer', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/employer` },
  { label: 'Browse jobs', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/jobs` },
  { label: 'About', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/about` },
  { label: 'Blogs', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/blog` },
  { label: 'Contact us', link: `${process.env.REACT_APP_EMPLOYER_PORTAL}/contact` }
];

export default function Layout({ children }) {
  const { notification } = useNotification();
  const userDetails = CurrentUser?.getDetails();
  const location = useLocation();
  const { userData } = useContext(UserData);
  const data = {
    right: (
      <div className="flex flex-1 items-center space-x-2 lg:space-x-6">
        {userData?.total_unlocks_left && (
          <UnlockCount
            subScriptionPresent={userData.subscription_present}
            count={userData?.total_unlocks_left}
            className={'lg:flex hidden '}>
            {userData.total_unlocks_left}
          </UnlockCount>
        )}
        <Button type="primary" size="small" className="!py-1 !px-4" href="/job-post">
          Post a job
        </Button>
        <button
          type="button"
          className="rounded-full bg-white p-1 h text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-theme-green-500 focus:ring-offset-2 hidden xxs:block">
          <span className="sr-only">View notifications</span>
          <DropMenu name={userData?.name} className="-right-12" notification={notification} />
        </button>
      </div>
    ),
    profile: {
      component: (
        <div className="flex flex-row items-center space-x-1">
          <span>Hi</span>
          <span className="text-theme-green-500 font-bold">{userDetails?.name}</span>
          <ChevronDownIcon className="w-4 h-4 text-theme-green-500" />
        </div>
      ),
      menu: [
        { name: 'Dashboard', href: '/dashboard' },
        {
          name: 'Edit Profile',
          href: '/edit-profile'
        },
        { name: 'Sign out', href: '#' }
      ]
    }
  };
  const navigation = [
    { name: 'Dashboard', href: '/dashboard', icon: 'dashboard', current: false },
    { name: 'Jobs', href: '/jobs', icon: 'jobs', current: false },
    {
      name: 'Billing',
      href: '/billing',
      icon: 'billing',
      subMenu: [
        { name: 'Plans', href: '/billing/plans', current: location.pathname.includes('/plans') },
        {
          name: 'Billing history',
          href: '/billing/history',
          current: location.pathname.includes('/history')
        }
      ],
      current: false
    },
    { name: 'Company profile', href: '/company-profile', icon: 'company', current: false }
  ];

  const locationWithoutLayuot = ['/job-post', '/job-success'];

  return (
    <>
      {locationWithoutLayuot.includes(location.pathname) ? (
        <div>{children}</div>
      ) : (
        <>
          <Sidebar
            navigation={navigation.map((el) =>
              location.pathname.includes(el.href) ? { ...el, current: true } : el
            )}
            headerNavigation={HeaderNavigation}
            {...data}>
            {children}
            {/*Footer component */}
          </Sidebar>
          <div className="relative z-10 ">
            <Footer {...footerProps} />
          </div>
        </>
      )}
    </>
  );
}

const DropMenu = ({ name, className, notification }) => {
  const [showMore, setShowMore] = useState(4);
  return (
    <div className={classNames('top-6', className)}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex py-2 text-sm font-medium cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <BellIcon className="w-6 h-6 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 text-black" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 mt-4 shadow-lg w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className=" py-1">
              <p className="px-4 py-2 border-b text-base font-medium text-black">Notifications</p>
              {(notification.length > 4 ? notification.slice(0, showMore) : notification).map(
                (e, idx) => (
                  <Menu.Item>
                    {({ active }) => (
                      <>
                        <div
                          key={idx}
                          className={classNames(
                            idx === notification.slice(0, showMore).length - 1
                              ? 'pb-1'
                              : ' border-b',
                            'group font-regular cursor-pointer hover:bg-[#E1F2F3]  p-2'
                          )}>
                          <a className="space-y-1" href={e.content.job_link}>
                            <p className="group-hover:font-semibold text-xs text-[#404759]">
                              {e.content.message}
                            </p>
                            <p className="text-[10px] text-[#64748B] pb-1">{e.created_at}</p>
                          </a>
                        </div>
                        {idx === notification.slice(0, showMore).length - 1 && (
                          <p
                            onClick={() => {
                              setShowMore(showMore === 4 ? 8 : 4);
                            }}
                            className="text-sm text-theme-green-500 text-center border-t font-medium pt-1 cursor-pointer">
                            {showMore === 4 ? 'See all' : 'See fewer'}
                          </p>
                        )}
                      </>
                    )}
                  </Menu.Item>
                )
              )}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};
