/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { BreadcrumbsCon } from '../../lib/context/BreadcrumbsContext';
import { useJobDetails } from '../../lib/services/jobPostDetailsServices';
import JobDetails from 'ui/stories/JobDetails';
import UnlockButton from 'ui/stories/UnlockButton';
import { Button } from 'ui/stories/Button';
import TableLayout from '../../components/tableLayout/TableLayout';
import EmptyPage from 'ui/stories/EmptyPage';
import Table from 'ui/stories/Table';
import { navigateSelf, toastedNote } from '../../utils/Helper';
import queryString from 'query-string';
import ApplicantStatus from 'ui/stories/ApplicantStatus';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useJobApplicants } from '../../lib/services/jobPostDetailsServices';
import StatusDropdown from 'ui/stories/StatusDropdown';
import JobPostEdit from './JobPostEdit';
import HospitalLogo from 'ui/stories/HospitalLogo';
import Shimmer from 'ui/stories/Shimmer';
import Loader from 'ui/stories/Loader';
import { UserData } from '../../lib/context/userContext';
import { Tooltip } from 'flowbite-react';
import Modal from 'ui/stories/Modal';

import classNames from 'classnames';
import { useApplicantProfile } from '../../lib/services/applicantProfileServices';
import { API } from '../../lib/network/API';
import JobClosePopUp from '../../components/JobClosePopUp';
import {
  applicantDropdownProps,
  download,
  hire,
  interview,
  putOnHold,
  reject,
  scrollToTop,
  shortlist,
  viewProfile
} from './jobSlugdata';
import useDocumentTitle from '../../utils/useDocumentTitle';

export default function JobsSlug() {
  const location = useLocation();
  const { setShowState, setPathArr } = useContext(BreadcrumbsCon);
  const [isOpen, setIsOpen] = useState(false);
  const [ontooltipClick, setOnTooltipClick] = useState(false);
  const statusQuery = new URLSearchParams(location.search).get('status') || '';
  const [editPage, setEditPage] = useState(false);
  const [status, setStatus] = useState(statusQuery || '');
  const [rowInfo, setRowInfo] = useState();
  const [show, setShow] = useState(false);

  const navigate = useNavigate();
  const { slug } = useParams();
  const { userData } = useContext(UserData);
  const pageNumberQuery = new URLSearchParams(location.search).get('pageNo') || 1;
  const {
    details,
    loading: jobDetailsLoading,
    isValidating: jobDetailsValidating,
    mutate: jobDetailMutate
  } = useJobDetails(true, slug);
  const [jobStatus, setJobStatus] = useState(details.status || '');
  const { applicants, loading, paginationInfo, mutate, error, isValidating } = useJobApplicants(
    true,
    slug,
    {
      page: pageNumberQuery,
      status: status.toLowerCase()
    }
  );

  const jobDetailsProps = [
    {
      title: 'Specialization',
      value: details?.specialization
    },
    {
      title: 'Salary',
      value: details?.salary
    },
    {
      title: 'Vacancies',
      value: details?.vacancy_count
    },
    {
      title: 'Location',
      value: details?.location
    }
  ];

  const handleEditChange = () => {
    setEditPage(!editPage);
  };

  const handleModalState = () => {
    setIsOpen(!isOpen);
  };
  //generate objects for breadCrumbs
  const slugArr = _.compact(location.pathname.split('/'));
  const breadCrumbs = slugArr.map((el, idx) => {
    return {
      name: el > 1 ? details.title : el,
      href: idx === 0 ? `/${el}` : `/jobs/${el}`,
      current: idx === slugArr.length - 1
    };
  });

  const TooltipContent = ({ rowData }) => {
    const StatusOptions = [];
    if (rowData.status === 'applied' && rowData.is_unlocked) {
      StatusOptions.push(viewProfile, download, shortlist, putOnHold, reject);
    } else if (rowData.status === 'cv_shortlisted' && rowData.is_unlocked) {
      StatusOptions.push(viewProfile, download, interview, hire, putOnHold, reject);
    } else if (rowData.status === 'interviewed' && rowData.is_unlocked) {
      StatusOptions.push(viewProfile, download, hire, reject);
    } else if (
      (rowData.status === 'hired' || rowData.status === 'rejected') &&
      rowData.is_unlocked
    ) {
      StatusOptions.push(viewProfile, download);
    } else if (rowData.status === 'on_hold' && rowData.is_unlocked) {
      StatusOptions.push(viewProfile, download, shortlist, interview, reject);
    } else {
      StatusOptions.push(viewProfile);
    }
    return (
      <div className="flex flex-col items-start justify-start w-screen gap-4 px-2 my-3 sm:my-0 sm:w-32 sm:gap-2">
        {StatusOptions.map((option, index) => {
          return (
            <div
              onClick={(e) => {
                option.fun(rowData, setOnTooltipClick, navigate, mutate, slug, details);
              }}
              key={`tooltip-${index}`}
              className={classNames(
                option.name === 'Reject' ? 'text-[#d63f0f]' : 'text-theme-gray-800',
                'w-fit cursor-pointer font-regular text-sm leading-7 hover:font-semibold'
              )}>
              {option.name}
            </div>
          );
        })}
      </div>
    );
  };

  const columnData = [
    {
      field: 'Applicant Name',
      render: (rowData) => (
        <div
          onClick={() => {
            navigate(`${location.pathname}/${rowData.id}`);
          }}
          className="flex items-center gap-3 text-sm text-gray-600 font-regular hover:underline">
          <img src="/emptyUser.svg" alt="rmpty_user" className="object-contain w-6 h-6" />
          {rowData.name}
        </div>
      )
    },
    {
      field: 'Qualification',
      render: (rowData) => (
        <div className="text-sm text-gray-600 font-regular">
          {_.isEmpty(rowData?.qualifications) ? '-na-' : rowData?.qualifications?.join(', ')}
        </div>
      )
    },
    {
      field: 'Years of experience',
      render: (rowData) => (
        <div className="text-sm text-gray-600 font-regular">
          {_.isNull(rowData?.experience)
            ? '-na-'
            : rowData?.experience?.years || rowData?.experience?.year}
        </div>
      )
    },
    {
      field: 'Status',
      render: (rowData) => (
        <div>
          <ApplicantStatus type={rowData.status} className="capitalize">
            {rowData.status}
          </ApplicantStatus>
        </div>
      )
    },
    {
      field: 'Action',
      render: (rowData) => (
        <div className="text-sm text-gray-600">
          {rowData.status === 'closed' ? (
            <button>
              <EllipsisVerticalIcon className="w-5 h-5" />
            </button>
          ) : (
            <>
              <div className="hidden sm:contents">
                <Tooltip
                  content={<TooltipContent rowData={rowData} />}
                  arrow={false}
                  placement={applicants?.length > 3 ? 'auto' : 'left'}
                  trigger="click"
                  style="light"
                  className={classNames('absolute z-50')}>
                  <button>
                    <EllipsisVerticalIcon
                      className="w-5 h-5"
                      onClick={() => {
                        setOnTooltipClick(true);
                      }}
                    />
                  </button>
                </Tooltip>
              </div>
              <div className="sm:hidden">
                <EllipsisVerticalIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => {
                    setShow(!show);
                    setOnTooltipClick(true);
                    setRowInfo(rowData);
                  }}
                />
              </div>
            </>
          )}
        </div>
      )
    }
  ];

  useEffect(() => {
    if (!paginationInfo) {
      mutate();
    }
    if (details && jobStatus.length && details.status !== jobStatus) {
      handleModalState();
    }
  }, [jobStatus]);

  useEffect(() => {
    setShowState(true);
    if (!_.isEmpty(details)) {
      setPathArr(breadCrumbs);
    }
    return () => {
      setShowState(false);
    };
  }, [location, details]);

  useEffect(() => {
    const jobsQueryValue = queryString.stringifyUrl(
      {
        url: '',
        query: {
          status: status && status.toLowerCase().replaceAll(' ', '_')
        }
      },
      { skipNull: true, skipEmptyString: true }
    );
    if (!_.isEmpty(jobsQueryValue)) navigate(jobsQueryValue);
  }, [status]);
  useDocumentTitle(`${details.title}`);

  const loaderContentHelper = (data) => {
    if (loading || isValidating) {
      return <Shimmer className="!w-full" />;
    } else if (data && _.isEmpty(data)) {
      return (
        <EmptyPage
          showButton={false}
          title="There is no applicant data"
          buttonTitle="Post a job"
          imgURL="/empty_images/No data.png"
          className="!bg-white"
        />
      );
    } else {
      return (
        <div>
          <Table columnData={columnData} data={applicants} />
        </div>
      );
    }
  };

  const Banner = () => {
    return (
      <>
        {/* mobile view */}
        <div className="border max-h-[500px] bg-white m-5 p-5 rounded-xl flex flex-col gap-6 lg:hidden">
          <div className="flex">
            <HospitalLogo
              isPointer
              imageURL={!_.isNull(details?.logo?.signed_url) ? details?.logo?.signed_url : null}
            />
            <div className="ml-6">
              <h3 className="text-xl text-left text-theme-gray-800">{details?.title}</h3>
              <p className="text-sm text-theme-gray-500">{details?.organization_name}</p>
            </div>
          </div>
          <h3 className="text-[#30c6ac] text-base">{details?.category}</h3>
          <JobDetails details={jobDetailsProps} />
          <div className="flex flex-col items-center justify-center pb-2 border-b">
            <UnlockButton className="w-full">{userData.total_unlocks_left}</UnlockButton>
            {!userData.subscription_present && (
              <Button type="plain" className="w-full !text-sm" href="/billing/plans">
                Buy a plan now
              </Button>
            )}
          </div>
          <div className="flex justify-between">
            <Button
              size="small"
              type="secondary"
              onClick={() => handleEditChange()}
              icon={<img src="/Pencil@1x.svg" alt="pencil" />}>
              Edit Job
            </Button>
            {details.status === 'submitted' ? (
              <p className="bg-theme-yellow-10 text-theme-yellow-500 p-1.5 rounded-md">
                Waiting for admin approval.
              </p>
            ) : (
              <StatusDropdown
                label="Job status"
                options={
                  details?.status === 'active'
                    ? ['pause', 'inactive', 'close']
                    : details?.status === 'inactive'
                    ? ['closed']
                    : details?.status === 'paused'
                    ? ['active', 'closed']
                    : []
                }
                selected={!!jobStatus ? jobStatus : details?.status}
                setSelected={setJobStatus}
              />
            )}
          </div>
        </div>
        {/* desktop view */}
        <div className="border max-h-[500px] bg-white m-8 p-5 rounded-xl lg:flex flex-col gap-4 hidden">
          <div className="flex justify-between">
            <div className="flex flex-col gap-4">
              <h3 className="text-3xl text-left text-theme-gray-800">{details?.title}</h3>
              <p className="text-sm text-theme-gray-500">{details?.organization_name}</p>
              <h3 className="text-[#30c6ac] text-base">{details?.category}</h3>
              <JobDetails details={jobDetailsProps} />
              <div className="flex items-center">
                <UnlockButton>{userData.total_unlocks_left}</UnlockButton>
                {!userData.subscription_present && (
                  <Button className={'!text-sm !leading-6'} type="plain" href="/billing/plans">
                    Buy a plan now
                  </Button>
                )}
              </div>
            </div>
            <div className="flex flex-col items-end flex-none gap-4">
              <div className="flex items-center gap-3">
                {details.status === 'submitted' ? (
                  <p className="bg-theme-yellow-10 text-theme-yellow-500 p-1.5 rounded-md">
                    Waiting for admin approval.
                  </p>
                ) : (
                  <StatusDropdown
                    label="Job status"
                    options={
                      details?.status === 'active'
                        ? ['pause', 'inactive', 'close']
                        : details?.status === 'inactive'
                        ? ['closed']
                        : details?.status === 'paused'
                        ? ['active', 'closed']
                        : []
                    }
                    selected={!!jobStatus ? jobStatus : details?.status}
                    setSelected={setJobStatus}
                  />
                )}
                <Button
                  size="small"
                  type="secondary"
                  className={'w-[120px]'}
                  onClick={() => handleEditChange()}
                  icon={<img src="/Pencil@1x.svg" alt="pencil" />}>
                  Edit Job
                </Button>
              </div>
              <HospitalLogo
                isPointer
                imageURL={
                  !_.isNull(details?.logo?.signed_url) ? details?.logo?.signed_url : '/cam.svg'
                }
              />
            </div>
          </div>
        </div>
      </>
    );
  };
  const bannerContentHelper = () => {
    if (jobDetailsLoading || jobDetailsValidating) {
      return <Shimmer rows={3} cols={3} className="mx-8 my-4" />;
    } else {
      return <Banner />;
    }
  };

  return (
    <>
      <div id="detail_banner">
        <AskForSure
          id={slug}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleModalState={handleModalState}
          jobStatus={jobStatus}
          mutate={jobDetailMutate}
        />

        {editPage ? (
          <JobPostEdit
            job={details}
            mutate={jobDetailMutate}
            handleEditChange={handleEditChange}
            editPageToggle={editPage}
            scrollToTop={() => scrollToTop()}
          />
        ) : (
          <div className="max-w-full pb-12 2xl:max-w-7xl">
            <div className="bg-[#f5f7fb]">
              {bannerContentHelper()}
              {/* table */}
              <div className="px-8">
                <TableLayout
                  title="Applicants"
                  filter
                  option
                  options2={applicantDropdownProps.options}
                  statusSelected={status}
                  setStatusSelected={setStatus}
                  paginationInfo={paginationInfo}
                  count={paginationInfo?.total_count}>
                  {loaderContentHelper(applicants)}
                </TableLayout>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal isOpen={show} setIsOpen={setShow} classname="!p-0 sm:hidden">
        <TooltipContent rowData={rowInfo} />
      </Modal>
    </>
  );
}

const AskForSure = ({ isOpen, setIsOpen, handleModalState, id, jobStatus, mutate }) => {
  const statusDropAPI = async () => {
    const { data, error } = await API.put(`/job_postings/${id}`, {
      job_posting: {
        status: jobStatus === 'pause' ? 'paused' : jobStatus === 'close' ? 'closed' : jobStatus
      }
    });
    if (error) {
      setIsOpen(!isOpen);
      toastedNote.error(error.message);
      mutate();
    } else {
      toastedNote.success(data?.response.messgae);
      mutate();
      setIsOpen(!isOpen);
    }
  };
  return (
    <div>
      {jobStatus === 'close' ? (
        <JobClosePopUp isOpen={isOpen} setIsOpen={setIsOpen} rowId={id} mutate={mutate} />
      ) : (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
          <div className="relative flex flex-col items-center justify-center max-w-xl p-20 text-center duration-300 ease-in border shadow-sm backdrop-blur-sm gap-y-4 rounded-xl">
            <img src="/cancel_plan_popup.svg" alt="vector" />
            <h3 className="text-xl text-theme-gray-800">
              Are you sure you want to change the status ?
            </h3>
            <div className="flex flex-wrap justify-center mt-4 space-x-2">
              <Button className="!px-8" type="plain" onClick={handleModalState}>
                No
              </Button>
              <Button className="!px-8" onClick={() => statusDropAPI()}>
                Yes
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};
