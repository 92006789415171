import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FormError from './FormError';
import FormLabel from './FormLabel';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function TextEditor({
  className,
  field,
  form,
  label,
  required,
  tooltip,
  editorModule,
  ...rest
}) {
  // let Block = Quill.import('blots/block');
  // Block.tagName = 'div';
  // Quill.register(Block);
  const meta = {
    touched: form?.touched[field.name] || false,
    error: form?.errors[field.name]
  };
  const value = field.value.length ? field.value : '';
  return (
    <div className={classNames(className)} {...rest}>
      {label && (
        <FormLabel required={required} tooltip={tooltip} className="mb-1.5">
          {label}
        </FormLabel>
      )}
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(e) => form.setFieldValue(field.name, e)}
        placeholder="Job Description"
        modules={editorModule}
        className="break-words "
      />
      <FormError meta={meta} />
    </div>
  );
}

TextEditor.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * label to display
   */
  label: PropTypes.string,
  /**
   * is this a required field
   */
  required: PropTypes.bool,
  /**
   * what tooltip to show
   */
  tooltip: PropTypes.string
};

TextEditor.defaultProps = {
  className: '',
  label: 'Job Description',
  editorModule: {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link']
    ]
  },
  required: false,
  tooltip: ''
};
