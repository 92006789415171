/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { queryStringParseFuntion } from '../../utils/Helper';
import { useAPI } from '../network/SWR';

export function useJobsList(canCall = true, { page = 1, status, criteria, order_by }) {
  const queryObject = {
    page,
    status: _.isEmpty(status) ? '' : status === 'all status' ? '' : status,
    criteria,
    order_by,
    per_page: 10
  };
  const queryValues = queryStringParseFuntion(queryObject);
  const key = `/job_postings${queryValues}`;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {
    suspense: true
  });

  return {
    jobLists: data?.job_posts || [],
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.pagination || null
  };
}
