import classNames from 'classnames';
import React from 'react';

export default function Heading2({ children, className, ...rest }) {
  return (
    <h2
      className={classNames('text-2xl md:text-[40px] text-center mb-3 md:mb-5', className)}
      {...rest}>
      {children}
    </h2>
  );
}
