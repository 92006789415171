import PropTypes from 'prop-types';
import classNames from 'classnames';
import cam from './assets/cam.svg';
import { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/solid';
import { useEffect } from 'react';
import _ from 'lodash';

export default function HospitalLogo({
  form,
  className,
  edit = false,
  imageURL = '/cam.svg',
  isPointer
}) {
  const [hospitalImage, setHospitalImage] = useState(imageURL);
  useEffect(() => {
    setHospitalImage(imageURL || cam?.src);
  }, [imageURL]);
  return (
    <div
      className={classNames(
        'w-[120px] h-20 relative flex justify-center items-center rounded-lg overflow-hidden group ring-1 ring-theme-gray-300',
        hospitalImage ? 'bg-[#f6f7fb]' : 'bg-[#0000001a]',
        className
      )}>
      <input
        accept="image/*"
        type="file"
        id="select-image"
        className="hidden"
        onChange={(e) => {
          e.target.files.length !== 0
            ? setHospitalImage(URL.createObjectURL(e.target.files[0]))
            : null;
          form?.setFieldValue('hospital_image', e.target.files[0]);
          form?.setFieldValue('hospital_url', URL?.createObjectURL(e.target.files[0]));
        }}
      />
      <label htmlFor={edit ? 'select-image' : ''} className="cursor-pointer">
        <img
          src={hospitalImage}
          alt="logo"
          className={classNames(
            hospitalImage ? 'h-full object-contain' : 'w-7 h-6',
            isPointer ? 'cursor-default' : 'cursor-pointer'
          )}
        />
      </label>

      {edit && hospitalImage && (
        <label
          style={{
            backgroundColor: 'rgba(246, 247, 251, 0.5)'
          }}
          htmlFor="select-image"
          className="absolute bottom-0 w-[120px] h-20 justify-center  backdrop-blur-sm font-semibold hidden group-hover:flex group-hover:items-center cursor-pointer group-hover:rounded-lg">
          <PencilSquareIcon className="w-6 h-6" />
        </label>
      )}
    </div>
  );
}

HospitalLogo.propTypes = {
  // additional classnames to be added
  className: PropTypes.string,

  // the image url to show
  hospitalImageURL: PropTypes.string
};

HospitalLogo.defaultProps = {
  className: '',
  hospitalImageURL: ''
};
