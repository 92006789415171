import React, { createContext, useState } from 'react';

export const TitleContext = createContext();
export const JobContext = ({ children }) => {
  const [thanksTitle, setThanksTitle] = useState();
  return (
    <TitleContext.Provider value={{ thanksTitle, setThanksTitle }}>
      {children}
    </TitleContext.Provider>
  );
};
