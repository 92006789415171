import axios from 'axios';
import { crossDomainToken, CurrentUser } from './Auth';

const CONSTANTS = {
  BASE_API_URL: 'https://api.nurseindia.org/v1'
};

axios.defaults.baseURL = CONSTANTS.BASE_API_URL;
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.get['Accept'] = 'application/json';
axios.defaults.headers.put['Accept'] = 'application/json';

axios.interceptors.request.use(
  function (config) {
    if (['/login'].indexOf(config.url) === -1) {
      const token = crossDomainToken();

      if (token) config.headers['Authorization'] = token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function makeReq(method, url, data = { showError: true }) {
  let promiseResponse = { error: null, data: null };
  const onSuccess = (response) => {
    promiseResponse.data = response.data;
    return Promise.resolve(promiseResponse);
  };

  const onError = (error) => {
    const { response } = error;
    if (response) {
      const data = response;
      if (data.status === '500') {
        promiseResponse.error = {
          message: 'Unable to connect with server. Try again after sometime',
          status: data.status
        };
      } else if (data.status === 401) {
        CurrentUser.logout();
        promiseResponse.error = {
          message: 'Unauthorized user',
          status: data.status
        };
        // window.location.href = '/signin';
      } else {
        promiseResponse.error = {
          message: data?.data?.error,
          status: data?.status
        };
      }
      return Promise.resolve(promiseResponse);
    } else {
      const { message } = error;
      promiseResponse.error = { message };
      return Promise.resolve(promiseResponse);
    }
  };
  return axios({
    method,
    url,
    data
  })
    .then(onSuccess)
    .catch(onError);
}

export const API = {
  post: (url, data, config) => makeReq('post', url, data, config),
  get: (url, data, config) => makeReq('get', url, data, config),
  put: (url, data, config) => makeReq('put', url, data, config),
  patch: (url, data, config) => makeReq('patch', url, data, config),
  delete: (url, data, config) => makeReq('delete', url, data, config)
};

export const FETCHER = (url) => {
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  return axios({
    method: 'get',
    url
  }).then(onSuccess);
};

export const CUSTOMFETCHER = (url) => {
  const onSuccess = (response) => {
    return Promise.resolve(response.data);
  };

  return axios
    .create({
      method: 'get',
      url
    })
    .then(onSuccess);
};
