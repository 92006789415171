/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import classNames from 'classnames';
import queryString from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';
import { paginate } from '../../utils/Paginate';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/20/solid';
import StatusDropdown from 'ui/stories/StatusDropdown';

const TableLayout = ({
  title,
  children,
  loading,
  paginationInfo,
  searchTerm,
  setPageNumberQuery,
  setSearchParamsQuery,
  forModal = false,
  hidePaginationInfo = false,
  filter = false,
  count,
  sortSelected,
  setSortSelected,
  statusSelected,
  setStatusSelected,
  options1 = [],
  options2 = [],
  option = false,
  label1,
  label2
}) => {
  //create these to stats in the parent so tat we can get the data

  //ends
  const current_page = Number(paginationInfo?.current_page);
  const total_pages = Number(paginationInfo?.total_pages);
  const paginationArray = paginate(current_page, total_pages);
  //   const per_page = Number(paginationInfo?.current_page_count);
  //conditionally show pagination buttons
  const buttonRenderBool = paginationArray[0] !== paginationArray[1] ? true : false;
  const navigate = useNavigate();
  const location = useLocation();

  //New query Link using queryString
  const parsed = queryString.parse(location.search);

  const paginationLinkHandler = (pageNo, query) => {
    if (pageNo === '...') {
      return window.location.search;
    }
    return queryString.stringifyUrl(
      {
        url: '',
        query: {
          ...parsed,
          pageNo,
          query
        }
      },
      { skipNull: true, skipEmptyString: true }
    );
  };

  const modalPaginationHandler = (pageNo, query) => {
    if (pageNo === '...') {
      return window.location.search;
    }
    setPageNumberQuery(pageNo);
    if (query) {
      setSearchParamsQuery(query);
    }
  };

  const tableHeading = (title) => {
    return (
      <div className="flex items-center justify-between pb-5 md:pb-10">
        <div className=" -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="mt-2">
            <h3 className="text-3xl font-bold leading-6 text-grey-charcoal">
              {title}
              {count ? (
                <p className="inline ml-3 bg-[#F57D77] text-white px-4 py-1 text-xl rounded-full">
                  {count}
                </p>
              ) : null}
            </h3>
          </div>
        </div>
        {option && (
          <div className="flex gap-2 md:gap-6">
            {!_.isEmpty(options1) && (
              <StatusDropdown
                label={label1 || 'Sort by'}
                options={options1}
                selected={sortSelected}
                dropDownClasses={'lg:h-12 sm:w-36'}
                setSelected={setSortSelected}>
                Sort by
              </StatusDropdown>
            )}
            {!_.isEmpty(options2) && (
              <StatusDropdown
                label={label2 || 'Status'}
                options={options2}
                selected={statusSelected}
                dropDownClasses={'lg:h-12 sm:w-36'}
                setSelected={setStatusSelected}>
                Status
              </StatusDropdown>
            )}
          </div>
        )}
      </div>
    );
  };

  const styles = (buttonStyle) => {
    switch (buttonStyle) {
      case 'Primary':
        return 'relative inline-flex ml-3 items-center px-4 py-4 text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none';
      case 'Button':
        return 'relative inline-flex space-x-4 items-center  px-2 sm:px-4 py-2  text-xs  sm:text-sm font-medium focus:outline-none';
      default:
        return '';
    }
  };

  const disabledButtonStyle = (pageVal1, pageVal2) => {
    return pageVal1 === pageVal2
      ? 'cursor-not-allowed  disabled:opacity-50 text-grey-600'
      : 'cursor-pointer hover:bg-gray-50 text-custom-green-persian';
  };

  const disabledButtonPage = (pageVal1, pageVal2) => {
    return pageVal1 === pageVal2 ? 'cursor-not-allowed text-custom-green-persian' : 'text-grey-600';
  };

  return (
    <div>
      <div>
        {title ? tableHeading(title) : null}
        <div>
          <div>
            <div className="min-h-[75vh] flex flex-col justify-between">
              {children}

              {paginationInfo ? (
                <nav
                  className="flex items-center w-fit mx-auto justify-between  px-4 py-4 sm:px-4"
                  aria-label="Pagination">
                  {!loading ? (
                    <>
                      <div className="flex flex-1 justify-between">
                        {buttonRenderBool ? (
                          <div
                            onClick={() =>
                              !forModal
                                ? navigate(
                                    paginationLinkHandler(
                                      current_page > 1 ? current_page - 1 : 1,
                                      searchTerm
                                    )
                                  )
                                : modalPaginationHandler(
                                    current_page > 1 ? current_page - 1 : 1,
                                    searchTerm
                                  )
                            }
                            className={classNames(
                              'mt-3',
                              styles('Button'),
                              disabledButtonStyle(current_page, 1)
                            )}>
                            <div className="flex flex-row items-center space-x-2 hover:text-theme-green-500">
                              <ArrowLeftIcon className="h-4 w-4" />
                              <p className="hidden sm:block"> Prev</p>
                            </div>
                          </div>
                        ) : null}
                        {/* pagination page numbers start */}
                        <div className="mt-1">
                          {buttonRenderBool
                            ? paginationArray?.map((pageNo, pageIdx) => (
                                <div
                                  onClick={() =>
                                    !forModal
                                      ? navigate(paginationLinkHandler(pageNo, searchTerm))
                                      : modalPaginationHandler(pageNo, searchTerm)
                                  }
                                  key={`${pageIdx}-${pageNo}`}
                                  className={classNames(
                                    'ml-6  cursor-pointer pt-4 !text-[15px] hover:text-theme-green-500',
                                    styles('Button'),
                                    disabledButtonPage(pageNo, current_page),
                                    // disabledButtonPage(pageNo, "..."),
                                    pageNo === '...' ? 'cursor-not-allowed' : '',
                                    pageNo === current_page
                                      ? 'text-theme-green-600'
                                      : 'text-theme-gray-600',
                                    hidePaginationInfo ? 'hidden' : 'block',
                                    'lg:inline-block'
                                  )}>
                                  {pageNo}
                                </div>
                              ))
                            : null}
                        </div>

                        {/* pagination page numbers end */}
                        {buttonRenderBool ? (
                          <div
                            onClick={() =>
                              !forModal
                                ? navigate(
                                    paginationLinkHandler(
                                      current_page < total_pages ? current_page + 1 : total_pages,
                                      searchTerm
                                    )
                                  )
                                : modalPaginationHandler(
                                    current_page < total_pages ? current_page + 1 : total_pages,
                                    searchTerm
                                  )
                            }
                            className={classNames(
                              'mt-3',
                              styles('Button'),
                              disabledButtonStyle(current_page, total_pages)
                            )}>
                            <div className="flex flex-row items-center space-x-2 hover:text-theme-green-500">
                              <p className="hidden sm:block"> Next </p>
                              <ArrowRightIcon className="h-4 w-4" />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </>
                  ) : null}
                </nav>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TableLayout;
