/* eslint-disable no-unused-vars */

import { API } from '../../lib/network/API';
import { toastedNote } from '../../utils/Helper';

export const editJob = {
  name: 'Edit job',
  fun: (rowData, setOnTooltipClick, navigate) => {
    setOnTooltipClick(false);
    navigate(`/jobs/${rowData.id}`);
  }
};

export const active = {
  name: 'Active',
  fun: async (rowData, setOnTooltipClick) => {
    setOnTooltipClick(false);
    const { data, error } = await API.put(`/job_postings/${rowData.id}`, {
      job_posting: {
        status: 'active'
      }
    });
    if (!error) {
      console.log(data);
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error?.message.message);
      console.log(error);
    }
  }
};

export const pause = {
  name: 'Pause',
  fun: async (rowData, setOnTooltipClick) => {
    setOnTooltipClick(false);
    const { data, error } = await API.put(`/job_postings/${rowData.id}`, {
      job_posting: {
        status: 'paused'
      }
    });
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error?.message.message);
    }
  }
};

export const inactive = {
  name: 'Inactive',
  fun: async (rowData, setOnTooltipClick) => {
    setOnTooltipClick(false);
    const { data, error } = await API.put(`/job_postings/${rowData.id}`, {
      job_posting: {
        status: 'inactive'
      }
    });
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
    } else {
      toastedNote.error(error?.message.message);
    }
  }
};
export const closed = {
  name: 'Close',
  fun: (rowData, setOnTooltipClick, navigate, setRowId, setCheckout, checkout) => {
    setOnTooltipClick(false);
    setRowId(rowData.id);
    setCheckout(!checkout);
  }
};

export const options1 = [
  'Creation descending',
  'Creation ascending',
  'Most applied',
  'Least applied'
];
export const options2 = [
  'All Status',
  'Active',
  'Closed',
  'Paused',
  'Inactive',
  'Draft',
  'Submitted'
];
