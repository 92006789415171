/* eslint-disable no-unused-vars */
import { Form, Formik, Field, FieldArray } from 'formik';
import React, { useState } from 'react';
import AutoComplete from 'ui/stories/AutoComplete';
import { Button } from 'ui/stories/Button';
import CheckBox from 'ui/stories/CheckBox';
import CheckTag from 'ui/stories/CheckTag';
import Dropdown from 'ui/stories/Dropdown';
import FormLabel from 'ui/stories/FormLabel';
import HospitalLogo from 'ui/stories/HospitalLogo';
import InputSelect from 'ui/stories/InputSelect';
import TextEditor from 'ui/stories/TextEditor';
import TextInput from 'ui/stories/TextInput';
import {
  useAdditionalQualification,
  useBenefits,
  useFindAllCategories,
  useFindAllCurrency,
  useFindAllSpecialization,
  useJobQualifications,
  useLocations
} from '../../lib/services/dropDownServices';
import { PlusSmallIcon } from '@heroicons/react/24/solid';
import { jobDetailEditValidation } from '../../lib/formikValidations/validation';
import { API } from '../../lib/network/API';
import { handleImageChange, toastedNote } from '../../utils/Helper';
import StatusDropdown from 'ui/stories/StatusDropdown';
import { CurrentUser } from '../../lib/network/Auth';
import { useEffect } from 'react';
import _ from 'lodash';

export const experience = [
  { value: '0 years', id: 1 },
  { value: '2 years', id: 2 },
  { value: '4 years', id: 4 },
  { value: '6 years', id: 6 },
  { value: '8 years', id: 8 },
  { value: '10 years', id: 10 },
  { value: '12 years', id: 12 },
  { value: '14 years', id: 14 },
  { value: '16 years', id: 16 },
  { value: '18 years', id: 18 },
  { value: '20+ years', id: 20 }
];

export default function JobPostEdit({
  job,
  mutate,
  handleEditChange,
  editPageToggle,
  scrollToTop
}) {
  const [query, setQuery] = useState(job?.city || '');
  const [benefitsToggle, setBenefitsToggle] = useState(job?.benefits?.default || []);
  const [locLoaing, setLocLoaing] = useState(false);
  const [locationObj, setLocationObj] = useState({});

  const { qualifications } = useJobQualifications();
  const { categories } = useFindAllCategories();
  const { specialization } = useFindAllSpecialization();
  const { currency } = useFindAllCurrency();
  const { benefits } = useBenefits();
  const { locations, loading: locationLoading } = useLocations(true, query);
  const { additionalQualifications } = useAdditionalQualification();
  const [status, setStatus] = useState(job.status || '');

  let dropStatusOptions = [];
  if (job.status === 'draft') {
    dropStatusOptions.push('draft');
  } else if (job.status === 'inactive') {
    dropStatusOptions.push('closed');
  } else if (job.status === 'paused') {
    dropStatusOptions.push('active', 'closed');
  } else if (job.status === 'active') {
    dropStatusOptions.push('pause', 'inactive', 'close');
  } else {
    dropStatusOptions;
  }

  const checkTagSetValue = (type, setFieldValue) => {
    setBenefitsToggle((prevState) => {
      if (prevState.includes(type.id)) {
        const index = prevState.indexOf(type.id);
        prevState.splice(index, 1);
        setFieldValue('benefits', [...prevState]);
        return [...prevState];
      } else {
        setFieldValue('benefits', [...prevState, type.id]);
        return [...prevState, type.id];
      }
    });
  };
  const categoryDropDownValues = {
    name: 'category',
    placeholder: 'Select',
    label: 'Category',
    valueKey: 'id',
    labelKey: 'value',
    options: categories
  };

  const specializationDropDownValues = {
    name: 'specialization',
    label: 'Specialization',
    placeholder: 'Select',
    valueKey: 'id',
    labelKey: 'value',
    options: specialization.length ? specialization : []
  };

  const initialValues = {
    hospital_image: {},
    hospital_url: job?.logo?.signed_url || '',
    hospital_name: job?.organization_name || '',
    job_title: job?.title || '',
    job_description: job?.description || '',
    category: job?.category_id || '',
    specialization: job?.work_area || '',
    salary: {
      amount: job?.salary || '',
      currency: job?.currency || ''
    },
    location: job?.city || '',
    vacancies: job?.vacancy_count || '',
    qualification: job?.required_qualification || '',
    additionalQualifications: job?.additional_qualifications || '',
    minYears: Number(job?.experience?.charAt(0)) || '',
    maxYears: Number(job?.experience?.charAt(10)) || '',
    benefits: job.benefits?.default || [],
    other_benefits: job.benefits?.custom?.length > 0 ? job.benefits.custom : [''],
    email: job?.recruiter_email || '',
    termsAndConditions: job?.status === 'draft' ? false : true,
    status: job.status || ''
  };

  const submitForm = async (values) => {
    console.log({ locationObj });
    const org_id = CurrentUser.getDetails().id;
    const imageData = !_.isEmpty(values.hospital_image.name)
      ? await handleImageChange(values.hospital_image, org_id)
      : {};
    let customData = {};
    if (values.hospital_image.name) {
      customData = {
        job_posting: {
          name: values.hospital_name,
          title: values.job_title,
          description: values.job_description,
          category_id: values.category,
          city: values.location,
          state: locationObj?.state,
          country: locationObj?.country,
          work_area: values.specialization,
          currency: values.salary.currency,
          salary: values.salary.amount,
          count: values.vacancies,
          required_qualification: values.qualification,
          additional_qualification: values.additionalQualifications,
          min_experience: values.minYears,
          max_experience: values.maxYears,
          logo_attributes: {
            id: job?.logo?.id,
            url: imageData?.signed_url,
            file_type: 'image'
          },
          benefits: {
            default: values.benefits,
            custom: values.other_benefits[0] === '' ? [] : values.other_benefits
          },
          recruiter_email: values.email,
          status: status
        }
      };
    } else {
      customData = {
        job_posting: {
          name: values.hospital_name,
          title: values.job_title,
          description: values.job_description,
          category_id: values.category,
          city: values.location,
          state: locationObj?.state,
          country: locationObj?.country,
          work_area: values.specialization,
          currency: values.salary.currency,
          salary: values.salary.amount,
          count: values.vacancies,
          required_qualification: values.qualification,
          additional_qualification: values.additionalQualifications,
          min_experience: values.minYears,
          max_experience: values.maxYears,
          benefits: {
            default: values.benefits,
            custom: values.other_benefits[0] === '' ? [] : values.other_benefits
          },
          recruiter_email: values.email,
          status: status
        }
      };
    }

    const { data, error } = await API.put(
      status === 'draft' ? `/job_postings/${job.id}?submit=true` : `/job_postings/${job.id}`,
      customData
    );
    if (!error) {
      toastedNote.success(data?.response.message);
      mutate();
      handleEditChange();
      setTimeout(() => {
        scrollToTop();
      }, 500);
    } else {
      toastedNote.error(error?.message.message);
      console.log(error);
    }
  };

  const fullObj = (val) => {
    console.log({ val });
    setLocationObj(val);
  };

  useEffect(() => {
    if (locations.length > 0) setLocLoaing(true);
  }, [editPageToggle, locations]);

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={submitForm}
        initialValues={initialValues}
        validationSchema={jobDetailEditValidation}>
        {({ values, setFieldValue, errors }) => {
          document
            .getElementsByClassName(Object.keys(errors)[0] + '-ErrorMessage')[0]
            ?.scrollIntoView({
              behavior: 'smooth',
              block: 'end'
            });
          return (
            <Form className="px-10 pt-5 pb-10">
              <div className="flex justify-between pb-6 2xl:max-w-7xl ">
                <div className="text-3xl text-theme-gray-800">{job.title}</div>
                <div>
                  {job.status === 'submitted' || 'draft' ? null : (
                    <StatusDropdown
                      label="Job status"
                      options={dropStatusOptions}
                      selected={status}
                      setSelected={setStatus}
                    />
                  )}
                </div>
              </div>
              <div className=" flex flex-col gap-6 max-w-full 2xl:max-w-7xl bg-white rounded-lg p-5 border-solid border border-[#e5eaf5]">
                <div className="text-xl leading-6 text-theme-gray-800">Job Details</div>
                <div className="flex items-center justify-start gap-6">
                  <Field
                    component={HospitalLogo}
                    edit={true}
                    name="hospital_image"
                    imageURL={job?.logo?.signed_url}
                  />
                  <div className="flex flex-col gap-3">
                    <div>Hospital logo</div>
                    <div className="font-regular text-xs text-[#848988]">
                      Dimensions: 120 x 80 px
                    </div>
                  </div>
                </div>
                <Field
                  component={TextInput}
                  placeholder="Enter Hospital Name"
                  name="hospital_name"
                  showValidation={true}
                  label="Hosptial Name"
                  onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                />
                <Field
                  component={TextInput}
                  name="job_title"
                  label="Job Title"
                  placeholder="Enter Job Title"
                  onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                />
                <Field component={Dropdown} {...categoryDropDownValues} />
                {locLoaing && (
                  <Field
                    component={AutoComplete}
                    name="location"
                    placeholder="Search your location"
                    locations={locations.length ? locations : []}
                    query={query}
                    setQuery={setQuery}
                    labelKey="city"
                    valueKey="city"
                    label="Location"
                    fullObj={fullObj}
                    onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                  />
                )}
                <Field
                  component={TextEditor}
                  name="job_description"
                  label="Job Description"
                  className="w-full"
                />
                <Field component={Dropdown} {...specializationDropDownValues} />
                <div className="flex flex-col w-full gap-6 xl:flex-row">
                  <div className="flex-1">
                    <Field
                      component={InputSelect}
                      name="salary"
                      field1="amount"
                      field2="currency"
                      valueKey="id"
                      labelKey="value"
                      label="Salary (per month)"
                      placeholder="Currency"
                      options={currency}
                    />
                  </div>
                  <div className="flex-1">
                    <Field
                      component={TextInput}
                      type="text"
                      onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                      name="vacancies"
                      label="No. of vacancies"
                    />
                  </div>
                </div>
                <Field
                  label="Qualification"
                  component={Dropdown}
                  valueKey="id"
                  labelKey="value"
                  options={qualifications}
                  name="qualification"
                  placeholder="Select a Qualification"
                />
                <div className="flex flex-col gap-3">
                  <FormLabel className="font-sans text-sm font-medium leading-3 text-theme-gray-800">
                    Additional Qualifications, if any
                  </FormLabel>
                  <div className="flex flex-col flex-wrap gap-4 py-2 md:flex-row">
                    {additionalQualifications.map((aq) => {
                      return (
                        <Field
                          component={CheckBox}
                          name="additionalQualifications"
                          title={aq.value}
                          key={aq.id}
                          value={aq.value}
                          type="checkbox"
                          onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="flex gap-6">
                  <div className="flex-1">
                    <Field
                      label="Min years"
                      component={Dropdown}
                      options={experience}
                      valueKey="id"
                      labelKey="value"
                      name="minYears"
                      placeholder="Select"
                    />
                  </div>
                  <div className="flex-1">
                    <Field
                      label="Max years"
                      component={Dropdown}
                      options={experience}
                      valueKey="id"
                      labelKey="value"
                      name="maxYears"
                      placeholder="Select"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <FormLabel className="font-sans text-sm font-medium leading-3 text-theme-gray-800">
                    Benefits
                  </FormLabel>
                  <div className="flex flex-wrap gap-3">
                    {benefits?.map((type, index) => {
                      return (
                        <CheckTag
                          key={index}
                          type="button"
                          name="benefits"
                          onClick={() => {
                            checkTagSetValue(type, setFieldValue);
                          }}
                          selected={benefitsToggle.includes(type.id)}>
                          {type.value}
                        </CheckTag>
                      );
                    })}
                  </div>
                </div>
                <FieldArray
                  name="other_benefits"
                  render={(arrayHelpers) => (
                    <div>
                      {values.other_benefits.length >= 1 &&
                        values.other_benefits.map((benefit, index) => {
                          return (
                            <div key={index}>
                              <Field
                                name={`other_benefits[${index}]`}
                                component={TextInput}
                                onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                                type="text"
                                className="pb-4"
                                placeholder="If others, please specify"
                                onBlur={(e) => {
                                  if (e.currentTarget.value === '') {
                                    if (index !== 0) {
                                      arrayHelpers.remove(index);
                                      return;
                                    } else if (index === 0 && values.other_benefits.length > 1) {
                                      arrayHelpers.remove(index);
                                    }
                                  }
                                }}
                              />
                            </div>
                          );
                        })}
                      <div
                        className="flex mt-3 w-fit"
                        onClick={() => {
                          values.other_benefits[values.other_benefits.length - 1] !== '' &&
                            arrayHelpers.push('');
                        }}>
                        <PlusSmallIcon className="w-6 cursor-pointer text-theme-green-500" />
                        <Button type="plain">Add other benefits</Button>
                      </div>
                    </div>
                  )}
                />
                <Field
                  component={TextInput}
                  label="Recruiter email id"
                  type="text"
                  name="email"
                  placeholder="hr@shardahospital.com"
                  onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                />
                <Field
                  component={CheckBox}
                  name="termsAndConditions"
                  className="text-justify "
                  subtitle="By filling in these details, you are authorizing Nurse India to showcase the position on their website and share the position details with relevant members of the NI community, for them to apply"
                />
                <div className="flex items-stretch justify-start gap-5">
                  <Button
                    submit
                    type="primary"
                    label="Save"
                    disabled={status === 'inactive' || status === 'closed'}
                    className="w-40"
                  />
                  <Button
                    type="plain"
                    label="Cancel"
                    className="w-40"
                    onClick={() => {
                      handleEditChange();
                      scrollToTop();
                    }}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
