import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from './Button';
import BenefitOption from './BenefitOption';
import { CheckIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { Fragment } from 'react';

export default function PricingCard({
  name,
  amount = null,
  description,
  planBenifits,
  className,
  onClick,
  price,
  ...rest
}) {
  const wholeContent = name.split('-');
  return (
    <div
      className={classNames(
        'min-w-[260px] bg-white rounded-lg p-5 flex flex-col justify-between text-center md:w-72 shadow-md',
        className
      )}
      {...rest}>
      <div className="flex flex-col space-y-4 pb-4">
        {wholeContent?.map((nameData, idx) => (
          <Fragment key={idx}>
            <h3
              className={classNames(
                'text-2xl',
                _.isNull(amount) ? 'text-theme-purple-500' : 'text-theme-green-500'
              )}>
              {nameData}
            </h3>
          </Fragment>
        ))}
        {!_.isNull(amount) ? (
          <p className="text-3xl text-theme-purple-500">
            ₹{amount}
            <span className="text-sm font-regular">/month</span>
          </p>
        ) : (
          <p className="text-3xl text-theme-purple-500">Let&apos;s Talk</p>
        )}
        <p className="text-[13px] font-regular text-theme-gray-500">{description}</p>
      </div>
      <div className="border-t pt-4">
        <Button
          type="primary"
          size="small"
          onClick={onClick}
          // href="/employer/login"
          className="flex justify-center py-2 md:py-3 mb-4 text-lg w-full">
          {price?.plan_type === 'contact_us' ? 'Contact Us' : 'Start Now'}
        </Button>
        <div className="text-theme-gray-500 font-regular space-y-2 flex flex-col items-start">
          {planBenifits.map((item, idx) => (
            <BenefitOption
              className="!text-xs"
              key={idx}
              icon={
                <CheckIcon className="w-4 h-4 rounded-full text-theme-green-500 rotate-12 p-0.5 bg-theme-green-200" />
              }>
              {item}
            </BenefitOption>
          ))}
        </div>
      </div>
    </div>
  );
}

PricingCard.propTypes = {
  /* 
        type of plan
    */
  name: PropTypes.string.isRequired,
  /*
        shift the title color for custom plans
   */
  amount: PropTypes.number,
  /* 
        description of the plan
    */
  description: PropTypes.string.isRequired,
  /* 
        benifits of the plan
    */
  planBenifits: PropTypes.arrayOf(PropTypes.string),
  /* 
        additional classnames to add
    */
  className: PropTypes.string,
  /* 
        onclick button
    */
  className: PropTypes.func.isRequired
};

PricingCard.defaultProps = {
  name: 'Junior domestic',
  description:
    'Looking for multiple jobs for senior nurses. The category of the candidate requirement will be mentioned while creating a job.',
  amount: null,
  planBenifits: ['Everything in Standard, plus', 'Dedicated account manager', 'Hiring plan']
};
