import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormLabel from './FormLabel';
import _ from 'lodash';
import FormError from './FormError';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { useEffect } from 'react';
import { parsePhoneNumberWithError, parseNumber } from 'libphonenumber-js';
import CodeAutoComplete from './CodeAutoComplete';
import PhoneInput from './PhoneInput';

export const parsePhoneNumber = (phone) => {
  if (_.isEmpty(phone)) return { valid: false };
  return parseNumber(phone, { defaultCountry: 'IN', extended: true });
};

export const isValidMobileNumber = (phone) => {
  if (_.isEmpty(phone)) return { valid: false };
  const parsedNumber = parseNumber(phone, { defaultCountry: 'IN', extended: true });
  return parsedNumber.valid ? parsePhoneNumberWithError(phone, 'IN').getType() === 'MOBILE' : false;
};

export default function Contact({
  field,
  form,
  tooltip,
  className,
  label,
  countryCodeQuery,
  setCountryCodeQuery,
  required,
  countryCodes,
  placeholder = '+00',
  getCountryCode = () => {},
  ...props
}) {
  const meta = {
    touched: form?.touched[field.name] || false,
    error: form?.errors[field.name]
  };
  const initialPhoneNumber = form?.values?.contact;
  const parsedNumber = parsePhoneNumber(initialPhoneNumber).valid
    ? parsePhoneNumberWithError(initialPhoneNumber, 'IN') || ''
    : {};
  const [phoneNumber, setPhoneNumber] = useState(parsedNumber?.nationalNumber || '');
  useEffect(() => {
    form?.setFieldValue(field.name, phoneNumber);
  }, [form?.values?.countrycode, phoneNumber]);

  return (
    <div
      className={classNames('relative flex flex-col border-none p-0 bg-transparent', className)}
      {...props}>
      <FormLabel tooltip={tooltip} required={required} className="mb-1">
        {label}
      </FormLabel>
      <div className="flex relative items-center w-full ">
        <CodeAutoComplete
          field={field}
          form={form}
          labelKey="code"
          labelKey2="flag"
          countryCodeQuery={countryCodeQuery}
          setCountryCodeQuery={setCountryCodeQuery}
          countryCodes={countryCodes}
          valueKey="code"
          label=""
          required
          name="countrycode"
          className="z-5"
          placeholder={placeholder}
          getCountryCode={getCountryCode}
        />
        <input
          {...field}
          {...props}
          maxLength={12}
          onBlur={field?.onBlur}
          value={phoneNumber || form?.values?.contact}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          type="text"
          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          className={classNames(
            'z-10 appearance-none w-full shadow-sm sm:text-sm font-regular text-sm h-12 rounded-tr-md rounded-br-md',
            meta.touched && meta.error
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300 focus:outline-none focus:ring-0'
              : 'border-[#dde8e7] focus:border-[#dde8e7] focus:ring-transparent text-neutral-700 block'
          )}
        />
        <div className="absolute inset-y-0 right-3 flex items-center">
          {meta.touched && meta.error && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500 pointer-events-none"
              aria-hidden="true"
            />
          )}
        </div>
      </div>
      <FormError meta={meta} className="-bottom-5" />
    </div>
  );
}

PhoneInput.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * placeholder if needed
   */
  placeholder: PropTypes.string,
  /**
   *if meessage need to be displayed
   */
  tooltip: PropTypes.string,
  /**
   * callback on click
   */
  label: PropTypes.string
};

PhoneInput.defaultProps = {
  className: '',
  placeholder: '',
  tooltip: '',
  label: ''
};
