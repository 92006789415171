import { Field, FieldArray } from 'formik';
import React, { useState } from 'react';
import { Button } from 'ui/stories/Button';
import CheckTag from 'ui/stories/CheckTag';
import Dropdown from 'ui/stories/Dropdown';
import FormLabel from 'ui/stories/FormLabel';
import TextEditor from 'ui/stories/TextEditor';
import TextInput from 'ui/stories/TextInput';
import { PlusSmallIcon } from '@heroicons/react/24/solid';
import CheckBox from 'ui/stories/CheckBox';
import { useAdditionalQualification } from '../../lib/services/dropDownServices';
import AutoComplete from 'ui/stories/AutoComplete';
import InputSelect from 'ui/stories/InputSelect';
import HospitalLogo from 'ui/stories/HospitalLogo';

export const experience = [
  { value: '0 years', id: 0 },
  { value: '2 years', id: 2 },
  { value: '4 years', id: 4 },
  { value: '6 years', id: 6 },
  { value: '8 years', id: 8 },
  { value: '10 years', id: 10 },
  { value: '12 years', id: 12 },
  { value: '14 years', id: 14 },
  { value: '16 years', id: 16 },
  { value: '18 years', id: 18 },
  { value: '20+ years', id: 20 }
];

export const RoleDetailForm = ({
  categories,
  specialization,
  query,
  setQuery,
  locations,
  currency,
  hospitalImg,
  fullObj
}) => {
  const categoryDropDownValues = {
    name: 'category',
    placeholder: 'Select',
    label: 'Category',
    valueKey: 'id',
    labelKey: 'value',
    options: categories,
    required: true
  };

  const specializationDropDownValues = {
    name: 'specialization',
    label: 'Specialization',
    placeholder: 'Select',
    valueKey: 'id',
    labelKey: 'value',
    options: specialization.length ? specialization : [],
    required: true,
    listClassName: 'h-48'
  };

  return (
    <div className="space-y-6">
      <div className="text-xl">Job Details</div>
      <div className="flex gap-6 items-center">
        <Field
          component={HospitalLogo}
          edit={true}
          name="hospital_image"
          imageURL={hospitalImg || '/cam.svg'}
        />
        <div className="flex flex-col gap-3">
          <div>Hospital logo</div>
          <div className="font-regular text-xs text-[#848988]">Dimensions: 120 x 80 px</div>
        </div>
      </div>
      <Field
        component={TextInput}
        name="hospital_name"
        label="Hosptial Name"
        placeholder="Enter Hospital Name"
        required={true}
      />
      <Field
        component={TextInput}
        name="job_title"
        label="Job Title"
        placeholder="Enter Job Title"
        required={true}
      />
      <Field component={Dropdown} {...categoryDropDownValues} />
      <Field
        component={AutoComplete}
        name="location"
        placeholder="Search your location"
        locations={locations.length ? locations : []}
        query={query}
        setQuery={setQuery}
        labelKey="city"
        valueKey="city"
        label="Location"
        required
        fullObj={fullObj}
      />
      <Field
        component={TextEditor}
        required={true}
        name="job_description"
        label="Job Description"
        className="w-full"
      />

      <Field component={Dropdown} {...specializationDropDownValues} />
      <div className="w-full flex gap-6 flex-row">
        <div className="flex-1">
          <Field
            component={InputSelect}
            name="salary"
            field1="amount"
            field2="currency"
            valueKey="id"
            labelKey="value"
            label="Salary (per month)"
            placeholder="Currency"
            options={currency}
            required={true}
          />
        </div>
        <div className="flex-1">
          <Field
            component={TextInput}
            type="text"
            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
            name="vacancies"
            label="No. of vacancies"
            required={true}
          />
        </div>
      </div>
    </div>
  );
};

export const EligibilityForm = ({ values, setFieldValue, qualifications, benefits }) => {
  const [benefitsToggle, setBenefitsToggle] = useState(values.benefits);
  const { additionalQualifications } = useAdditionalQualification();

  const checkTagSetValue = (type, setFieldValue) => {
    setBenefitsToggle((prevState) => {
      if (prevState.includes(type.id)) {
        const index = prevState.indexOf(type.id);
        prevState.splice(index, 1);
        setFieldValue('benefits', [...prevState]);
        return [...prevState];
      } else {
        setFieldValue('benefits', [...prevState, type.id]);
        return [...prevState, type.id];
      }
    });
  };
  return (
    <div className="space-y-6">
      <div className="text-xl">Eligibility</div>
      <Field
        label="Qualification"
        component={Dropdown}
        valueKey="id"
        labelKey="value"
        options={qualifications}
        name="qualification"
        placeholder="Select a Qualification"
        required={true}
      />

      <div className="flex flex-col gap-3">
        <FormLabel className="font-sans text-theme-gray-800 leading-3 font-medium text-sm">
          Additional Qualifications, if any
        </FormLabel>
        <div className="flex flex-col gap-4 py-2 md:flex-row flex-wrap">
          {additionalQualifications.map((aq) => {
            return (
              <Field
                component={CheckBox}
                name="additionalQualifications"
                title={aq.value}
                key={aq.id}
                value={aq.value}
                type="checkbox"
                onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
              />
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <FormLabel className="font-sans text-theme-gray-800 text-sm font-medium leading-5">
          Years of Experience
        </FormLabel>
        <div className="flex gap-6">
          <div className="flex-1">
            <Field
              label="Min years"
              component={Dropdown}
              options={experience}
              valueKey="id"
              labelKey="value"
              name="minYears"
              placeholder="Select"
              required={true}
            />
          </div>
          <div className="flex-1">
            <Field
              label="Max years"
              component={Dropdown}
              options={experience}
              valueKey="id"
              labelKey="value"
              name="maxYears"
              placeholder="Select"
              required={true}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <FormLabel className="font-sans text-theme-gray-800 text-sm font-medium leading-3">
          Benefits (Select Applicable)
        </FormLabel>
        <div className="flex flex-wrap gap-3">
          {benefits?.map((type, index) => {
            return (
              <CheckTag
                key={index}
                type="button"
                name="benefits"
                onClick={() => {
                  checkTagSetValue(type, setFieldValue);
                }}
                selected={benefitsToggle.includes(type.id)}>
                {type.value}
              </CheckTag>
            );
          })}
        </div>
      </div>
      <FieldArray
        name="other_benefits"
        render={(arrayHelpers) => (
          <div>
            {values.other_benefits.length >= 1 &&
              values.other_benefits.map((benefit, index) => {
                return (
                  <div key={index}>
                    <Field
                      name={`other_benefits.${index}`}
                      component={TextInput}
                      className="pb-4"
                      onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                      type="text"
                      placeholder="If others, please specify"
                      onBlur={(e) => {
                        if (e.currentTarget.value === '') {
                          if (index !== 0) {
                            arrayHelpers.remove(index);
                          } else if (index === 0 && values.other_benefits.length > 1) {
                            arrayHelpers.remove(index);
                          }
                        }
                      }}
                    />
                  </div>
                );
              })}
            <div
              className="flex mt-3 w-fit"
              onClick={() => {
                if (
                  values.other_benefits[values.other_benefits.length - 1] !== '' &&
                  values.other_benefits[values.other_benefits.length - 1].trim().length !== 0
                )
                  arrayHelpers.push('');
              }}>
              <PlusSmallIcon className="w-6 text-theme-green-500 cursor-pointer" />
              <Button type="plain">Add other benefits</Button>
            </div>
          </div>
        )}
      />
      <Field
        component={TextInput}
        label="Recruiter email id"
        type="text"
        name="email"
        placeholder="hr@shardahospital.com"
        required={true}
        onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
      />
      <Field
        component={CheckBox}
        name="termsAndConditions"
        className="text-justify "
        required={true}
        onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
        subtitle="By filling in these details, you are authorizing Nurse India to showcase the position on their website and share the position details with relevant members of the NI community, for them to apply"
      />
    </div>
  );
};
