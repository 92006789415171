/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import ApplicantProfile from '../../components/applicant-profile/ApplicantProfile';
import { BreadcrumbsCon } from '../../lib/context/BreadcrumbsContext';
import { useJobDetails } from '../../lib/services/jobPostDetailsServices';
import useDocumentTitle from '../../utils/useDocumentTitle';

export default function CandidateProfile({ handleRender }) {
  const { setShowState, pathArr, setPathArr } = useContext(BreadcrumbsCon);
  const location = useLocation();
  const { slug, id } = useParams();

  const { details, loading } = useJobDetails(true, slug);
  const slugArr = _.compact(location.pathname.split('/'));
  const breadCrumbs = slugArr.map((el, idx) => {
    return {
      name: idx === 1 ? details.title : idx === 2 ? 'Candidate profile' : el,
      href: idx === 0 ? `/${el}` : idx === 1 ? `/jobs/${el}` : `/jobs/${slugArr[1]}/${el}`,
      current: idx === slugArr.length - 1
    };
  });

  useEffect(() => {
    setShowState(true);
    if (!_.isEmpty(details)) {
      setPathArr(breadCrumbs);
    }
    return () => {
      setShowState(false);
    };
  }, [location, details]);
  useDocumentTitle(`${details.title}`);

  return (
    <div>
      <ApplicantProfile id={id} slug={slug} handleRender={handleRender} />
    </div>
  );
}
