import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
export const Logo = ({ whiteWashed, className, ...rest }) => {
  return (
    <div>
      <a href="/" className="cursor-pointer">
        <svg
          className={classNames('h-10 md:h-12', className)}
          viewBox="0 0 169 70"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...rest}>
          <path
            d="m83.154 8.376 9.434 16.964h.333l-.303-7.59v-4.627l-.483-4.384 4.475-.363v23.495l-3.9.332-9.465-16.812h-.333l.303 9.193v7.378h-3.962v-19.14l-.393-4.083 4.294-.363zM106.437 15.784v10.311c0 1.815.937 2.843 2.449 2.843 1.421 0 2.601-.847 3.568-2.389v-6.924l-.332-3.508 4.475-.333v11.702l1.361 3.266-3.871 1.633-1.058-2.359c-.938.907-1.935 1.663-3.054 2.238a9.301 9.301 0 0 1-1.603.12c-4.021 0-6.077-2.479-6.077-6.168v-6.591l-.333-3.508 4.475-.333zM125.093 15.573l1.058 2.389a15.05 15.05 0 0 1 3.266-2.268c.423-.09.907-.121 1.391-.121.998 0 2.026.212 2.933.726l-1.391 4.082c-.816-.575-1.663-.907-2.691-.907-1.24 0-2.177.786-2.963 2.056v10.432h-4.113V20.41l-1.33-3.205 3.84-1.633zM138.247 27.245c1.058.907 2.328 1.542 3.447 1.905.363.03.816.06 1.27.06 1.27 0 2.207-.181 2.903-.514.302-.333.453-.726.453-1.27 0-.998-.484-1.602-3.326-1.875-4.022-.453-6.047-2.086-6.047-4.959 0-2.902 2.267-5.019 6.561-5.019 2.48 0 4.566.635 6.35 1.663l-1.996 3.145a11.022 11.022 0 0 0-2.963-1.542c-.423-.06-.937-.121-1.391-.121-.968 0-1.784.15-2.449.453-.242.303-.363.726-.363 1.089 0 1.149.756 1.663 2.933 1.935 4.082.484 6.41 1.996 6.41 5.05 0 3.175-2.449 5.14-6.894 5.14-2.721 0-4.838-.514-6.833-1.784l1.935-3.356z"
            fill={whiteWashed ? '#fff' : '#373895'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m161.076 25.28-3.144-.122c.03 2.54 1.572 3.901 3.961 3.901 1.784 0 3.326-.484 4.928-1.542l1.573 2.933c-1.905 1.3-4.112 1.935-6.592 1.935-5.08 0-8.013-3.175-8.013-8.406 0-5.05 3.266-8.406 7.892-8.406 4.354 0 6.924 3.084 6.924 7.801v1.542l-7.529.363zm3.417-3.236c0-1.935-1.209-3.205-3.084-3.205-1.149 0-2.026.302-2.721.877-.515.755-.787 1.693-.787 2.72l6.592-.392z"
            fill={whiteWashed ? '#fff' : '#373895'}
          />
          <path
            d="M83.487 39.4v23.585h-4.234v-19.17l-.393-4.082 4.627-.333zM92.105 46.566l1.088 2.359c.968-.877 2.147-1.633 3.357-2.207.484-.121 1.028-.152 1.602-.152 3.992 0 6.048 2.54 6.048 6.23v10.19h-4.173v-10.1c0-1.814-.937-2.812-2.45-2.812-1.42 0-2.811.816-3.84 2.328v10.583h-4.112v-11.49l-1.42-3.205 3.9-1.724z"
            fill={whiteWashed ? '#fff' : '#008B73'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M123.31 38.432v20.2l1.421 3.083-3.628 1.694-1.089-1.996c-.937.695-2.086 1.451-3.265 1.844a9.669 9.669 0 0 1-1.633.121c-4.415 0-6.985-3.054-6.985-8.285 0-5.443 3.054-8.527 7.408-8.527 1.572 0 2.873.363 3.689.907v-5.08l-.302-3.628 4.384-.333zm-4.082 12.58c-.937-.757-2.056-1.12-3.326-1.12-1.058 0-1.966.333-2.691.968-.635 1.028-.938 2.57-.938 4.264 0 3.285 1.119 4.928 3.357 4.928 1.36 0 2.661-.695 3.598-2.056v-6.985z"
            fill={whiteWashed ? '#fff' : '#008B73'}
          />
          <path
            d="M132.441 46.808v16.177h-4.082V50.618l-.363-3.477 4.445-.333zm.212-8.194v4.414l-4.596-.181.181-3.87 4.415-.363z"
            fill={whiteWashed ? '#fff' : '#008B73'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M137.007 48.834c2.178-1.512 4.627-2.268 7.409-2.268 4.051 0 6.138 2.298 6.138 6.32v5.836l1.754 2.752-3.206 2.056-1.663-2.329a12.585 12.585 0 0 1-3.537 2.056c-.454.091-1.059.122-1.633.122-3.478 0-5.564-1.754-5.564-4.718 0-2.63 1.905-4.475 5.261-4.898l4.536-.635.121-.393v-.544c0-1.482-1.089-2.299-2.661-2.299-.695 0-1.33.061-1.814.152-.968.484-1.966 1.058-2.873 1.844l-2.268-3.054zm9.495 7.257-3.447.212c-.695.06-1.361.242-1.845.484a2.988 2.988 0 0 0-.393 1.451c0 1.21.726 1.905 2.238 1.905 1.421 0 2.631-.635 3.447-1.754v-2.298z"
            fill={whiteWashed ? '#fff' : '#008B73'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70 34.985c0 7.015-5.685 12.7-12.67 12.7h-7.498V43.27h7.498c4.536 0 8.255-3.72 8.255-8.285 0-4.536-3.719-8.255-8.255-8.255h-9.645v30.6C47.685 64.316 42 70 34.985 70c-6.985 0-12.67-5.685-12.67-12.67v-9.645H12.67C5.685 47.685 0 42 0 34.985 0 28 5.685 22.315 12.67 22.315h7.226v4.415H12.67c-4.565 0-8.254 3.72-8.254 8.255 0 4.566 3.689 8.285 8.255 8.285h9.645v-30.6C22.315 5.685 28 0 34.985 0c7.015 0 12.7 5.685 12.7 12.67v9.645h9.645C64.315 22.315 70 28 70 34.985zM43.27 26.73h-9.706v-4.415h9.706V12.67c0-4.566-3.72-8.255-8.285-8.255-4.536 0-8.255 3.689-8.255 8.255v30.6h9.404v4.415H26.73v9.645c0 4.536 3.72 8.255 8.255 8.255 4.566 0 8.285-3.719 8.285-8.254V26.73z"
            fill={whiteWashed ? '#fff' : 'url(#7unqav0zna)'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M37.797 48.864a3.316 3.316 0 0 1-3.326-3.326 3.316 3.316 0 0 1 3.326-3.326 3.336 3.336 0 0 1 3.326 3.326 3.336 3.336 0 0 1-3.326 3.326zm0-4.535c-.665 0-1.21.544-1.21 1.21 0 .634.545 1.179 1.21 1.179a1.2 1.2 0 0 0 1.18-1.18c0-.665-.545-1.21-1.18-1.21z"
            fill={whiteWashed ? '#fff' : 'url(#uxl79sjmhb)'}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.96 27.759a3.317 3.317 0 0 1-3.325-3.326 3.316 3.316 0 0 1 3.326-3.326 3.316 3.316 0 0 1 3.326 3.326 3.317 3.317 0 0 1-3.326 3.326zm0-4.505c-.664 0-1.178.514-1.178 1.179s.514 1.21 1.179 1.21 1.18-.545 1.18-1.21c0-.665-.515-1.18-1.18-1.18z"
            fill={whiteWashed ? '#fff' : 'url(#csfgrkje3c)'}
          />
          <defs>
            <linearGradient
              id="7unqav0zna"
              x1={0}
              y1={0}
              x2="69.849"
              y2="69.849"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#373895" />
              <stop offset={1} stopColor="#008B73" />
            </linearGradient>
            <linearGradient
              id="uxl79sjmhb"
              x1="34.471"
              y1="42.333"
              x2="41.123"
              y2="48.986"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#373895" />
              <stop offset={1} stopColor="#008B73" />
            </linearGradient>
            <linearGradient
              id="csfgrkje3c"
              x1="28.726"
              y1="21.167"
              x2="35.378"
              y2="27.819"
              gradientUnits="userSpaceOnUse">
              <stop stopColor="#373895" />
              <stop offset={1} stopColor="#008B73" />
            </linearGradient>
          </defs>
        </svg>
      </a>
    </div>
  );
};

Logo.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * size of loader
   */
  whiteWashed: PropTypes.bool
};

Logo.defaultProps = {
  className: '',
  whiteWashed: false
};
