import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import classNames from 'classnames';
import FormLabel from './FormLabel';
import FormError from './FormError';

export default function InputSelect({
  form,
  field,
  field1,
  field2,
  options,
  placeholder,
  labelKey,
  valueKey,
  label,
  required,
  tooltip
}) {
  const meta = {
    touched: form.touched[field.name]?.[field1] || form.touched[field.name]?.[field2] || false,
    error: form.errors[field.name]?.[field1] || form.errors[field.name]?.[field2]
  };
  return (
    <div>
      {label && (
        <FormLabel required={required} tooltip={tooltip}>
          {label}
        </FormLabel>
      )}
      <div className="w-full relative mt-1">
        <input
          className={classNames(
            'block  w-full rounded-md  sm:text-sm font-regular text-sm h-12',
            meta.touched && meta.error
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
              : 'border-gray-300 shadow-sm focus:border-theme-green-500 focus:ring-theme-green-500 text-neutral-700'
          )}
          value={field.value?.amount || ''}
          type="text"
          maxLength={9}
          onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
          name={`${field.name}.${field1}`}
          onChange={field.onChange}
          onBlur={field.onBlur}
        />
        <div className="w-28 absolute top-0 right-1">
          <SelectDropdown
            options={options}
            labelKey={labelKey}
            valueKey={valueKey}
            field={field}
            form={form}
            field2={field2}
            placeholder={placeholder}
          />
        </div>
      </div>
      <FormError meta={meta} name={field.name} />
    </div>
  );
}

function SelectDropdown({
  field,
  form,
  options,
  className,
  placeholder,
  field2,
  valueKey,
  labelKey,
  ...props
}) {
  const value = form?.values[field.name].currency;

  const selectedOption =
    options?.find((opt) => opt[valueKey] === value || opt?.value === value) || null;
  return (
    <div className={classNames('font-regular', className)} {...props}>
      <Listbox
        value={value}
        onChange={(opt) => {
          console.log({ opt });
          form?.setFieldValue(
            `${field.name}.${field2}`,
            opt[valueKey] || opt?.value || opt[valueKey] || opt?.value || opt
          );
        }}>
        <div className="relative">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-transparent h-10 inset-y-1 pl-3 pr-5 text-left  focus:outline-none focus-visible:border-theme-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span
              className={classNames(
                'block truncate h-5',
                selectedOption ? 'text-theme-gray-800' : 'text-theme-gray-500'
              )}>
              {selectedOption?.label || selectedOption?.[labelKey] || placeholder}
            </span>

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="z-10 absolute  mt-2.5 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options?.map((option, optionIDX) => (
                <Listbox.Option
                  key={optionIDX}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-theme-green-100 text-theme-green-700' : 'text-theme-700'
                    }`
                  }
                  value={option[valueKey] || option?.value}>
                  {({ selected }) => (
                    <>
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {option[labelKey] || option?.label}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-theme-green-500">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
