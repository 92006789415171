/* eslint-disable @next/next/no-img-element */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Logo } from './Logo';
import { Button } from './Button';
import { useState } from 'react';
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { useRouter } from 'next/router';
import { CurrentEmployee, CurrentUser } from '../../../apps/nextapp/src/lib/network/Auth';
import { v4 } from 'uuid';
import Image from 'next/image';
import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

const employeeMenuOptions = [
  { name: 'My Jobs', href: '/my-jobs', icon: '/Job1.svg' },
  { name: 'Profile', href: '/profile', icon: '/User@1x.svg' },
  { name: 'Signout', href: '/', icon: '/SignOut@1x.svg' }
];

const employerMenuOptions = [
  { name: 'Dashboard', href: `${process.env.NEXT_PUBLIC_EMPLOYER_PORTAL}/dashboard` },
  {
    name: 'Edit Profile',
    href: `${process.env.NEXT_PUBLIC_EMPLOYER_PORTAL}/edit-profile`
  },
  { name: 'Sign out', href: '#' }
];

export const Header = ({
  navigation,
  className,
  plain = false,
  preview = false,
  handleTogglePreview,
  togglePreview,
  employer,
  employeeData,
  userData,
  ...rest
}) => {
  const [isOpen, setOpen] = useState(false);
  const menuStatus = () => {
    setOpen(!isOpen);
  };

  const closeLabel = (
    <div className="flex gap-2">
      <XMarkIcon className="w-5 h-5" /> Close
    </div>
  );

  const router = useRouter();

  return (
    <div className={classNames('fixed z-50 top-0 w-full shadow-md bg-white', className)} {...rest}>
      {/*make sure the hero sections @ any page is having maring top 80px */}
      <header className={classNames('h-20 flex max-w-screenSize mx-auto')}>
        <div className="flex px-5 lg:px-28 justify-between items-center w-full">
          <div className="logo">
            <Logo className="w-28 h-12 object-contain cursor-pointer" />
          </div>
          {preview && (
            <Button
              type="secondary"
              size="small"
              className="md:hidden !py-1"
              onClick={handleTogglePreview}>
              {togglePreview ? closeLabel : 'Job Preview'}
            </Button>
          )}
          {plain ? null : (
            <>
              {/*Desktop */}
              <DesktopNavigation
                navigation={navigation}
                employer={employer}
                router={router}
                employeeData={employeeData}
                userData={userData}
              />

              {/*mobile */}
              <MobileView
                menuStatus={menuStatus}
                toggle={isOpen}
                employeeData={employeeData}
                userData={userData}
                employer={employer}
              />
            </>
          )}
        </div>
      </header>
      {/*mobile nav */}
      {plain ? null : (
        <MobileNavigation
          navigation={navigation}
          isOpen={isOpen}
          employer={employer}
          router={router}
        />
      )}
    </div>
  );
};

const DesktopNavigation = ({ navigation, router, employer, employeeData, userData }) => {
  const isUserLoggedIn = () => CurrentUser.isLoggedIn();
  return (
    <>
      <div className="hidden lg:block">
        <ul className="flex h-full items-center gap-7 lg:gap-8">
          {navigation.map((page) => {
            return (
              <li key={v4()} className="">
                <a
                  href={page.link}
                  className={classNames(
                    'font-sans text-[15px] text-[#8485b9]  hover:text-green-700 transition-all',
                    page.link === router.pathname ? 'text-green-700' : ''
                  )}>
                  {page.label}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="hidden lg:flex lg:items-center space-x-4">
        {employer && (
          <Button
            type="plain"
            className="cursor-pointer"
            submit={true}
            href={
              isUserLoggedIn
                ? `${process.env.NEXT_PUBLIC_EMPLOYER_PORTAL}/job-post`
                : `/employer/login`
            }>
            Post a Job
          </Button>
        )}
        {employer ? (
          typeof window != 'undefined' && CurrentUser.isLoggedIn() && userData?.name ? (
            <DropMenu name={userData?.name} employer />
          ) : (
            <Button
              submit={true}
              href={'employer/login'}
              className="!py-1 !px-4 lg:!ml-6 !text-base"
              size="small">
              Login
            </Button>
          )
        ) : employeeData?.name ? (
          <DropMenu name={employeeData?.name} />
        ) : (
          <Button
            submit={true}
            href={'/login'}
            className="!py-1 !px-4 lg:!ml-6 !text-base"
            size="small">
            Login
          </Button>
        )}
      </div>
    </>
  );
};
const MobileView = ({ menuStatus, toggle, employeeData, userData, employer }) => (
  <div className="flex items-center gap-2 lg:hidden">
    <div>
      {employer ? (
        userData?.name ? (
          <DropMenu name={userData?.name} employer className="-right-12" />
        ) : (
          <Button
            submit={true}
            href={employer ? '/employer/login' : '/login'}
            className="!py-1 !px-4 lg:!ml-6 !text-base"
            size="small">
            Login
          </Button>
        )
      ) : employeeData?.name ? (
        <DropMenu name={employeeData?.name} className="-right-12" />
      ) : (
        <Button
          submit={true}
          href={employer ? '/employer/login' : '/login'}
          className="!py-1 !px-4 lg:!ml-6 !text-base"
          size="small">
          Login
        </Button>
      )}
    </div>
    <button onClick={() => menuStatus()}>
      {toggle ? (
        <img src="/header/hamclose_icon.svg" alt="menu" />
      ) : (
        <img src="/header/ham_icon.svg" alt="menu" />
      )}
    </button>
  </div>
);

const MobileNavigation = ({ navigation, isOpen, employer, router }) => {
  return (
    <div
      className={classNames(
        'block lg:hidden bg-gradient-to-b from-[#EDEDED] to-[#ffff] absolute z-10 w-full transition-all',
        !isOpen ? 'hidden' : 'block'
      )}>
      <ul className="px-5 pb-4 pt-1 text-[#334155]">
        {navigation.map((page, key) => {
          return (
            <li
              key={key}
              className={classNames(key === navigation.length - 1 ? 'pb-2' : null, 'mt-3')}>
              <a
                href={page.link}
                className={classNames(
                  'font-sans text-[15px] hover:text-green-700 transition-all',
                  page.link === router.pathname ? 'text-green-700' : ''
                )}>
                {page.label}
              </a>
            </li>
          );
        })}
        {employer && <li className="pt-2 border-t">Post a Job</li>}
      </ul>
    </div>
  );
};

const DropMenu = ({ name, className, employer }) => {
  return (
    <div className={classNames(' w-auto', className)}>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex gap-4 w-full pt-1 justify-center rounded-md text-sm font-medium text-theme-green-500 cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
            <span className="pt-[1px]">{name}</span>
            <ChevronDownIcon className="w-6 h-6" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Menu.Items className="absolute right-0 mt-5 w-44 md:w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1">
              {employer
                ? employerMenuOptions.map((e) => (
                    <Menu.Item>
                      {({ active }) => (
                        <>
                          {e.name === 'Sign out' ? (
                            <a
                              onClick={() => {
                                CurrentUser.logout();
                                window.location.reload(true);
                              }}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'flex gap-3 px-4 py-2 text-sm text-gray-600 cursor-pointer'
                              )}>
                              {e.name}
                            </a>
                          ) : (
                            <a
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'flex gap-3 px-4 py-2 text-sm text-gray-600'
                              )}
                              href={e.href}>
                              {e.name}
                            </a>
                          )}
                        </>
                      )}
                    </Menu.Item>
                  ))
                : employeeMenuOptions.map((e) => (
                    <Menu.Item>
                      {({ active }) => (
                        <>
                          {e.name === 'Signout' ? (
                            <a
                              onClick={() => CurrentEmployee.logout()}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'flex gap-3 px-4 py-2 text-sm text-gray-600 cursor-pointer'
                              )}>
                              <span>
                                <Image src={e.icon} height="18px" width="18px" alt="icon" />
                              </span>
                              {e.name}
                            </a>
                          ) : (
                            <a
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'flex gap-3 px-4 py-2 text-sm text-gray-600'
                              )}
                              href={e.href}>
                              <span>
                                <Image src={e.icon} height="18px" width="18px" alt="icon" />
                              </span>
                              {e.name}
                            </a>
                          )}
                        </>
                      )}
                    </Menu.Item>
                  ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

Header.propTypes = {
  /*if need only logo */
  className: PropTypes.string,
  /*Should pass links */
  navigation: PropTypes.array.isRequired,

  /*if need only logo */
  plain: PropTypes.bool,

  // to check for applicant or employer page
  employer: PropTypes.bool
};

Header.defaultProps = {
  navigation: [],
  plain: false
};
