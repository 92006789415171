import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import FormError from './FormError';
import FormLabel from './FormLabel';

export default function Dropdown({
  field,
  form,
  options,
  className,
  placeholder,
  label,
  valueKey,
  labelKey,
  required,
  tooltip,
  multiple,
  getValue = () => {},
  listClassName,
  accreditation,
  ...props
}) {
  const meta = {
    touched: form?.touched[field.name] || false,
    error: form?.errors[field.name]
  };
  const value = form?.values[field.name];
  let selectedOption = multiple
    ? options.filter((opt) => value.find((id) => opt[valueKey] === id || opt?.value === id)) || null
    : options?.find((opt) => opt[valueKey] === value || opt?.value === value || opt === value) ||
      null;
  if (accreditation) {
    selectedOption?.map((item) => {
      if (item.value === 'Not accredited') {
        options = options.filter((item) => item.value === 'Not accredited');
        selectedOption = selectedOption.filter((item) => item.value === 'Not accredited');
      } else {
        options = options.filter((item) => item.value !== 'Not accredited');
        selectedOption = selectedOption.filter((item) => item.value !== 'Not accredited');
      }
    });
  }
  return (
    <div className={classNames('font-regular', className)} {...props}>
      {label && (
        <FormLabel required={required} tooltip={tooltip}>
          {label}
        </FormLabel>
      )}

      <Listbox
        multiple={multiple}
        value={value}
        onChange={(opt) => {
          getValue(opt[valueKey] || opt?.value || opt[valueKey] || opt?.value || opt);
          form?.setFieldValue(
            field.name,
            opt[valueKey] || opt?.value || opt[valueKey] || opt?.value || opt !== '0 years'
              ? opt
              : 0 // exceptional case handling - couldn't find any other soultion
          );
        }}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white h-12 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-theme-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            {multiple ? (
              <span>
                {selectedOption.length
                  ? selectedOption.map((el) => el.label || el[labelKey]).join(', ')
                  : placeholder}
              </span>
            ) : (
              <span
                className={classNames(
                  'block truncate h-5',
                  selectedOption ? 'text-theme-gray-800' : 'text-theme-gray-500'
                )}>
                {selectedOption?.label ||
                  selectedOption?.[labelKey] ||
                  selectedOption ||
                  placeholder}
              </span>
            )}

            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options
              className={classNames(
                'z-50 mb-44 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm',
                listClassName
              )}>
              {options?.map((option, optionIDX) => (
                <Listbox.Option
                  key={optionIDX}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-theme-green-100 text-theme-green-700' : 'text-theme-700'
                    }`
                  }
                  value={option[valueKey] || option?.value || option}>
                  {({ selected }) => (
                    <>
                      {multiple ? (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}>
                            {option[labelKey] || option?.label}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-theme-green-500">
                              <CheckIcon
                                className="h-4 w-4 rounded-sm p-0.5 bg-theme-green-800 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          ) : (
                            <span className="absolute  inset-y-0 left-0 flex items-center pl-3 text-theme-green-500">
                              <div className="h-4 w-4 rounded-sm p-0.5 bg-gray-300 text-white" />
                            </span>
                          )}
                        </>
                      ) : (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? 'font-medium' : 'font-normal'
                            }`}>
                            {option[labelKey] || option?.label || option}
                          </span>
                          {selected ? (
                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-theme-green-500">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>

      <FormError meta={meta} name={field.name} />
    </div>
  );
}

Dropdown.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * label to display
   */
  label: PropTypes.string,
  /**
   * is this a required field
   */
  required: PropTypes.bool,
  /**
   * what tooltip to show
   */
  tooltip: PropTypes.string,
  /**
   * if label is provided in another key
   */
  labelKey: PropTypes.string,
  /**
   * if value is provided in another key
   */
  valueKey: PropTypes.string,
  /**
   * multiple
   */
  multiple: PropTypes.bool,
  /**
   * options available
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      info: PropTypes.any
    }).isRequired
  )
};

Dropdown.defaultProps = {
  placeholder: '',
  className: '',
  label: null,
  required: false,
  tooltip: null,
  multiple: false,
  valueKey: 'value',
  labelKey: 'label'
};
