import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

export default function JobDetails({ className, details, applicant }) {
  const Splitter = () => (
    <span
      className={classNames(
        applicant ? 'bg-[#5455AF]' : 'bg-theme-gray-300',
        'mx-3 p-[1px] h-5 font-thin hidden sm:block'
      )}></span>
  );

  const mobileContent = _.chunk(details, 2);

  const RenderStats = ({ stats }) =>
    stats.map((c, idx) => (
      <div className="flex flex-col sm:flex-row flex-wrap sm:items-center" key={idx}>
        {c
          .map((detail, idx) => (
            <div
              key={idx}
              className={classNames(
                applicant ? 'text-white' : 'text-theme-gray-500',
                'flex flex-row  text-sm font-regular'
              )}>
              <span className="mr-2">{detail.title}</span>
              <span className="font-bold">{detail.value}</span>
            </div>
          ))
          .reduce((previousItem, currentItem) => [previousItem, <Splitter />, currentItem])}
      </div>
    ));
  return (
    <>
      <div className={classNames('block sm:hidden', className)}>
        <RenderStats stats={mobileContent} />
      </div>
      <div className={classNames('hidden sm:flex', className)}>
        <RenderStats stats={[details]} />
      </div>
    </>
  );
}

JobDetails.propTypes = {
  // Addtiion classnames to be added
  className: PropTypes.string,

  // details we are getting is an array
  details: PropTypes.arrayOf(
    // the array is filled with objects
    PropTypes.shape({
      // the title is a string and required
      title: PropTypes.string.isRequired,

      // the value of the title is a string and required
      value: PropTypes.string.isRequired
    })

    // the array itself is required
  ).isRequired,

  // job details when used for applicant side
  applicant: PropTypes.bool
};

// default values for the props if the props does get value from parent
JobDetails.defaultProps = {
  // the classname is empty string if no additional classname is provided in the props
  className: '',

  // the details array will be empty by default if no detail array is provided
  details: [],
  //when using the jobDetail in the applicant side we can pass this prop
  applicant: false
};
