import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M 9 12.75 L 11.25 15 L 15 9.75" />
    </svg>
  );
};

export default function Stepper({ number, children, active, completed, className, ...rest }) {
  return (
    <div className={classNames('flex items-center', className)} {...rest}>
      <p
        className={classNames(
          'text-white rounded-full w-8 h-8 flex justify-center items-center text-xs transition-colors duration-300',
          active ? 'bg-theme-green-500' : completed ? 'bg-theme-green-500' : 'bg-theme-gray-400'
        )}>
        {active ? number : completed ? <CheckIcon className="w-7 h-7" /> : number}

        {/* {completed && <CheckIcon className="w-7 h-7" />}
        {active ? number : number} */}
      </p>
      <p
        className={classNames(
          'text-base ml-2 transition-colors duration-300',
          active
            ? 'text-theme-green-500'
            : completed
            ? 'text-theme-green-500'
            : 'text-theme-gray-400'
        )}>
        {children}
      </p>
    </div>
  );
}

Stepper.propTypes = {
  /* 
    which number to show for which part on the form you are
  */
  number: PropTypes.number.isRequired,
  /*
    stepper content
  */
  children: PropTypes.string.isRequired,
  /* 
    replace the number with a tick if it's active
  */
  active: PropTypes.bool,
  /* 
    additional classnames to be added
  */
  className: PropTypes.string
};

Stepper.defaultProps = {
  active: false,
  number: 1,
  children: 'Role Details'
};
