import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Button } from 'ui/stories/Button';
import Loader from 'ui/stories/Loader';
import TextInput from 'ui/stories/TextInput';
import Contact from 'ui/stories/Contact';
import { editProfileValidation } from '../lib/formikValidations/validation';
import { API } from '../lib/network/API';
import { CurrentUser } from '../lib/network/Auth';
import { useOrganization } from '../lib/services/companyProfileServices';
import { toastedNote } from '../utils/Helper';
import useDocumentTitle from '../utils/useDocumentTitle';
import { useCountryCodeList } from '../lib/services/dropDownServices';
import { isValidPhoneNumber } from 'libphonenumber-js';

export const EditProfile = () => {
  const [countryCodeQuery, setCountryCodeQuery] = useState('');
  const { countryCodes } = useCountryCodeList(
    true,
    countryCodeQuery[0] === '+' ? countryCodeQuery.replace('+', '%2B') : `%2B${countryCodeQuery}`
  );
  const validate = (values) => {
    const errors = {};

    if (!values.countrycode) {
      errors.countrycode = 'Country Code Required';
    }

    const isValid = isValidPhoneNumber(values?.contact, values?.alphaCode);
    if (!isValid) {
      errors.contact = 'Enter valid phone number';
    }

    return errors;
  };

  const user = CurrentUser.getDetails();
  const { organization, loading } = useOrganization(true, user.id);
  const initialValues = {
    name: organization.name || '',
    email: organization.email || '',
    alphaCode: '',
    contact: organization?.phone || '',
    countrycode: organization?.country_code || '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };

  useDocumentTitle(`Edit Profile`);
  const submitForm = async (values) => {
    const customData = {
      organization: {
        name: values.name,
        email: values.email,
        country_code: values.countrycode,
        phone: values.contact
      }
    };
    const passwordData = {
      old_password: values.oldPassword,
      password: values.newPassword,
      confirm_password: values.confirmPassword
    };
    if (values?.name !== organization?.name || values?.phoneNumber !== organization?.phone) {
      const { data, error } = await API.put(`/organizations/${user.id}`, customData);
      if (!error) {
        toastedNote.success(data.response.message);
      } else {
        toastedNote.error(error.message[0]);
        console.log(error);
      }
    }
    if (values?.newPassword !== '') {
      const { data, error } = await API.post('/organizations/update_password', passwordData);
      if (!error) {
        console.log(data);
        toastedNote.success(data.response.message);
      } else {
        toastedNote.error(error.message[0]);
        console.log(error);
      }
    }
  };
  if (loading) {
    return (
      <div className="w-full h-96 flex justify-center items-center">
        <Loader size="large" />
      </div>
    );
  }
  return (
    <>
      <Formik
        validate={validate}
        enableReinitialize
        initialValues={initialValues}
        onSubmit={submitForm}
        validationSchema={editProfileValidation}>
        {({ errors }) => {
          document
            .getElementsByClassName(Object.keys(errors)[0] + '-ErrorMessage')[0]
            ?.scrollIntoView({
              behavior: 'smooth',
              block: 'end'
            });
          return (
            <Form className="flex flex-col gap-6 pt-10 pb-14 px-6">
              <div className="flex flex-col gap-2">
                <div className="text-xl font-sans font-medium leading-[30px] text-theme-gray-800">
                  Edit Profile
                </div>
                <div className="text-xs font-regular leading-[18px] text-theme-gray-600">
                  Update your details and manage your account
                </div>
              </div>
              <Field
                component={TextInput}
                placeholder="Enter your Name"
                name="name"
                showValidation={true}
                label="Your name"
                onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
              />
              <div className="flex gap-6">
                <div className="flex-1">
                  <Field
                    label="Official email id of hospital"
                    component={TextInput}
                    type="email"
                    name="email"
                    placeholder="hospital@gmail.com"
                    readOnly
                    onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                  />
                </div>
                <div className="flex-1">
                  <Field
                    component={Contact}
                    label="Contact"
                    name="contact"
                    countryCodeQuery={countryCodeQuery}
                    setCountryCodeQuery={setCountryCodeQuery}
                    countryCodes={countryCodes}
                    onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                  />
                </div>
              </div>
              <div className="text-2xl text-theme-gray-800">Manage your password</div>
              <Field
                component={TextInput}
                type="password"
                name="oldPassword"
                label="Old password"
                placeholder="Old password"
                onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
              />
              <Field
                component={TextInput}
                type="password"
                name="newPassword"
                label="New password"
                placeholder="New password"
                onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
              />
              <Field
                component={TextInput}
                type="password"
                name="confirmPassword"
                label="Confirm password"
                placeholder="Password"
                onKeyPress={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
              />
              <Button submit type="primary" label="Save" className="w-40" />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
