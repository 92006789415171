import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button } from './Button';
import Shimmer from './Shimmer';

export default function EmptyPage({
  className,
  imgURL,
  title,
  showButton = false,
  buttonTitle,
  href,
  loading,
  height,
  sideTitle,
  whiteSpace = false,
  children
}) {
  return (
    <>
      {loading ? (
        <Shimmer className={classNames(height ? height : 'h-[415px]')} />
      ) : (
        <div
          className={classNames(
            'w-full h-full p-4 bg-gray-200 flex-col justify-center space-y-10 py-7 rounded-md border',
            className
          )}>
          {sideTitle && <p className="-mt-5 text-xl lg:text-3xl">{sideTitle}</p>}
          <img className="w-80 h-40 mx-auto object-contain" src={imgURL} alt="empty_image" />
          <div className="flex-col space-y-5 text-center">
            <p className="text-xl font-medium font-sans">{title}</p>
            {showButton && (
              <Button
                className={whiteSpace ? '!py-1' : 'w-28 h-8 !p-0'}
                href={href}
                type="primary"
                label={buttonTitle}
              />
            )}
            {children}
          </div>
        </div>
      )}
    </>
  );
}

EmptyPage.propTypes = {
  // classes to be added in the future
  className: PropTypes.string,

  // the image to be shown on the page
  imgURL: PropTypes.string,

  // title for the page
  title: PropTypes.string,

  // boolean to check if to show button or not
  showButton: PropTypes.bool,

  // label for the button
  buttonTitle: PropTypes.string
};

EmptyPage.defaultProps = {
  imgURL: './assets/empty_images/image3x.png',
  title: 'Job not Posted yet',
  buttonTitle: 'Post a job',
  showButton: true
};
