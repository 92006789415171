import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import FormError from './FormError';
import FormLabel from './FormLabel';
import Icon from './Icon';
import _ from 'lodash';

export default function TextInput({
  field,
  form,
  className,
  showValidation,
  label,
  required,
  tooltip,
  type,
  readOnly,
  ...props
}) {
  const meta = {
    touched: _.get(form.touched, field.name) || false,
    error: _.get(form.errors, field.name)
  };
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword((p) => !p);

  const icon = { eye: showPassword ? EyeSlashIcon : EyeIcon };
  return (
    <div className={classNames('block', className)}>
      {label && (
        <FormLabel required={required} tooltip={tooltip}>
          {label}
        </FormLabel>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        <input
          {...field}
          {...props}
          type={showPassword ? 'text' : type}
          readOnly={readOnly}
          className={classNames(
            'block  w-full rounded-md  sm:text-sm font-regular text-sm h-12',
            meta.touched && meta.error
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
              : readOnly
              ? 'cursor-not-allowed border-none focus:border-none focus:outline-none focus:ring-0 opacity-80'
              : 'border-[#dde8e7] focus:border-theme-green-500 focus:ring-theme-green-500 text-neutral-700'
          )}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          {type === 'password' && field.value?.length > 0 && (
            <button type="button" onClick={toggleShowPassword}>
              <icon.eye className="h-5 w-5 text-black" aria-hidden="true" />
            </button>
          )}
          {meta.touched && meta.error && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500 pointer-events-none"
              aria-hidden="true"
            />
          )}
          {meta.touched && !meta.error && showValidation && (
            <Icon icon="tick" className="h-5 w-5 text-theme-green-500" aria-hidden="true" />
          )}
        </div>
      </div>
      <FormError meta={meta} name={field.name} />
    </div>
  );
}

TextInput.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * to show tick mark if valid
   */
  showValidation: PropTypes.bool,
  /**
   * input type
   */
  type: PropTypes.string,
  /**
   * label to display
   */
  label: PropTypes.string,
  /**
   * is this a required field
   */
  required: PropTypes.bool,
  /**
   * what tooltip to show
   */
  tooltip: PropTypes.string,
  /* 
  If we want field to be readonly
   */
  readOnly: PropTypes.bool
};

TextInput.defaultProps = {
  className: '',
  showValidation: false,
  type: 'text',
  label: null,
  required: false,
  tooltip: null,
  readOnly: false
};
