import { useAPI } from '../network/SWR';

export function useOrganizationType(canCall = true) {
  const key = '/configs/options?config_key=organization_type';
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    organizationType: data?.response?.configurations || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useSpeciality(canCall = true) {
  const key = '/configs/options?config_key=organization_speciality';
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    specialities: data?.response?.configurations || [],
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useOrganization(canCall = true, id = 2) {
  const key = `/organizations/${id}`;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    organization: data?.organization || [],
    error,
    loading,
    mutate,
    isValidating
  };
}
