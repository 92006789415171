/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import UnlockCount from 'ui/stories/UnlockButton';
import Stat from 'ui/stories/Stat';
import Table from 'ui/stories/Table';
import { CurrentUser } from '../../lib/network/Auth';
import { useStats } from '../../lib/services/dashboardServices';
import { Button } from 'ui/stories/Button';
import ApplicantStatus from 'ui/stories/ApplicantStatus';
import BuyPlanBanner from 'ui/stories/BuyPlanBanner';
import EmptyPage from 'ui/stories/EmptyPage';
import { useState } from 'react';
import { Tooltip } from 'flowbite-react';
import classNames from 'classnames';
import JobClosePopUp from '../../components/JobClosePopUp';
import Loader from 'ui/stories/Loader';
import { useContext } from 'react';
import { UserData } from '../../lib/context/userContext';
import { statsKey, StatusOptions } from './data';
import Modal from 'ui/stories/Modal';
import useDocumentTitle from '../../utils/useDocumentTitle';

export default function Home() {
  const userDetails = CurrentUser.getDetails();
  const [checkout, setCheckout] = useState(false);
  const { userData } = useContext(UserData);

  const [rowId, setRowId] = useState(null);
  // const [showId, setShowId] = useState(false);
  const [show, setShow] = useState(false);
  const [ontooltipClick, setOnTooltipClick] = useState(false);
  const [rowInfo, setRowInfo] = useState();

  const { stats, loading, error, isValidating, mutate } = useStats();
  const navigate = useNavigate();

  const TooltipContent = ({ rowData }) => {
    return (
      <div className="flex flex-col justify-start w-screen gap-4 px-4 my-3 sm:my-0 sm:w-20 sm:gap-2">
        {StatusOptions.map((option, index) => {
          return (
            <div
              onClick={() => {
                option.fun(
                  rowData,
                  setOnTooltipClick,
                  setRowId,
                  setCheckout,
                  navigate,
                  checkout,
                  mutate
                );
              }}
              key={`tooltip-${index}`}
              className={classNames(
                StatusOptions.length > 1 && index === StatusOptions.length - 1
                  ? 'text-[#d63f0f]'
                  : 'text-theme-gray-800',
                'cursor-pointer w-fit font-regular text-sm font-semibold leading-7 hover:font-bold'
              )}>
              {option.name}
            </div>
          );
        })}
      </div>
    );
  };

  const columnData = [
    {
      field: 'Job Title',
      render: (rowData) => (
        <div
          onClick={() => {
            navigate(`/jobs/${rowData.id}`);
          }}
          className="text-sm text-gray-600 font-regular hover:underline">
          {rowData.title}
        </div>
      )
    },
    {
      field: 'Total Applicant',
      render: (rowData) => (
        <div className="text-sm text-gray-600 font-regular">{rowData.total_applicants}</div>
      )
    },
    {
      field: 'Shortlisted',
      render: (rowData) => (
        <div className="text-sm text-gray-600 font-regular">{rowData.total_shortlisted}</div>
      )
    },
    {
      field: 'Status',
      render: (rowData) => (
        <div>
          <ApplicantStatus type={rowData.status} className="capitalize">
            {rowData.status}
          </ApplicantStatus>
        </div>
      )
    },
    {
      field: 'Action',
      render: (rowData) => (
        <div className="relative text-sm text-gray-600">
          <div className="hidden sm:contents">
            <Tooltip
              content={<TooltipContent rowData={rowData} />}
              placement="left"
              trigger="click"
              style="light"
              className={classNames(ontooltipClick ? 'visible' : 'hidden', 'absolute z-50')}>
              <button>
                <EllipsisVerticalIcon
                  className="w-5 h-5"
                  onClick={() => {
                    setOnTooltipClick(true);
                  }}
                />
              </button>
            </Tooltip>
          </div>
          <div className="sm:hidden">
            <EllipsisVerticalIcon
              className="w-5 h-5 cursor-pointer"
              onClick={() => {
                setShow(!show);
                setOnTooltipClick(true);
                setRowInfo(rowData);
                // setShowId(rowData.id);
              }}
            />
          </div>
        </div>
      )
    }
  ];

  //if error I will send a error prop
  const locaderContentHelper = (stats) => {
    if (error && !isValidating) {
      return (
        <EmptyPage
          title="Job not posted yet"
          showButton
          buttonTitle="Post a job"
          imgURL="./empty_images/empty.png"
          href="/job-post"
        />
      );
    } else if (loading || isValidating) {
      return (
        <div className="flex items-center justify-center h-96">
          <Loader size="large" />
        </div>
      );
    } else if (stats && _.isEmpty(stats?.dashboard_stats)) {
      return (
        <EmptyPage
          title="Job not posted yet"
          showButton
          buttonTitle="Post a job"
          imgURL="./empty_images/empty.png"
          href="/job-post"
        />
      );
    } else {
      return (
        <div className="flex flex-col space-y-8">
          <div className="grid gap-8 p-3 bg-white border rounded-md shadow-sm sm:grid-cols-2 lg:grid-cols-3 md:p-0 md:px-10 md:py-6">
            {statsKey?.map((el, key) => (
              <div key={`stats-${key}`}>
                <Stat
                  title={el.title}
                  icon={<img src={el.src} alt="logo" className="w-9 h-9 md:w-16 md:h-16" />}>
                  {_.first(stats?.dashboard_stats)[el.key]}
                </Stat>
              </div>
            ))}
          </div>
          {!_.isEmpty(stats.job_posts) ? (
            <Table columnData={columnData} data={stats.job_posts} customHeader>
              <div className="flex justify-between w-full">
                <p className="text-xl">Current active jobs</p>
                <Button size="medium" type="plain" href="/jobs" className="!py-0">
                  See All
                </Button>
              </div>
            </Table>
          ) : (
            <EmptyPage
              title="No active jobs currently"
              imgURL="./empty_images/empty.png"
              showButton={false}
            />
          )}
        </div>
      );
    }
  };
  useDocumentTitle('Dashboard');
  return (
    <div className="px-8">
      {close && (
        <JobClosePopUp isOpen={checkout} setIsOpen={setCheckout} rowId={rowId} mutate={mutate} />
      )}
      <div className="flex flex-col max-w-full px-3 py-6 space-y-8 lg:py-10 md:space-y-10 2xl:max-w-7xl">
        <div className="flex flex-col space-y-3 text-xl md:text-4xl md:flex-row md:flex-wrap md:justify-between md:items-center">
          <div>
            {new Date().getHours() < 12
              ? 'Good morning'
              : new Date().getHours() < 18
              ? 'Good afternoon'
              : 'Good evening'}
            , {userDetails?.name}!
            <p className="mt-3 text-xs font-regular md:text-sm text-theme-gray-500">
              Here is today&apos;s report and performance
            </p>
          </div>
          <div>
            <BuyPlanBanner
              title="Get a plan now"
              subtitle="Your free trial is about to end buy a plan now"
              buttonTitle="Buy a plan"
              href="/billing/plans"
            />
          </div>
        </div>
        <div className=" lg:hidden">
          <UnlockCount shadow className="w-full">
            {userData.total_unlocks_left}
          </UnlockCount>
        </div>
        {locaderContentHelper(stats)}
      </div>
      {/* {show && showId === rowInfo?.id ? (
        <div
          ref={menuRef}
          className="w-full h-64 sm:hidden bg-white fixed z-[50] bottom-0 left-0 pt-7 px-5 pb-14 shadow-[0px_6px_24px_rgba(0,0,0,0.28)] rounded-t-[20px]">
          <TooltipContent rowData={rowInfo} />
        </div>
      ) : (
        ''
      )} */}
      <Modal isOpen={show} setIsOpen={setShow}>
        <TooltipContent rowData={rowInfo} />
      </Modal>
    </div>
  );
}
