import React, { useContext } from 'react';
import { FacebookIcon, LinkedinIcon, InstagramIcon, MailIcon } from 'ui/stories/assets/Logos';
import { Button } from 'ui/stories/Button';
import Footer from 'ui/stories/Footer';
import SuccessLayout from 'ui/stories/SuccessLayout';
import { TitleContext } from '../../lib/context/JobContext';
import useDocumentTitle from '../../utils/useDocumentTitle';
const footerProps = {
  socialLinks: [
    { icon: <FacebookIcon />, link: 'https://www.facebook.com/NurseIndiaOfficial' },
    { icon: <LinkedinIcon />, link: 'https://www.linkedin.com/company/nurseindia-org/' },
    { icon: <InstagramIcon />, link: 'https://www.instagram.com/nurseindia.org_/' },
    { icon: <MailIcon />, link: 'connect@nurseindia.org' }
  ],
  sections: [
    [
      { name: 'About us', link: '/' },
      { name: 'Privacy Policy', link: '/' },
      { name: 'Our corporate clients', link: '/' }
    ],
    [
      { name: 'Contact Us', link: '/' },
      { name: 'Blog', link: '/' },
      { name: 'FAQ', link: '/' }
    ]
  ]
};

const thankyouData = {
  desc: 'You will receive an email once your job posting has been approved',
  imgUrl: '../../../../product_quality.svg'
};

export const JobSuccess = () => {
  useDocumentTitle('Successfully job created');
  const { thanksTitle } = useContext(TitleContext);
  return (
    <>
      <SuccessLayout
        title={`Your job "${thanksTitle || ''}" has been successfully created`}
        description={thankyouData.desc}
        imgURL="./jobsuccess.png"
        className="text-theme-purple-500 text-xl md:text-[40px] h-[90vh] leading-tight justify-center bg-gradient-to-tr from-theme-green-100">
        <Button
          href="/"
          size="medium"
          type="secondary"
          className="py-1 px-4 bg-white md:py-2 md:px-6 mx-1.5 md:mx-2.5">
          Go to dashboard
        </Button>
        <Button
          href="/job-post"
          size="medium"
          type="primary"
          className="py-1 px-4 md:py-2 md:px-6 mx-1.5 md:mx-2.5">
          Create a new job
        </Button>
      </SuccessLayout>
      <Footer {...footerProps} />
    </>
  );
};
