import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import _ from 'lodash';

function StatusDropdown({
  options,
  className,
  optionsClassName,
  label,
  children,
  selected,
  setSelected,
  dropDownClasses
}) {
  return (
    <div className={classNames('', className)}>
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative h-9 sm:h-10 text-xs flex items-center sm:space-x-6">
          <span className="font-regular whitespace-nowrap text-sm font-medium hidden md:inline-block">
            {children || label}
          </span>
          <Listbox.Button
            className={classNames(
              'relative w-full cursor-default rounded-lg border border-[#dde8e7] bg-white py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-theme-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm',
              dropDownClasses
            )}>
            <span
              className={classNames(
                'hidden md:block truncate font-regular text-sm capitalize',
                selected ? 'text-theme-gray-800 font-semibold' : 'text-theme-gray-500'
              )}>
              {selected ? selected : _.first(options)}
            </span>
            <span
              className={classNames(
                'block md:hidden truncate text-xs font-regular capitalize',
                selected ? 'text-theme-gray-800 font-semibold' : 'text-theme-gray-500'
              )}>
              {selected ? selected : label}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-5 w-5 text-gray-700" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Listbox.Options className="z-10 absolute text-xs font-regular scrollbar-hide top-10 right-0.5 mt-1 max-h-64 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map((option, optionIdx) => {
                option = option.replace('_', ' ');
                return (
                  <Listbox.Option
                    key={optionIdx}
                    className={({ active }) =>
                      `relative capitalize cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-theme-green-100 text-theme-green-700' : 'text-theme-700'
                      } ${optionsClassName}`
                    }
                    value={option}>
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {option}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-theme-green-500">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                );
              })}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default StatusDropdown;

StatusDropdown.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * label to display
   */
  label: PropTypes.string,
  /**
   * children to display
   */
  children: PropTypes.any,
  /**
   * options available
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    }).isRequired
  )
};

StatusDropdown.defaultProps = {
  className: '',
  label: null
};
