import React, { useState } from 'react';
import JobPostingLayout from './JobPostingLayout';
import { Header } from 'ui/stories/Header';
import useDocumentTitle from '../../utils/useDocumentTitle';
export default function JobPosting() {
  useDocumentTitle('Job Posting');
  const [togglePreview, setTogglePreview] = useState(false);

  const handleTogglePreview = () => {
    setTogglePreview(!togglePreview);
    console.log('working');
  };
  return (
    <div>
      <Header
        plain
        preview
        handleTogglePreview={handleTogglePreview}
        togglePreview={togglePreview}
      />
      <JobPostingLayout togglePreview={togglePreview} />
    </div>
  );
}
