/* eslint-disable no-unused-vars */

import Cookies from 'js-cookie';
import { toastedNote } from '../../utils/Helper';

export const crossDomainToken = () => {
  const token = Cookies.get('token');
  if (token) return token;
  return null;
};

const getCurrentUserDetails = () => {
  const curUserDetail = Cookies.get('userDetails');
  if (curUserDetail) return JSON.parse(curUserDetail);
  return null;
};

const getCurrentUserProfileDetails = () => {
  const curUserDetail = Cookies.get('userDetails');
  if (curUserDetail) return JSON.parse(curUserDetail);
  return null;
};

export const CurrentUser = {
  logout: async () => {
    Cookies.remove('token', { path: '', domain: process.env.REACT_APP_HOST });
    Cookies.remove('userDetails', { path: '', domain: process.env.REACT_APP_HOST });
    Cookies.set('logout', false, { path: '', domain: process.env.REACT_APP_HOST });
    setTimeout(() => {
      window.location.href = `${process.env.REACT_APP_EMPLOYER_PORTAL}/employer`;
    }, 100);
  },
  isLoggedIn: () => !!crossDomainToken(),

  getDetails: () => getCurrentUserDetails(),
  setDetails: (data) => {
    localStorage.setItem('current_user_details', JSON.stringify(data));
  },
  setUserProfileDetails: (data) => {
    localStorage.setItem('current_user_profile_details', JSON.stringify(data));
  },
  getUserProfileDetails: () => getCurrentUserProfileDetails()
};

export const logout = async () => {
  toastedNote.success('Logged out successfully');
};
