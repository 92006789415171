import useSWR from 'swr';
import { useEffect } from 'react';
import { CUSTOMFETCHER, FETCHER } from './API';
import { CurrentUser } from './Auth';
import { toastedNote } from '../../utils/Helper';

const getError = (error) => {
  if (!error) return error;
  const { response } = error;
  if (response) {
    const { data } = response;

    if (data.status === '500' || response?.status === 500) {
      return {
        message: 'Unable to connect with server. Try again after sometime'
      };
    } else if (data.status === 401 || response?.status === 401) {
      CurrentUser.logout();
      return {
        message: 'Unauthorized user'
      };
    } else {
      return { message: data.message, status: data.status };
    }
  } else {
    const { message } = error;
    return { message };
  }
};

const swrConfig = {
  onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
    const { response } = error;
    if (retryCount >= 3) return;
    if (response && response.status === 404) return;
    setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
  }
};

const swrCustomConfig = {
  onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
    const { response } = error;
    if (retryCount >= 3) return;
    if (response && response.status === 404) return;
    setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
  },
  revalidateOnFocus: false,
  revalidateOnMount: 'disable'
};
//we may need swrDefaults because in some where we need to revalidate on focus we can use this
export function useAPI(url, { showError = true, swrDefaults = false, customFetcher = false }) {
  const { data, error, mutate, isValidating } = useSWR(
    url,
    customFetcher ? CUSTOMFETCHER : FETCHER,
    !!swrDefaults ? swrConfig : swrCustomConfig
  );
  const customError = getError(error);
  useEffect(() => {
    if (customError && showError) {
      toastedNote.error(customError.message ?? 'Network Error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return {
    data,
    error: customError,
    loading: !error && !data,
    mutate,
    isValidating
  };
}
