// create SWR services here
import _ from 'lodash';
import { useAPI } from '../network/SWR';

export function useJobDetails(canCall = true, id) {
  const key = `/job_postings/${id}`;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});
  return {
    details: data?.job || {},
    error,
    loading,
    mutate,
    isValidating
  };
}

export function useJobApplicants(canCall = true, id, { page = 1, status }) {
  const newStatus = _.isEmpty(status)
    ? ''
    : status === 'all'
    ? ''
    : status === 'cv shortlisted'
    ? 'cv_shortlisted'
    : status === 'on hold'
    ? 'on_hold'
    : status;
  const key = `/job_postings/${id}/applicants?page=${page}&per_page=10&status=${newStatus}`;
  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {
    suspense: true
  });
  return {
    applicants: data?.applicants || {},
    error,
    loading,
    mutate,
    isValidating,
    paginationInfo: data?.pagination || null
  };
}
