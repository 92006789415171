import { InformationCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

const TooltipButton = ({ tooltip }) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip();

  return (
    <>
      <button type="button" ref={setTriggerRef}>
        <InformationCircleIcon className="h-4 w-4 text-theme-gray-600" />
      </button>

      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container bg-black text-white border-black font-regular text-xxs'
          })}>
          <div
            {...getArrowProps({
              className: 'border-black tooltip-arrow'
            })}
          />
          {tooltip}
        </div>
      )}
    </>
  );
};
export default function FormLabel({ className, required, children, tooltip }) {
  return (
    <div className="font-sans flex flex-row gap-2">
      <label className={classNames('text-[15px] leading-6 text-theme-gray-800', className)}>
        {children}
        {required && <span className="text-red-600">*</span>}
      </label>
      {tooltip && <TooltipButton tooltip={tooltip} />}
    </div>
  );
}

FormLabel.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,

  /**
   * breadcrumbs
   */
  required: PropTypes.bool,

  /**
   * breadcrumbs
   */
  tooltip: PropTypes.string
};

FormLabel.defaultProps = {
  className: '',
  required: false,
  tooltip: null
};
