/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import { Fragment, memo, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Logo } from './Logo';
import NavIcon from './NavIcon';
import Icon from './Icon';
import classNames from 'classnames';
import { XMarkIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import Breadcrumbs from 'ui/stories/Breadcrumbs';
import { BreadcrumbsCon } from '../../../apps/reactapp/src/lib/context/BreadcrumbsContext';
import { Disclosure } from '@headlessui/react';
import { CurrentUser, logout } from '../../../apps/reactapp/src/lib/network/Auth';
const ProfileMenuButton = memo(({ className, profile }) => {
  if (!profile?.href && !profile.menu) {
    throw new Error('Profile needs href or menu');
  }
  if (profile?.href) {
    return (
      <a href={profile.href} className={classNames('relative', className)}>
        {profile.component}
      </a>
    );
  }
  return (
    <Menu as="div">
      <div className={className}>
        <Menu.Button className="flex items-center max-w-xs text-sm bg-white rounded-full focus:outline-none font-regular">
          {profile.component}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 z-50 w-48 py-1 mt-8 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {profile.menu.map((item) => (
            <Menu.Item key={item.name}>
              {({ active }) => (
                <>
                  {item.name === 'Sign out' ? (
                    <Link
                      onClick={() => CurrentUser.logout()}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-600'
                      )}>
                      {item.name}
                    </Link>
                  ) : (
                    <Link
                      to={item.href}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-600'
                      )}>
                      {item.name}
                    </Link>
                  )}
                </>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
});

export default function Sidebar({
  children,
  navigation,
  headerNavigation,
  profile,
  right,
  showProfileOnRight
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [sidebarMinified, setSidebarMinified] = useState(false);
  const { showState, pathArr } = useContext(BreadcrumbsCon);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full">
                <Dialog.Panel className="relative flex flex-col flex-1 w-full pt-5 pb-4 bg-white max-w-xxs">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute top-0 right-0 pt-2 -mr-12"></div>
                  </Transition.Child>
                  <div className="flex items-center flex-shrink-0 px-4 space-x-3">
                    <button
                      type="button"
                      className="flex items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="w-8 h-8 text-theme-green-500" aria-hidden="true" />
                    </button>
                    <Logo />
                  </div>
                  <div className="flex-1 h-0 px-5 mt-10 overflow-y-auto">
                    {profile && <ProfileMenuButton profile={profile} />}
                    <nav className="mt-8 space-y-1 border-b border-gray-500 pb-7 border-opacity-10">
                      {navigation.map((item, key) => (
                        <div key={key}>
                          {item.subMenu ? (
                            <Disclosure as="div" key={item.name}>
                              {({}) => (
                                <div className="flex flex-col">
                                  <Disclosure.Button
                                    className={classNames(
                                      item.current
                                        ? 'bg-theme-green-100 text-theme-green-500'
                                        : 'text-theme-gray-500 hover:bg-theme-gray-100 hover:text-theme-gray-700',
                                      item.current
                                        ? 'stroke-theme-green-500 fill-theme-green-500'
                                        : 'stroke-theme-gray-500 hover:stroke-theme-gray-700 fill-theme-gray-500 hover:fill-theme-gray-700',
                                      'group flex items-center text-sm font-medium rounded-full',
                                      sidebarMinified ? 'w-10 h-10 mx-auto ' : 'px-5 py-2 '
                                    )}>
                                    <NavIcon
                                      className={classNames(
                                        'flex-shrink-0 h-6 w-6',
                                        sidebarMinified ? 'mx-auto' : 'mr-3'
                                      )}
                                      icon={item.icon}
                                      active={item.current}
                                      aria-hidden="true"
                                    />
                                    <span>{!sidebarMinified && item.name}</span>
                                  </Disclosure.Button>
                                  {!sidebarMinified && (
                                    <Disclosure.Panel className="space-y-1">
                                      {item.subMenu.map((subItem) => (
                                        <Link
                                          onClick={() => setSidebarOpen(!sidebarOpen)}
                                          key={subItem.name}
                                          to={subItem.href}
                                          className={classNames(
                                            subItem.current
                                              ? 'text-theme-green-500'
                                              : 'text-theme-gray-500',
                                            'group  w-full  py-2 pl-11 pr-2 ml-3 mt-1   group flex items-center text-sm font-medium rounded-full'
                                          )}>
                                          {subItem.name}
                                        </Link>
                                      ))}
                                    </Disclosure.Panel>
                                  )}
                                </div>
                              )}
                            </Disclosure>
                          ) : (
                            <Link
                              key={item.name}
                              to={item.href}
                              onClick={() => setSidebarOpen(!sidebarOpen)}
                              className={classNames(
                                item.current
                                  ? 'bg-theme-green-100 text-theme-green-500'
                                  : 'text-theme-gray-500 hover:bg-theme-gray-100 hover:text-theme-gray-700',
                                'group flex items-center px-5 py-2 text-sm font-medium rounded-full'
                              )}>
                              <NavIcon
                                className={classNames('mr-4 flex-shrink-0 h-6 w-6')}
                                icon={item.icon}
                                active={item.current}
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          )}
                        </div>
                      ))}
                    </nav>
                    <nav className="px-5 py-5 space-y-4">
                      {headerNavigation.map((item, itemKey) => (
                        <div key={itemKey}>
                          <a
                            key={item.label}
                            href={item.link}
                            onClick={() => setSidebarOpen(!sidebarOpen)}
                            className="text-sm font-semibold leading-6 text-gray-500 font-regular">
                            {item.label}
                          </a>
                        </div>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={classNames(
            'hidden md:fixed md:inset-y-0 md:flex md:flex-col transition-all',
            sidebarMinified ? 'md:w-28' : 'md:w-64'
          )}>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-20 overflow-y-auto bg-white border-r border-gray-200 shadow-md">
            <div className="flex flex-col flex-grow mt-10">
              <nav
                className={classNames(
                  'flex-1 space-y-4 flex flex-col border-b pb-7 border-gray-500 border-opacity-10',
                  sidebarMinified ? 'px-8' : 'px-5 '
                )}>
                {navigation.map((item, key) => (
                  <div key={key}>
                    {item.subMenu ? (
                      <Disclosure as="div" key={item.name}>
                        {({}) => (
                          <div className="flex flex-col">
                            <Disclosure.Button
                              className={classNames(
                                item.current
                                  ? 'bg-theme-green-100 text-theme-green-500'
                                  : 'text-theme-gray-500 hover:bg-theme-gray-100 hover:text-theme-gray-700',
                                item.current
                                  ? 'stroke-theme-green-500 fill-theme-green-500'
                                  : 'stroke-theme-gray-500 hover:stroke-theme-gray-700 fill-theme-gray-500 hover:fill-theme-gray-700',
                                'group flex items-center text-sm font-medium rounded-full',
                                sidebarMinified ? 'w-10 h-10 mx-auto ' : 'px-5 py-2 '
                              )}>
                              <NavIcon
                                className={classNames(
                                  'flex-shrink-0 h-6 w-6',
                                  sidebarMinified ? 'mx-auto' : 'mr-3'
                                )}
                                icon={item.icon}
                                active={item.current}
                                aria-hidden="true"
                              />
                              <span>{!sidebarMinified && item.name}</span>
                            </Disclosure.Button>
                            {!sidebarMinified && (
                              <Disclosure.Panel className="space-y-1">
                                {item.subMenu.map((subItem) => (
                                  <Link
                                    key={subItem.name}
                                    to={subItem.href}
                                    className={classNames(
                                      subItem.current
                                        ? 'text-theme-green-500'
                                        : 'text-theme-gray-500',
                                      'group ml-3 mt-3 w-full  py-2 pl-11 pr-2   hover:bg-theme-gray-100 hover:text-theme-gray-700 group flex items-center text-sm font-medium rounded-full'
                                    )}>
                                    {subItem.name}
                                  </Link>
                                ))}
                              </Disclosure.Panel>
                            )}
                          </div>
                        )}
                      </Disclosure>
                    ) : (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-theme-green-100 text-theme-green-500'
                            : 'text-theme-gray-500 hover:bg-theme-gray-100 hover:text-theme-gray-700',
                          item.current
                            ? 'stroke-theme-green-500 fill-theme-green-500'
                            : 'stroke-theme-gray-500 hover:stroke-theme-gray-700 fill-theme-gray-500 hover:fill-theme-gray-700',
                          'group flex items-center text-sm font-medium rounded-full',
                          sidebarMinified ? 'w-10 h-10 mx-auto ' : 'px-5 py-2 '
                        )}>
                        <NavIcon
                          className={classNames(
                            'flex-shrink-0 h-6 w-6',
                            sidebarMinified ? 'mx-auto' : 'mr-3'
                          )}
                          icon={item.icon}
                          active={item.current}
                          aria-hidden="true"
                        />

                        {!sidebarMinified && item.name}
                      </Link>
                    )}
                  </div>
                ))}
                <nav
                  className={classNames(
                    'space-y-4 px-5 py-5',
                    sidebarMinified ? 'hidden' : 'inline-block'
                  )}>
                  {headerNavigation.map((item, itemKey) => (
                    <div key={itemKey}>
                      <a
                        key={item.label}
                        href={item.link}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        className="font-regular font-medium text-sm leading-[26px] text-gray-500">
                        {item.label}
                      </a>
                    </div>
                  ))}
                </nav>
              </nav>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="sticky top-0 z-50 flex h-20 bg-white shadow">
            <div className="flex flex-row items-center flex-1 flex-shrink-0 px-5 space-x-6 md:w-64 ">
              {!sidebarOpen && (
                <button
                  type="button"
                  className="text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-theme-green-500 md:hidden"
                  onClick={() => setSidebarOpen(true)}>
                  <span className="sr-only">Open sidebar</span>
                  <Icon
                    icon="hamburger"
                    className="fill-theme-green-500 hover:fill-theme-green-900"
                  />
                </button>
              )}
              <button
                type="button"
                className={classNames(
                  'items-center justify-center rounded-full focus:outline-none sm:hidden',
                  sidebarOpen ? 'flex' : 'hidden'
                )}
                onClick={() => setSidebarOpen(false)}>
                <span className="sr-only">Close sidebar</span>
                <XMarkIcon className="w-6 h-6 text-theme-green-500" aria-hidden="true" />
              </button>
              <button
                onClick={() => setSidebarMinified((c) => !c)}
                className="hidden md:block focus:outline-none">
                <Icon
                  icon="hamburger"
                  className="fill-theme-green-500 hover:fill-theme-green-900"
                />
              </button>

              <Logo className="h-12" />
            </div>

            <div className="flex justify-between px-2 xs:flex-1 lg:px-4">
              <div className="flex flex-1"></div>
              {/* <div className="ml-16">{left}</div> */}
              <div className="flex items-center ml-4 space-x-4 md:ml-6">
                {right}
                {showProfileOnRight && profile && (
                  <ProfileMenuButton className="hidden md:block" profile={profile} />
                )}
              </div>
            </div>
          </div>

          <main
            className={classNames(
              'flex-1 min-h-[400px] md:min-h-[600px] bg-dashboard-bg',
              sidebarMinified ? 'md:pl-28' : 'md:pl-64'
            )}>
            {showState ? (
              <div>
                <Breadcrumbs className="mx-8 mt-10" pages={pathArr} />
              </div>
            ) : null}
            {children}
          </main>
        </div>
      </div>
    </>
  );
}

Sidebar.propTypes = {
  /**
   * left items in nav bar
   */
  left: PropTypes.arrayOf(PropTypes.node),
  /**
   * center items in nav bar
   */
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      current: PropTypes.bool.isRequired
    })
  ),
  profile: PropTypes.shape({
    component: PropTypes.node.isRequired,
    menu: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      })
    )
  }),
  /**
   * right items in nav bar
   */
  right: PropTypes.node,

  /**
   * right items in nav bar
   */
  showProfileOnRight: PropTypes.bool
};

Sidebar.defaultProps = {
  left: null,
  center: null,
  right: null,
  showProfileOnRight: true
};
