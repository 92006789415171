import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

const Path = memo(({ icon, active }) => {
  const stroke = active
    ? 'stroke-theme-green-500'
    : 'stroke-theme-gray-500 group-hover:stroke-theme-gray-800';
  const fill = active
    ? 'fill-theme-green-500'
    : 'fill-theme-gray-500 group-hover:fill-theme-gray-800';
  switch (icon) {
    case 'dashboard':
      return (
        <>
          <path
            className={classNames(fill, 'opacity-20 group-hover:opacity-30')}
            d="M7.125 20.25a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75ZM7.125 10.5a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75ZM16.875 10.5a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75ZM16.875 20.25a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75Z"
          />
          <path
            d="M7.125 10.5a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75ZM16.875 10.5a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75ZM16.875 20.25a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75ZM7.125 20.25a3.375 3.375 0 1 0 0-6.75 3.375 3.375 0 0 0 0 6.75Z"
            className={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case 'jobs':
      return (
        <>
          <path
            d="M12 14.25a17.84 17.84 0 0 1-9-2.41v7.66a.75.75 0 0 0 .75.75h16.5a.75.75 0 0 0 .75-.75v-7.66a17.934 17.934 0 0 1-9 2.41z"
            className={classNames(fill, 'opacity-20 group-hover:opacity-30')}
          />
          <g className={stroke} strokeLinecap="round" strokeLinejoin="round">
            <path d="M20.25 6.75H3.75A.75.75 0 0 0 3 7.5v12c0 .414.336.75.75.75h16.5a.75.75 0 0 0 .75-.75v-12a.75.75 0 0 0-.75-.75zM15.75 6.75v-1.5a1.5 1.5 0 0 0-1.5-1.5h-4.5a1.5 1.5 0 0 0-1.5 1.5v1.5" />
            <path d="M21 11.84a17.934 17.934 0 0 1-9 2.41 17.84 17.84 0 0 1-9-2.41M10.875 11.25h2.25" />
          </g>
        </>
      );
    case 'billing':
      return (
        <>
          <path
            d="M20.25 15H15v5.25L20.25 15Z"
            className={classNames(fill, 'opacity-20 group-hover:opacity-30')}
          />
          <path
            d="M9 9h6M9 12h6M9 15h3M14.69 20.25H4.5a.75.75 0 0 1-.75-.75v-15a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 .75.75v10.19a.742.742 0 0 1-.216.526l-4.818 4.818a.74.74 0 0 1-.525.216v0Z"
            className={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M20.184 15H15v5.184"
            className={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    case 'company':
      return (
        <>
          <path
            d="M13.5 20.25V3.75a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75v16.5"
            className={classNames(fill, 'opacity-20 group-hover:opacity-30')}
          />
          <path
            d="M1.5 20.25h21M13.5 20.25V3.75a.75.75 0 0 0-.75-.75h-9a.75.75 0 0 0-.75.75v16.5M21 20.25V9.75a.75.75 0 0 0-.75-.75H13.5M6 6.75h3M7.5 12.75h3M6 16.5h3M16.5 16.5H18M16.5 12.75H18"
            className={stroke}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </>
      );
    default:
      return null;
  }
});
function NavIcon({ icon, active, ...props }) {
  return (
    <svg fill="none" height={24} width={24} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path icon={icon} active={active} />
    </svg>
  );
}

NavIcon.propTypes = {
  /**
   * size of NavIcon
   */
  icon: PropTypes.string.isRequired,
  /**
   * size of NavIcon
   */
  active: PropTypes.bool
};

NavIcon.defaultProps = { active: false };

export default NavIcon;
