import { Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import FormLabel from './FormLabel';
import classNames from 'classnames';
import { useEffect } from 'react';

export default function CodeAutoComplete({
  field,
  form,
  valueKey,
  labelKey,
  labelKey2,
  label,
  required,
  countryCodeQuery,
  setCountryCodeQuery,
  countryCodes,
  classname,
  getCountryCode,
  placeholder,
  ...rest
}) {
  const meta = {
    touched: form?.touched[field.name] || false,
    error: form?.errors[field.name]
  };
  const value = form?.values?.countrycode;
  const valueDisplay = countryCodes?.find((el) => el[valueKey] === value || el.value === value);

  useEffect(() => {
    if (valueDisplay) {
      form?.setFieldValue('alphaCode', valueDisplay.country_code);
    }
  }, [valueDisplay, countryCodes]);
  return (
    <div>
      <Combobox
        value={!!valueDisplay ? valueDisplay[valueKey] : value}
        onChange={(opt) => {
          form?.setFieldValue('countrycode', opt[valueKey] || opt?.value || opt);

          getCountryCode(opt.country_code);
        }}>
        {label && <FormLabel required={required}>{label}</FormLabel>}
        <div className="relative -mr-2">
          <div
            className={classNames(
              'relative shadow-sm w-full border-r cursor-default overflow-hidden rounded-tl-lg rounded-bl-lg bg-white text-left sm:text-sm',
              classname
            )}>
            <Combobox.Input
              placeholder={placeholder}
              {...rest}
              className={classNames(
                'bg-[#F9F9F9] border-[#dde8e7] border-r-0 rounded-tl-lg rounded-bl-lg focus:border-[#dde8e7] focus:ring-[#dde8e7] h-12 pl-3 pr-3 leading-5 text-gray-900  block max-w-[89px] sm:text-sm font-regular text-sm ',
                meta.touched && meta.error
                  ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-300 focus:outline-none focus:ring-0'
                  : 'border-[#dde8e7] text-neutral-700 block'
              )}
              onChange={(event) => setCountryCodeQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-3">
              <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <Combobox.Options className="z-10 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-thumb-rounded-full scrollbar-track-gray-100 absolute mt-1 max-h-60 w-[100px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5  sm:text-sm">
              {countryCodes.length === 0 && countryCodeQuery !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                countryCodes.map((countryCode, idx) => (
                  <Combobox.Option
                    key={idx}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-2 pr-4 ${
                        active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={countryCode}>
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                          {`${countryCode[labelKey2]} ${countryCode[labelKey]}`}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}>
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
// focus:outline-none focus-visible:border-theme-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300
