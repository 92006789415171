/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TableLayout from '../../components/tableLayout/TableLayout';
import { useJobsList } from '../../lib/services/jobsServices';
import queryString from 'query-string';
import { navigateSelf, toastedNote } from '../../utils/Helper';
import _ from 'lodash';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import Table from 'ui/stories/Table';
import ApplicantStatus from 'ui/stories/ApplicantStatus';
import EmptyPage from 'ui/stories/EmptyPage';
import { Tooltip } from 'flowbite-react';
import classNames from 'classnames';
import JobClosePopUp from '../../components/JobClosePopUp';

import Loader from 'ui/stories/Loader';
import { useStats } from '../../lib/services/dashboardServices';
import { API } from '../../lib/network/API';
import { active, closed, editJob, inactive, options2, pause } from './jobData';
import Modal from 'ui/stories/Modal';
import useDocumentTitle from '../../utils/useDocumentTitle';

export default function Jobs() {
  useDocumentTitle('Jobs');

  const location = useLocation();
  const navigate = useNavigate();
  const parsed = queryString.parse(location.search);
  const pageNumberQuery = new URLSearchParams(location.search).get('pageNo') || 1;
  const sortByQuery = new URLSearchParams(location.search).get('sortBy') || '';
  const statusQuery = new URLSearchParams(location.search).get('status') || '';
  const [sortBy, setSortBy] = useState(sortByQuery || '');
  const [status, setStatus] = useState(statusQuery || '');
  const [checkout, setCheckout] = useState(false);
  const [rowId, setRowId] = useState(sortByQuery || null);
  const [ontooltipClick, setOnTooltipClick] = useState(false);
  const [rowInfo, setRowInfo] = useState();
  const [show, setShow] = useState(false);

  const TooltipContent = ({ rowData }) => {
    const StatusOptions = [];
    if (rowData.status === 'draft' || rowData.status === 'submitted') {
      StatusOptions.push(editJob);
    } else if (rowData.status === 'paused') {
      StatusOptions.push(editJob, active, closed);
    } else if (rowData.status === 'active') {
      StatusOptions.push(editJob, pause, inactive, closed);
    } else if (rowData.status === 'inactive') {
      StatusOptions.push(closed);
    } else {
      return;
    }
    return (
      <div className="w-screen my-3 sm:my-0 sm:w-20  flex flex-col justify-start gap-4 sm:gap-2 px-4">
        {StatusOptions.map((option, index) => {
          return (
            <div
              onClick={(e) => {
                option.fun(rowData, setOnTooltipClick, navigate, setRowId, setCheckout, checkout);
              }}
              key={`tooltip-${index}`}
              className={classNames(
                StatusOptions.length > 1 && index === StatusOptions.length - 1
                  ? 'text-[#d63f0f]'
                  : 'text-theme-gray-800',
                'w-fit cursor-pointer font-regular text-sm font-semibold leading-7 hover:font-bold'
              )}>
              {option.name}
            </div>
          );
        })}
      </div>
    );
  };
  const { jobLists, paginationInfo, loading, mutate, error, isValidating } = useJobsList(true, {
    page: pageNumberQuery,
    status: status.toLowerCase().replaceAll('_', ' '),
    criteria: _.isEmpty(sortBy)
      ? 'order'
      : sortBy.includes('Creation')
      ? 'order'
      : 'applications_count',
    order_by: _.isEmpty(sortBy)
      ? 'desc'
      : sortBy.includes('ascending')
      ? 'asc'
      : sortBy.includes('Least')
      ? 'asc'
      : 'desc'
  });
  const columnData = [
    {
      field: 'Job Title',
      render: (rowData) => (
        <div
          onClick={() => {
            navigate(`${location.pathname}/${rowData.id}`);
          }}
          className="text-gray-600 font-regular text-sm hover:underline cursor-pointer leading-6">
          {rowData.title}
        </div>
      )
    },
    {
      field: 'Total Applicant',
      render: (rowData) => (
        <div className="text-gray-600 font-regular text-sm leading-6">
          {rowData.total_applicants}
        </div>
      )
    },
    {
      field: 'Shortlisted',
      render: (rowData) => (
        <div className="text-gray-600 font-regular text-sm leading-6">
          {rowData.total_shortlisted}
        </div>
      )
    },
    {
      field: 'Status',
      render: (rowData) => (
        <div>
          <ApplicantStatus type={rowData.status} className="capitalize">
            {rowData.status}
          </ApplicantStatus>
        </div>
      )
    },
    {
      field: 'Action',
      render: (rowData) => (
        <div className="text-gray-600 text-sm relative">
          {rowData.status === 'closed' ? (
            <></>
          ) : (
            <>
              <div className="hidden sm:contents">
                <Tooltip
                  content={<TooltipContent rowData={rowData} />}
                  arrow
                  placement="left"
                  trigger="click"
                  style="light"
                  className={classNames(ontooltipClick ? 'visible' : 'hidden', 'absolute')}>
                  <button>
                    <EllipsisVerticalIcon
                      className="w-5 h-5"
                      onClick={() => {
                        setOnTooltipClick(true);
                      }}
                    />
                  </button>
                </Tooltip>
              </div>
              <div className="sm:hidden">
                <EllipsisVerticalIcon
                  className="cursor-pointer w-5 h-5"
                  onClick={() => {
                    setShow(!show);
                    setOnTooltipClick(true);
                    setRowInfo(rowData);
                  }}
                />
              </div>
            </>
          )}
        </div>
      )
    }
  ];

  useEffect(() => {
    const jobsQueryValue = queryString.stringifyUrl(
      {
        url: '',
        query: {
          status: status.replaceAll(' ', '_'),
          sortBy: sortBy.replaceAll(' ', '_')
        }
      },
      { skipNull: true, skipEmptyString: true }
    );
    if (!_.isEmpty(jobsQueryValue)) navigate(jobsQueryValue);
  }, [sortBy, status]);

  const locaderContentHelper = (data) => {
    if (loading || isValidating) {
      return (
        <div className="flex justify-center items-center h-96">
          <Loader size="large" />
        </div>
      );
    } else if (data && _.isEmpty(data)) {
      return (
        <EmptyPage
          title={`No jobs available in ${status} status`}
          showButton
          buttonTitle="Post a job"
          imgURL="./empty_images/empty.png"
          href="/job-post"
        />
      );
    } else {
      return (
        <div>
          <Table columnData={columnData} data={jobLists} />
        </div>
      );
    }
  };

  return (
    <>
      <div className="px-8 py-5 max-w-full 2xl:max-w-7xl md:py-10">
        {close && (
          <JobClosePopUp isOpen={checkout} setIsOpen={setCheckout} rowId={rowId} mutate={mutate} />
        )}
        <TableLayout
          option
          // options1={options1}
          options2={options2}
          title="Jobs"
          filter
          loading={loading}
          statusSelected={status}
          setStatusSelected={setStatus}
          sortSelected={sortBy}
          setSortSelected={setSortBy}
          paginationInfo={paginationInfo}>
          {locaderContentHelper(jobLists)}
        </TableLayout>
      </div>
      <Modal isOpen={show} setIsOpen={setShow}>
        <TooltipContent rowData={rowInfo} />
      </Modal>
    </>
  );
}
