import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import React from 'react';
import FormError from './FormError';
import FormLabel from './FormLabel';
import Icon from './Icon';
import PropTypes from 'prop-types';

export default function TextArea({
  field,
  form,
  rows,
  label,
  cols,
  className,
  required,
  tooltip,
  showValidation,
  resizable,
  ...rest
}) {
  const meta = {
    touched: form.touched[field.name] || false,
    error: form.errors[field.name]
  };
  return (
    <div className={classNames('block', className)}>
      {label && (
        <FormLabel required={required} tooltip={tooltip}>
          {label}
        </FormLabel>
      )}
      <div className="relative mt-1 rounded-md shadow-sm">
        <textarea
          {...field}
          className={classNames(
            'block w-full rounded-md  sm:text-sm font-regular text-sm',
            meta.touched && meta.error
              ? 'border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
              : 'border-gray-300 shadow-sm focus:border-theme-green-500 focus:ring-theme-green-500 text-neutral-700',
            resizable ? 'resize' : 'resize-none'
          )}
          rows={rows}
          cols={cols}
          {...rest}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          {meta.touched && meta.error && (
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500 pointer-events-none"
              aria-hidden="true"
            />
          )}
          {meta.touched && !meta.error && showValidation && (
            <Icon icon="tick" className="h-5 w-5 text-theme-green-500" aria-hidden="true" />
          )}
        </div>
      </div>
      <FormError meta={meta} name={field.name} />
    </div>
  );
}

TextArea.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,
  /**
   * to show tick mark if valid
   */
  showValidation: PropTypes.bool,
  /**
   * label to display
   */
  label: PropTypes.string,
  /**
   * is this a required field
   */
  required: PropTypes.bool,
  /**
   * what tooltip to show
   */
  tooltip: PropTypes.string,
  /**
   * rows to show
   */
  rows: PropTypes.number,
  /**
   * columns to show
   */
  cols: PropTypes.number,
  /**
   * resizable
   */
  resizable: PropTypes.bool
};

TextArea.defaultProps = {
  className: '',
  showValidation: false,
  type: 'text',
  label: null,
  required: false,
  tooltip: null,
  rows: 6,
  cols: 0,
  resizable: false
};
