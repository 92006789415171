import { useAPI } from '../network/SWR';

export function useStats(canCall = true) {
  const key = '/organizations/dashboard?per_page=5';

  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});

  return {
    stats: data || [],
    error,
    loading,
    mutate,
    isValidating
  };
}
