import { useAPI } from '../network/SWR';

export function useNotification(canCall = true) {
  const key = '/notifications';

  const { data, error, loading, mutate, isValidating } = useAPI(canCall ? key : null, {});

  return {
    notification: data?.notifications || [],
    error,
    loading,
    mutate,
    isValidating
  };
}
