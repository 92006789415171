/* eslint-disable no-unused-vars */
import React, { createContext, useState } from 'react';
import { useEffect } from 'react';
import { toastedNote } from '../../utils/Helper';
import { API } from '../network/API';
import { CurrentUser } from '../network/Auth';

export const UserData = createContext();
export const UserContext = ({ children }) => {
  const userDetails = CurrentUser.getDetails();
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const getDetailsFun = async () => {
      const { data, error } = await API.get(`/organizations/${userDetails.id}`);
      if (error) toastedNote.error(error);
      else setUserData(data.organization);
    };
    getDetailsFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <UserData.Provider value={{ userData }}>{children}</UserData.Provider>;
};
