import classNames from 'classnames';
import PropTypes from 'prop-types';
import FormError from './FormError';

export default function CheckBox({
  field,
  form,
  className,
  title,
  subtitle,
  tnc = false,
  ...props
}) {
  const meta = {
    touched: form?.touched[field?.name] || false,
    error: form?.errors[field?.name]
  };
  return (
    <div className={classNames('relative flex items-start', className)}>
      <div className="flex h-5 items-center">
        <input
          checked={field?.value}
          {...field}
          {...props}
          type="checkbox"
          className={classNames(
            'h-4 w-4 border-gray-300 text-theme-green-600 focus:ring-theme-green-500 cursor-pointer'
          )}
          id={title}
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={title}
          className={classNames(
            'font-regular ',
            field?.checked ? 'text-theme-green-600' : 'text-theme-gray-700'
          )}>
          {title}
        </label>
        {tnc ? (
          <div>
            <p className="font-regular text-sm text-theme-gray-600">
              {`I accept Nurse India's `}
              <span className="text-theme-green-600">
                <a href="/termsconditions"> Terms and Condtions </a>
              </span>
              and
              <span className="text-theme-green-600">
                <a href="/privacypolicy"> Privacy Policy.</a>
              </span>
            </p>
            <span>
              <FormError meta={meta} name={field?.name} />
            </span>
          </div>
        ) : (
          <p className="font-regular text-sm text-theme-gray-600">
            {subtitle}
            <span>
              <FormError meta={meta} name={field?.name} />
            </span>
          </p>
        )}
      </div>
    </div>
  );
}

CheckBox.propTypes = {
  /**
   * additional class anmes
   */
  className: PropTypes.string,

  /**
   * title to be shown
   */
  title: PropTypes.string,

  /**
   * subtitle to be shown
   */
  subtitle: PropTypes.string
};

CheckBox.defaultProps = {
  className: '',
  title: '',
  subtitle: ''
};
