/* eslint-disable no-unused-vars */

import { API } from '../../lib/network/API';
import { toastedNote } from '../../utils/Helper';

export const statsKey = [
  {
    title: 'Total Jobs',
    key: 'total_jobs',
    src: '/dashboard/briefcase.png'
  },
  {
    title: 'Current Active Jobs',
    key: 'active_jobs',
    src: '/dashboard/flag.png'
  },
  {
    title: 'Total Applicants',
    key: 'total_applicants',
    src: '/dashboard/group.png'
  },
  {
    title: 'Total Shortlisted',
    key: 'total_shortlisted',
    src: '/dashboard/people.png'
  },
  {
    title: 'Total Interveiwed',
    key: 'total_interveiwed',
    src: '/dashboard/user_profile.png'
  },
  {
    title: 'Total Hired',
    key: 'total_hired',
    src: '/dashboard/user_accepted.png'
  }
];

export const StatusOptions = [
  {
    name: 'Edit job',
    fun: (rowData, setOnTooltipClick, setRowId, setCheckout, navigate, checkout, mutate) => {
      setOnTooltipClick(false);
      console.log(rowData.id);
      navigate(`/jobs/${rowData.id}`);
    }
  },
  {
    name: 'Pause',
    fun: async (rowData, setOnTooltipClick, setRowId, setCheckout, navigate, checkout, mutate) => {
      setOnTooltipClick(false);
      const { data, error } = await API.put(`/job_postings/${rowData.id}`, {
        job_posting: {
          status: 'paused'
        }
      });
      if (!error) {
        console.log(data);
        toastedNote.success(data?.response.message);
        mutate();
      } else {
        toastedNote.error(error?.message.message);
        console.log(error);
      }
    }
  },
  {
    name: 'Inactive',
    fun: async (rowData, setOnTooltipClick, setRowId, setCheckout, navigate, checkout, mutate) => {
      setOnTooltipClick(false);
      const { data, error } = await API.put(`/job_postings/${rowData.id}`, {
        job_posting: {
          status: 'inactive'
        }
      });
      if (!error) {
        console.log(data);
        toastedNote.success(data?.response.message);
        mutate();
      } else {
        toastedNote.error(error?.message.message);
        console.log(error);
      }
    }
  },
  {
    name: 'Close',

    fun: (rowData, setOnTooltipClick, setRowId, setCheckout, navigate, checkout, mutate) => {
      setOnTooltipClick(false);
      setRowId(rowData.id);
      setCheckout(!checkout);
    }
  }
];
