/* eslint-disable no-unused-vars */

import Cookies from 'js-cookie';
import { toastedNote } from '../../utils/Helper';

const getCurrentUserDetails = () => {
  const curUserDetail = localStorage.getItem('current_user_details');
  if (curUserDetail) return JSON.parse(curUserDetail);
  return null;
};

const getCurrentUserProfileDetails = () => {
  const curUserDetail = localStorage.getItem('current_user_profile_details');
  if (curUserDetail) return JSON.parse(curUserDetail);
  return null;
};

export const CurrentUser = {
  setToken: (token) => localStorage.setItem('user_auth_token', token),

  getToken: () => localStorage.getItem('user_auth_token'),

  logout: async () => {
    Cookies.remove('token', { path: '/', domain: process.env.NEXT_PUBLIC_HOST });
    Cookies.remove('userDetails', { path: '/', domain: process.env.NEXT_PUBLIC_HOST });
    Cookies.remove('logout', { path: '/', domain: process.env.NEXT_PUBLIC_HOST });
    localStorage.removeItem('user_auth_token');
    localStorage.removeItem('current_user_details');
    localStorage.removeItem('current_user_profile_details');

    toastedNote.success('Logged out successfully');
  },
  isLoggedIn: () => !!localStorage.getItem('user_auth_token'),

  getDetails: () => getCurrentUserDetails(),
  setDetails: (data) => {
    localStorage.setItem('current_user_details', JSON.stringify(data));
  },
  setUserProfileDetails: (data) => {
    localStorage.setItem('current_user_profile_details', JSON.stringify(data));
  },
  getUserProfileDetails: () => getCurrentUserProfileDetails()
};

export const logout = async () => {
  // Logout from server
  //   const { data, error } = await API.delete("/auth/logout");
  localStorage.removeItem('current_user_details');
  localStorage.removeItem('user_auth_token');
  toastedNote.success('Logged out successfully');
  setTimeout(() => {
    window.location.href = '/login';
  }, 1500);
};

// employee authentication functions

//create a function to store employee token use it in the api js
// current user should match --> current employee or something

const getCurrentEmployeeDetails = () => {
  const curUserDetail = localStorage.getItem('current_employee_details');
  if (curUserDetail) return JSON.parse(curUserDetail);
  return null;
};

const getCurrentEmployeeProfileDetails = () => {
  const curUserDetail = localStorage.getItem('current_employee_profile_details');
  if (curUserDetail) return JSON.parse(curUserDetail);
  return null;
};

export const CurrentEmployee = {
  setToken: (token) => localStorage.setItem('employee_auth_token', token),

  getToken: () => localStorage.getItem('employee_auth_token'),

  logout: async () => {
    localStorage.removeItem('employee_auth_token');
    localStorage.removeItem('current_employee_details');
    localStorage.removeItem('current_employee_profile_details');
    Cookies.remove('isApplicantLoggedIn');
    toastedNote.success('Logged out successfully');

    window.location.href = '/';
  },
  isLoggedIn: () => !!localStorage.getItem('employee_auth_token'),

  getDetails: () => getCurrentEmployeeDetails(),
  setDetails: (data) => {
    localStorage.setItem('current_employee_details', JSON.stringify(data));
  },
  setUserProfileDetails: (data) => {
    localStorage.setItem('current_employee_profile_details', JSON.stringify(data));
  },
  getUserProfileDetails: () => getCurrentEmployeeProfileDetails()
};

export const logoutEmployee = async () => {
  // Logout from server
  //   const { data, error } = await API.delete("/auth/logout");
  localStorage.removeItem('current_employee_details');
  localStorage.removeItem('employee_auth_token');
  toastedNote.success('Logged out successfully');
  setTimeout(() => {
    window.location.href = '/';
  }, 1500);
};
const getCurrentBrowserId = () => {
  const curBrowserId = localStorage.getItem('browser_id');
  if (curBrowserId) return JSON.parse(curBrowserId);
  return null;
};

export const CurrentBlogUser = {
  setToken: (token) => localStorage.setItem('browser_id', token),
  getToken: () => localStorage.getItem('browser_id'),

  setBrowserIdDetails: (data) => {
    localStorage.setItem('browser_id', JSON.stringify(data));
  },
  getBrowserIdDetails: () => getCurrentBrowserId()
};
